import React from "react";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
import { Switch, useLocation, Route, useRouteMatch } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  return (
    <div className="header mx-2 mx-md-4">
      {location.pathname === "/full-page/order-type" ? (
        <div style={{ marginTop: "54px" }}></div>
      ) : (
        <Navbar />
      )}
      <TopBar />
    </div>
  );
};

export default Header;
