import { convertToTimeZone } from "../helpers/DialogHelper";
import { orderMSTActions } from "./files/orderMSTRedux";

const dateValidationMiddleware = (store) => (next) => (action) => {
  const result = next(action);


  if (action.type === "persist/REHYDRATE") {
    const checkOrderDate = () => {
      const state = store.getState();
      const orderDateState = state.orderMST.orderDateTime;

      
      if (orderDateState && orderDateState.data) {
        const orderDate = new Date(orderDateState.data.orderDate);
        const currentDate  = convertToTimeZone(new Date());



        const orderDateOnly = new Date(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate());
        const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        
        if (orderDate) {
          if (orderDateOnly < currentDateOnly) {
            let data = {
              orderDate: currentDateOnly,
              orderTime: "Now",
              timedOrder: false,
            };
            store.dispatch(orderMSTActions.setOrderDateTime(data));
          }
        }
      }
    };

    setTimeout(checkOrderDate, 0);
  }

  return result;
};

export default dateValidationMiddleware;
