import React from 'react';
import './formComponent.scss'

const PaymentIncDec = ({
    value = 0,
    onChange,
    negative = false,
    step = 1,
    max = undefined,
    min = undefined,
    plusIcon,
    minusIcon
}) => {
    const isMinusDisabled = () => (!isNaN(min) ? min >= value : false)
    const isPlusDisabled = () => (!isNaN(max) ? max <= value : false)
    return (
        <div className="inc-dec-counter">
            <button
                onClick={() => onChange && onChange(value - step, true)}
                disabled={true}
                className={`box box-minus ${isMinusDisabled()
                    ? 'text-muted disabled'
                    : 'text-danger'}`}
                    style={{ opacity:"0.5" }}
            >
                {minusIcon ? minusIcon : (<i className="fas fa-minus"></i>)}
            </button>
            <span className="box box-value d-flex justify-content-center">
                <div>{value}</div>
            </span>
            <button
                onClick={() => onChange && onChange(value + step, false)}
                disabled={true}
                className={`box box-plus ${isPlusDisabled()
                    ? 'text-muted disabled'
                    : 'text-success'}`}
                style={{ opacity:"0.5" }}
            >
                {plusIcon ? plusIcon : (<i className="fas fa-plus"></i>)}
            </button>
        </div>
    );
};

export default PaymentIncDec;