
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const feedbackCruds = {

    all : () => {
        return axios.get(`/feedback/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getAllActive : () => {
        return axios.get(`/feedback/allActive/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{active:undefined, createdBy:undefined, createdOn:undefined, customerMST:undefined, customerMSTId:undefined, feedback:undefined, feedbackId:undefined, modifiedBy:undefined, modifiedOn:undefined, orderMST:undefined, orderMSTId:undefined, outletMST:undefined, outletMSTId:undefined, rating:undefined}}} data
     */
    create : (data) => {
        return axios.post(`/feedback/create/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    delete : ({ id }) => {
        return axios.delete(`/feedback/delete/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byId : ({ id }) => {
        return axios.get(`/feedback/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byOrderMSTId : ({ id }) => {
        return axios.get(`/feedback/orderMST/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findPaginated : () => {
        return axios.get(`/feedback/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{customerMSTId:undefined, feedBack:undefined, qualityRating:undefined, rating:undefined}}} data
     */
    siteFeedBack : (data) => {
        return axios.post(`/feedback/siteFeedback/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{feedBack:undefined, orderMSTId:undefined, rating:undefined}}} data
     */
    submitFeedBack : (data) => {
        return axios.post(`/feedback/submitFeedback/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    update : (data) => {
        return axios.put(`/feedback/update/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default feedbackCruds;
    