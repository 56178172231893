import axios from "axios";
import { errorMessageFormatter } from "../../helpers/Utils";
const orderMSTCruds = {
  all: () => {
    return axios
      .get(`/orderMST/all/`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  pageChange: () => {
    return axios.get(`/orderMST/notifySSE/`);
  },

  getAllActive: () => {
    return axios
      .get(`/orderMST/allActive/`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  cancelOrderWithReason: (data) => {
    return axios
      .put(`/orderMST/cancelOrderWithReason/`, data.body)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  /**
   * @param {{param:{},body:{orderMST:undefined, orderMSTWebRequest:undefined}}} data
   */
  create: (data) => {
    return axios
      .post(`/orderMST/create/`, data.body)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },
  updateOrderPayment: (data) => {
    return axios
      .put(`/orderMST/updateOrderPayment/`, data.body)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },
  delete: ({ id }) => {
    return axios
      .delete(`/orderMST/delete/${id}`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  updateOrderPayment: (data) => {
    return axios
      .put(`/orderMST/updateOrderPayment/`, data.body)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },


  getCurrentOrderStage: ({ id }) => {
    return axios
      .get(`/orderMST/getCurrentStage/${id}`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  getOrderHistory: () => {
    return axios
      .get(`/orderMST/getHistory/`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  byId: ({ id }) => {
    return axios
      .get(`/orderMST/id/${id}`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  invoicedOrder: ({ outletId }) => {
    return axios
      .get(`/orderMST/invoicedOrder/${outletId}`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  findPaginated: () => {
    return axios
      .get(`/orderMST/paginated/`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  runningOrderByCustomer: ({ id }) => {
    return axios
      .get(`/orderMST/runningOrder/customerMST/id/${id}`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  runningOrder: ({ time }) => {
    return axios
      .get(`/orderMST/runningOrder/${time}`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  trackOrder: ({ id }) => {
    return axios
      .get(`/orderMST/track/${id}`)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  /**
   * @param {{param:{},body:{active:undefined, cartManageTime:undefined, cartTrackingMSTId:undefined, cartTrackingPage:undefined, checkoutTime:undefined, createdBy:undefined, createdOn:undefined, initTime:undefined, lastPageTime:undefined, menuPageTime:undefined, modifiedBy:undefined, modifiedOn:undefined, orderMST:undefined, orderMSTId:undefined, paymentPageTime:undefined}}} data
   */
  trackingCart: (data) => {
    return axios
      .post(`/orderMST/trackingCart/`, data.body)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  update: (data) => {
    // console.log(data)
    return axios
      .put(`/orderMST/update/`, data.body)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  updateStage: (data) => {
    return axios
      .put(`/orderMST/updateStage/`, data)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  updatePriceController: (data) => {
    return axios
      .put(`/orderMST/update/priceListMST/`, data.body)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  updatePrice: (data) => {
    return axios
      .put(`/orderMST/updatePrice/`, data.body)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },

  checkMinOrderValue: (data) => {
    return axios
      .post(`/orderMST/checkMinOrderValue/`, data)
      .then((res) =>
        res.data.status
          ? Promise.resolve(res.data.data)
          : Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            })
      )
      .catch((err) =>
        Promise.reject({ message: err.message, status: err?.response?.status })
      );
  },
};

export default orderMSTCruds;
