
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const outletMSTCruds = {

    all : () => {
        return axios.get(`/outletMST/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getAllActive : () => {
        return axios.get(`/outletMST/allActive/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },

    getOutletInfo : () => {
        return  axios.get('/outletMST/getOutletInfo/')
        .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message: errorMessageFormatter(res.data), status: res.status}))
        .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
    
        
    byCode : ({ code }) => {
        return axios.get(`/outletMST/code/${code}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{abnNumber:undefined, active:undefined, address1:undefined, address2:undefined, anniversary:undefined, birthDate:undefined, createdBy:undefined, createdOn:undefined, customerCare:undefined, dayStartFloatAmount:undefined, documentStagesList:undefined, driverFloatAmount:undefined, extraHours:undefined, gender:undefined, geoLocation:undefined, geographyMST1:undefined, geographyMST2:undefined, geographyMST3:undefined, geographyMST4:undefined, geographyMST5:undefined, geographyMST6:undefined, geographyMST7:undefined, geographyMST8:undefined, geographyMSTId1:undefined, geographyMSTId2:undefined, geographyMSTId3:undefined, geographyMSTId4:undefined, geographyMSTId5:undefined, geographyMSTId6:undefined, geographyMSTId7:undefined, geographyMSTId8:undefined, groupOutletMST:undefined, groupOutletMSTId:undefined, legalInformation:undefined, location:undefined, logoBig:undefined, logoSmall:undefined, modifiedBy:undefined, modifiedOn:undefined, outletCategoryMST:undefined, outletCategoryMSTId:undefined, outletCode:undefined, outletGroupMST:undefined, outletGroupMSTId:undefined, outletMST:undefined, outletMSTId:undefined, outletName:undefined, outletTypeMST:undefined, outletTypeMSTId:undefined, ownerName:undefined, parentOutletMSTId:undefined, pincode:undefined, polygon:undefined, primaryContact:undefined, primaryEmail:undefined, purchasePriceListMST:undefined, purchasePriceListMSTId:undefined, registrationDate:undefined, registrationNumber:undefined, registrationValidityDate:undefined, salesPriceListMST:undefined, salesPriceListMSTId:undefined, salesTaxCodeMST:undefined, salesTaxCodeMSTId:undefined, secondaryContact:undefined, secondaryEmail:undefined, surchargeDTLList:undefined, taxMessage:undefined, termCondition:undefined, thanksNotes:undefined, transferPriceListMST:undefined, transferPriceListMSTId:undefined, webSite:undefined}}} data
     */
    create : (data) => {
        return axios.post(`/outletMST/create/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    delete : ({ id }) => {
        return axios.delete(`/outletMST/delete/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getSuppliers : () => {
        return axios.get(`/outletMST/getSuppliers/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byId : ({ id }) => {
        return axios.get(`/outletMST/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findPaginated : () => {
        return axios.get(`/outletMST/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    update : (data) => {
        return axios.put(`/outletMST/update/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{id:undefined},body:null}} data
     */
    uploadBigLogo : (data) => {
        return axios.post(`/outletMST/uploadFile/logoBig/id/${data.param.id}`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{id:undefined},body:null}} data
     */
    uploadSmallLogo : (data) => {
        return axios.post(`/outletMST/uploadFile/logoSmall/id/${data.param.id}`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default outletMSTCruds;
    