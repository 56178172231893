import { createSlice } from "@reduxjs/toolkit";
import outletMSTCruds from "../../_apis/cruds/outletMSTCrud";
import { cartSlice } from "./cartRedux";
import { formatDate, formatTime } from "../../helpers/Utils";
const REDUCER_NAME = "outletMST";

const TYPES = {
  OUTLET: "outlet",
  ALLOUTLET: "allOutlet",
  CURRENTOUTLET: 'currentOutlet'
};

export const outletMSTReduxSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return { ...occ, [cur]: { loading: false, error: null, data: null },
    };
    }, {}),
  },
  reducers: {
    catchError: (state, action) => {
      state[action.payload.type].loading = false;
      state[action.payload.type].error = action.payload.error;
    },
    startCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = true;
    },
    stopCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
    },
    dataRecieved: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = action.payload.data;
    },
  },
});


const selectCurrentOutlet = (state) => state.outletMST;



const startCall = (type) => outletMSTReduxSlice.actions.startCall({ type });
const stopCall = (type) => outletMSTReduxSlice.actions.stopCall({ type });
const dataRecieved = (type, data) =>
outletMSTReduxSlice.actions.dataRecieved({ type, data });
const catchError = (type, error) =>
outletMSTReduxSlice.actions.catchError({ type, error });

export const outletMSTActions = {
  getCurrentOutlet: (code) => (dispatch) => {
    dispatch(startCall(TYPES.OUTLET));
    return outletMSTCruds
      .byCode({code})
      .then( async (res) => {

        let surCharges = res?.surchargeDTLList;

        await dispatch(
          cartSlice.actions.setSurcharges(
            surCharges.filter((sur) => {
              if (surCharges.halfNHalf) return true;

              const today = new Date();
              const time = formatTime(today);
              const date = formatDate(today, "yyyy-mm-dd");

              let isValid = true;

              if (sur.day) {
                if ((sur.day === 7 ? 0 : sur.day) !== today.getDay())
                  isValid = false;
              } else if (
                isValid &&
                sur.toTime &&
                sur.fromTime &&
                sur.fromDate &&
                sur.toDate
              ) {
                if (
                  sur.fromTime <= time &&
                  sur.toTime >= time &&
                  sur.fromDate <= date &&
                  sur.toDate >= date
                )
                  isValid = true;
                else isValid = false;
              } else if (isValid && sur.toTime && sur.fromDate) {
                if (sur.fromTime <= time && sur.toTime >= time)
                  isValid = true;
                else isValid = false;
              } else if (isValid && sur.toDate && sur.fromDate) {
                if (sur.fromDate <= date && sur.toDate >= date)
                  isValid = true;
                else isValid = false;
              }
              return isValid;
            })
          )
        );
        dispatch(dataRecieved(TYPES.OUTLET, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.OUTLET, err));
      });
  },


  getAllOutlet: () => (dispatch)  => {
    dispatch(startCall(TYPES.ALLOUTLET));
    return outletMSTCruds
    .getOutletInfo()
    .then( (res) => {
      dispatch(dataRecieved(TYPES.ALLOUTLET, res));

    }
    )
    .catch((err) => {
      dispatch(catchError(TYPES.OUTLET, err));
    });
  },

  seletedOutlet: (data) => (dispatch) => {
    dispatch(startCall(TYPES.SELECTEDOutlet));

    return Promise.resolve(data)
    .then((res) => {
      dispatch(stopCall(TYPES.SELECTEDOutlet));
      dispatch(dataRecieved(TYPES.SELECTEDOutlet, res));
      return Promise.resolve(res);
    })
    .catch((res) => {
      dispatch(stopCall(TYPES.SELECTEDOutlet));
      dispatch(catchError(TYPES.SELECTEDOutlet, res));
      return Promise.reject(res);
    })
  },

  setCurrentOutlet: (outlet) => (dispatch, getState) => {
    const currentOutlet = selectCurrentOutlet(getState());
    // localStorage.setItem('selected-outlet', JSON.stringify(outlet));
    dispatch(dataRecieved(TYPES.CURRENTOUTLET, outlet));
  },

};
