import React from "react";

const SimpleSelect = (props) => {



  return (
    <>
      <select
        className="form-select simple-select form-control"
        aria-label="Default select example"
        onChange={props.change}
        style={props.style}
        value={props.value}
      >
        {props.data.map((item, index) => {
          return (
            <option value={item} key={index}>
              {item}
            </option>
          );
        })}
      </select>
    </>
  );
};
export default SimpleSelect;
