
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const deliveryChargesDTLCruds = {

    all : () => {
        return axios.get(`/deliveryChargesDTL/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },

    getPolygon: () => {
        return axios.get("/deliveryChargesDTL/getAllPolygonWithOutletInfo")
        .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
        .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getAllActive : () => {
        return axios.get(`/deliveryChargesDTL/allActive/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{active:undefined, createdBy:undefined, createdOn:undefined, deliveryChargesDTLId:undefined, deliveryChargesMST:undefined, deliveryChargesMSTId:undefined, expressCharges:undefined, geographyMSTIds:undefined, minimumOrderValue:undefined, modifiedBy:undefined, modifiedOn:undefined, normalCharges:undefined, polygon:undefined}}} data
     */
    create : (data) => {
        return axios.post(`/deliveryChargesDTL/create/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    delete : ({ id }) => {
        return axios.delete(`/deliveryChargesDTL/delete/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getDeliveryChargeByGeography : ({ id }) => {
        return axios.get(`/deliveryChargesDTL/geographyId/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },    
        
    getMinimumOrder : ({ id }) => {
        return axios.get(`/deliveryChargesDTL/getDeliveryChargesDTL/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byId : ({ id }) => {
        return axios.get(`/deliveryChargesDTL/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byOutletId : ({ id }) => {
        return axios.get(`/deliveryChargesDTL/outletId/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findPaginated : () => {
        return axios.get(`/deliveryChargesDTL/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    update : (data) => {
        return axios.put(`/deliveryChargesDTL/update/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default deliveryChargesDTLCruds;
    