import { createSlice } from "@reduxjs/toolkit";
import deliveryChargesDTLCruds from "../../_apis/cruds/deliveryChargesDTLCrud";
const REDUCER_NAME = "deliveryChargeDTL";
const TYPES = {
  DELIVERYCHARGE: "deliveryCharge",
  CHECKMINIMUM: "checkMinimum",
  DELIVERYADDRESS: "deliveryAddress",
  POLYGON: "polygon",

};
export const deliveryChargesDTLReduxSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return { ...occ, [cur]: { loading: false, error: null, data: null } };
    }, {}),
    polygon: { loading: false, error: null, data: null },
  },
  reducers: {
    catchError: (state, action) => {
      state[action.payload.type].loading = false;
      state[action.payload.type].error = action.payload.error;
    },
    startCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = true;
    },
    stopCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
    },
    dataRecieved: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = action.payload.data;
    },
  },
});

const startCall = (type) =>
  deliveryChargesDTLReduxSlice.actions.startCall({ type });
const stopCall = (type) =>
  deliveryChargesDTLReduxSlice.actions.stopCall({ type });
const dataRecieved = (type, data) =>
  deliveryChargesDTLReduxSlice.actions.dataRecieved({ type, data });
const catchError = (type, error) =>
  deliveryChargesDTLReduxSlice.actions.catchError({ type, error });

export const deliveryChargesDTLActions = {

  setSuburbId: (id) => (dispatch) => {
    dispatch(dataRecieved(TYPES.DELIVERYADDRESS, id))
  },

  fetchPolygon: () => (dispatch) => {
    dispatch(startCall(TYPES.POLYGON));
    return deliveryChargesDTLCruds
      .getPolygon()
      .then((res) => {
        dispatch(dataRecieved(TYPES.POLYGON, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.POLYGON, err));
      });
  },

  getDeliveryCharge: (id) => (dispatch) => {
    dispatch(startCall(TYPES.DELIVERYCHARGE));
    return deliveryChargesDTLCruds
      .getDeliveryChargeByGeography({ id })
      .then((res) => {
        // console.log("response");
        // console.log(res);
        dispatch(dataRecieved(TYPES.DELIVERYCHARGE, res));

        if (res) {
          return Promise.resolve(res);
        } else if (res === 0) {
          return Promise.resolve(res);
        } else {
          Promise.reject("null");
        }
      })
      .catch((err) => {
        dispatch(catchError(TYPES.DELIVERYCHARGE, err));
        return Promise.reject(err);
      });
  },

  getMinimumOrderValue: (id) => (dispatch) => {
    dispatch(startCall(TYPES.CHECKMINIMUM));
    // return deliveryChargesDTLCruds
    //   .getMinimumOrder({ id })
    return  Promise.resolve(id)
      .then((res) => {
        dispatch(stopCall(TYPES.CHECKMINIMUM));
        dispatch(dataRecieved(TYPES.CHECKMINIMUM, res));
        if (res) {
          return Promise.resolve(res);
        } else if (res === 0) {
          return Promise.resolve(res);
        } else {
          Promise.reject("null");
        }
      })
      .catch((err) => {
        dispatch(stopCall(TYPES.CHECKMINIMUM));
        dispatch(catchError(TYPES.CHECKMINIMUM, err));
        return Promise.reject(err);
      });
    
  },
};
