
import React from 'react';

const Spinner = ({ text = null, wrapperStyle = {}, wrapperClass = "", spinnerClass = "size-large" })  => {
// const Spinner = ({ text = null, wrapperStyle = {}, wrapperClass = "", spinnerClass = "text-warning size-large" })  => {
    return (
        <div className={wrapperClass} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...wrapperStyle }}>
            <div className={`spinner spinner-border ${spinnerClass}`} style={{color: 'var(--test-primary)'}}role="status">
                <span className="sr-only">Loading...</span>
            </div>
            {text && (
                <span className="ml-3" style={{fontSize:"22px",fontWeight:"700"}}>{text}</span>
            )}
        </div>
    );
};

export default Spinner;