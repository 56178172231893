// import matchSorter from 'match-sorter'

import { sortArray } from "./Utils";

export const getByGeoTypeCode = (data, code) => {
  return data.filter((row) => row?.geographyTypeCode === code);
};
export const getByGeoTypeCodeAndParentVal = (data, code, parentName) => {
  if (data) {
    return data.filter(
      (row) =>
        row?.geographyTypeCode === code &&
        row.parentGeographyMST?.geographyName === parentName
    );
  } else {
    return [];
  }
};
export const getPincodeAndSuburbCode = (data) => {
  if (data) {
    let suburbNameList = getByGeoTypeCode(data, "GT5");
    let list = [];
    suburbNameList.map((row) => {
      list.push({
        value: row.geographyName + "-" + row.parentGeographyMST.geographyName,
        label: row.geographyName + "-" + row.parentGeographyMST.geographyName,
        id: row.geographyMSTId + "-" + row.parentGeographyMST.geographyMSTId
      });
    });
    return sortArray(list,"value");
  } else {
    return [];
  }
};
export const getSuburbAndStreetNameOptions = (data) => {
  if (data) {
    let suburbNameList = getByGeoTypeCode(data, "GT5");
    let list = [];
    suburbNameList.forEach((suburb) => {
      getStreetName(data, suburb.geographyName).forEach(street => {
        list.push({
          value: street.label + "-" + suburb.geographyName,
          label: street.value + " - " + suburb.geographyName,
          id: street.id + "-" + suburb.geographyMSTId
        });
      })
    });
    return sortArray(list,"value");
  } else {
    return [];
  }
};
export const getStreetName = (data, parentName) => {
  let parentArr = parentName.split("-");
  let streetNameList = getByGeoTypeCodeAndParentVal(data, "GT6", parentArr[0]);
  let list = [];
  streetNameList.map((row) => {
    list.push({
      value: row.geographyName,
      label: row.geographyName,
      id: row.geographyMSTId
    });
  });
  return list;
};
