import { createSlice } from "@reduxjs/toolkit";
import customerMSTCruds from '../../_apis/cruds/customerMSTCrud';
import { TOKEN_NAME } from "../../_apis/setupAxios";
const REDUCER_NAME = "customerMST"

const TYPES = {
    GET_HISTORY: 'history',
    GET_FAV:'favourite',
    SIGNUP_CUSTOMER:'sign_up'
}
export const customerMSTSlice = createSlice({
    name: REDUCER_NAME,
    initialState: {
        ...Object.values(TYPES).reduce((occ, cur) => { return ({ ...occ, [cur]: { loading: false, error: null, data: null } }) }, {})
    },
    reducers: {
        catchError: (state, action) => {
            state[action.payload.type].loading = false;
            state[action.payload.type].error = action.payload.error;
        },
        startCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = true;
        },
        stopCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
        },
        dataRecieved: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
            state[action.payload.type].data = action.payload.data;
        },
    },
})

const startCall = (type) => customerMSTSlice.actions.startCall({ type })
const stopCall = (type) => customerMSTSlice.actions.stopCall({ type })
const dataRecieved = (type, data) => customerMSTSlice.actions.dataRecieved({ type, data })
const catchError = (type, error) => customerMSTSlice.actions.catchError({ type, error })
export const customerMSTActions = {
    getCustomerHistory: (id) => dispatch => {
        dispatch(startCall(TYPES.GET_HISTORY))
        return customerMSTCruds.getHistory({id }).then(res => {
            dispatch(dataRecieved(TYPES.GET_HISTORY, res))
        }).catch(err => {
            dispatch(catchError(TYPES.GET_HISTORY, err))
        })
    },
    getCustomerFav:(id)=>dispatch=>{
        dispatch(startCall(TYPES.GET_FAV))
        return customerMSTCruds.getFavourite({id }).then(res => {
            dispatch(dataRecieved(TYPES.GET_FAV, res))
        }).catch(err => {
            dispatch(catchError(TYPES.GET_FAV, err))
        })
    },
    signUpIpCustomer:(data)=>dispatch=>{
        dispatch(startCall(TYPES.SIGNUP_CUSTOMER))
        return customerMSTCruds.signUpCustomerByIp(data).then(res => {
            if(res?.jwtToken) {
                localStorage.setItem(TOKEN_NAME,res.jwtToken)
            }
            dispatch(dataRecieved(TYPES.SIGNUP_CUSTOMER, res))
            return Promise.resolve(res);
        }).catch(err => {
            dispatch(catchError(TYPES.SIGNUP_CUSTOMER, err))
            return Promise.reject(err);
        })
    },
    updateSignUpIpCustomer:(data)=>dispatch=>{
        dispatch(startCall(TYPES.SIGNUP_CUSTOMER))
        return customerMSTCruds.updateSignUpCustomer(data).then(res => {
            dispatch(dataRecieved(TYPES.SIGNUP_CUSTOMER, res))
            return Promise.resolve(res);
        }).catch(err => {
            dispatch(catchError(TYPES.SIGNUP_CUSTOMER, err))
            return Promise.reject(err);
        })
    }
}