
import axios from 'axios'
import {v4 as uuidv4} from 'uuid';
import { errorMessageFormatter } from '../../helpers/Utils'
const userMSTCruds = {


   /**
     * @param {{param:{key:undefined},body:null}} data
     */
    addClaimToToken : (data) => {
        return axios.post(`/userMST/addIntoToken/${data.param.key}`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{password:undefined, role:undefined}}} data
     */
    checkAuthForAdminManager : (data) => {
        return axios.post(`/userMST/adminManager/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    all : () => {
        return axios.get(`/userMST/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getAllActive : () => {
        return axios.get(`/userMST/allActive/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{password:undefined, role:undefined}}} data
     */
    checkAuth : (data) => {
        return axios.post(`/userMST/auth/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{newPassword:undefined, oldPassword:undefined, username:undefined}}} data
     */
    changePassword : (data) => {
        return axios.post(`/userMST/changePassword/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{active:undefined, createdBy:undefined, createdOn:undefined, deliveryPerson:undefined, dueBalance:undefined, forgotPasswordOTP:undefined, forgotPasswordOTPExpiry:undefined, gender:undefined, invoiceDiscountLimit:undefined, ipAddress:undefined, ipUser:undefined, lock:undefined, modifiedBy:undefined, modifiedOn:undefined, password:undefined, primaryContactNumber:undefined, primaryEmailId:undefined, profileImage:undefined, secondaryContactNumber:undefined, secondaryEmailId:undefined, signUp:undefined, userAddressMSTList:undefined, userCode:undefined, userExternalCode:undefined, userGroupMST:undefined, userGroupMSTId:undefined, userGroupManagerMST:undefined, userGroupManagerMSTId:undefined, userMSTId:undefined, userName:undefined, userOutletAccessDTLList:undefined, userRoleMST:undefined, userRoleMSTId:undefined}}} data
     */
    create : (data) => {
        return axios.post(`/userMST/create/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    delete : ({ id }) => {
        return axios.delete(`/userMST/delete/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{username:undefined}}} data
     */
    forgotPassword : (data) => {
        return axios.post(`/userMST/forgotPassword/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byId : ({ id }) => {
        return axios.get(`/userMST/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getLoggedInUser : () => {
        return axios.get(`/userMST/loggedInUser/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{password:undefined, system:undefined, userName:undefined}}} data
     */
    loginUser : (data) => {
        return axios.post(`/userMST/login/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    createUserByIp : () => {
        let uuid = sessionStorage.getItem('ip')
        if(!uuid){
            let uuid = uuidv4()
            sessionStorage.setItem('ip', uuid)
            return axios.get(`/userMST/login/byIp/${uuid}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
        }
        else{
            let uuid = sessionStorage.getItem('ip')
            return axios.get(`/userMST/login/byIp/${uuid}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
        }
        // return axios.get(`/userMST/login/byIp/`)
        //     .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
        //     .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{outletCode:undefined, pin:undefined, systemCode:undefined}}} data
     */
    loginByPin : (data) => {
        return axios.post(`/userMST/login/byPin/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{outlet:undefined, password:undefined, username:undefined}}} data
     */
    clockInUser : (data) => {
        return axios.post(`/userMST/login/clockIn`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{outlet:undefined, password:undefined, username:undefined}}} data
     */
    clockOutUser : (data) => {
        return axios.post(`/userMST/login/clockOut`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findPaginated : () => {
        return axios.get(`/userMST/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{newPassword:undefined, otp:undefined, username:undefined}}} data
     */
    resetPassword : (data) => {
        return axios.post(`/userMST/resetPassword/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getStaff : () => {
        return axios.get(`/userMST/staff/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getActiveStaff : () => {
        return axios.get(`/userMST/staff/allActive/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    update : (data) => {
        return axios.put(`/userMST/update/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{id:undefined},body:null}} data
     */
    uploadProfile : (data) => {
        return axios.post(`/userMST/uploadFile/id/${data.param.id}`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{active:undefined, createdBy:undefined, createdOn:undefined, deliveryPerson:undefined, dueBalance:undefined, forgotPasswordOTP:undefined, forgotPasswordOTPExpiry:undefined, gender:undefined, invoiceDiscountLimit:undefined, ipAddress:undefined, ipUser:undefined, lock:undefined, modifiedBy:undefined, modifiedOn:undefined, password:undefined, primaryContactNumber:undefined, primaryEmailId:undefined, profileImage:undefined, secondaryContactNumber:undefined, secondaryEmailId:undefined, signUp:undefined, userAddressMSTList:undefined, userCode:undefined, userExternalCode:undefined, userGroupMST:undefined, userGroupMSTId:undefined, userGroupManagerMST:undefined, userGroupManagerMSTId:undefined, userMSTId:undefined, userName:undefined, userOutletAccessDTLList:undefined, userRoleMST:undefined, userRoleMSTId:undefined}}} data
     */
    createUser : (data) => {
        return axios.post(`/userMST/user/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default userMSTCruds;
    