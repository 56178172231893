import { createSlice } from "@reduxjs/toolkit";
import orderMSTCruds from "../../_apis/cruds/orderMSTCrud";
const REDUCER_NAME = "orderMST";

const TYPES = {
  ORDER_TYPE: "orderType",
  CUSTOMER_ADDRESS: "customerAddress",
  ORDER_DATE_TIME: "orderDateTime",
  RUNNING_ORDER: "runningOrder",
  ORDER_STAGE: "orderStage",
  TEMP_USER: "tempUser",
  GUEST_CUST: "guestCust",
  ORDER_TIME: "orderTime",
};

export const orderMSTReduxSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return { ...occ, [cur]: { loading: false, error: null, data: null } };
    }, {}),
  },
  reducers: {
    catchError: (state, action) => {
      state[action.payload.type].loading = false;
      state[action.payload.type].error = action.payload.error;
    },
    startCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = true;
    },
    stopCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
    },
    dataRecieved: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = action.payload.data;
    },
    resetToInitial: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = null;
    },
  },
});

const startCall = (type) => orderMSTReduxSlice.actions.startCall({ type });
const stopCall = (type) => orderMSTReduxSlice.actions.stopCall({ type });
const dataRecieved = (type, data) =>
  orderMSTReduxSlice.actions.dataRecieved({ type, data });
const catchError = (type, error) =>
  orderMSTReduxSlice.actions.catchError({ type, error });
const resetToInitial = (type) =>
  orderMSTReduxSlice.actions.resetToInitial({ type });

export const orderMSTActions = {
  setOrderType: (orderType) => (dispatch) => {
    dispatch(dataRecieved(TYPES.ORDER_TYPE, orderType));
  },
  setCustomerAddress: (customerAddress) => (dispatch) => {
    dispatch(dataRecieved(TYPES.CUSTOMER_ADDRESS, customerAddress));
  },
  setOrderDateTime: (orderDateTime) => (dispatch) => {
    dispatch(dataRecieved(TYPES.ORDER_DATE_TIME, orderDateTime));
  },
  resetOrderType: () => (dispatch) => {
    dispatch(resetToInitial(TYPES.ORDER_TYPE));
  },
  resetCustomerAddress: () => (dispatch) => {
    dispatch(resetToInitial(TYPES.CUSTOMER_ADDRESS));
  },
  resetDateTime: () => (dispatch) => {
    dispatch(resetToInitial(TYPES.ORDER_DATE_TIME));
  },
  getRunningOrder: (id) => (dispatch) => {
    dispatch(startCall(TYPES.RUNNING_ORDER));
    return orderMSTCruds
      .runningOrderByCustomer({ id })
      .then((res) => {
        dispatch(dataRecieved(TYPES.RUNNING_ORDER, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.RUNNING_ORDER, err));
      });
  },
  getCurrentStage: (id) => (dispatch) => {
    dispatch(startCall(TYPES.RUNNING_ORDER));
    return orderMSTCruds
      .getCurrentOrderStage({ id })
      .then((res) => {
        dispatch(dataRecieved(TYPES.RUNNING_ORDER, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.RUNNING_ORDER, err));
      });
  },
  tempUser: (data) => (dispatch) => {
    if (data) {
      let custData = {
        userMSTId: data.userMSTId,
        customerMSTId: data.customerMSTId,
        customerFirstName: data.customerFirstName,
        customerLastName: data.customerLastName,
        primaryEmail: data.primaryEmail,
        primaryContact: data.primaryContact,
      };
      dispatch(dataRecieved(TYPES.TEMP_USER, custData));
    } else {
      dispatch(dataRecieved(TYPES.TEMP_USER, data));
    }
  },
  setGuestCustomer: (data) => (dispatch) => {
    if (data) {
      let custData = {
        userMSTId: data.userMSTId,
        customerMSTId: data.customerMSTId,
        customerFirstName: data.customerFirstName,
        customerLastName: data.customerLastName,
      };
      dispatch(dataRecieved(TYPES.GUEST_CUST, custData));
    } else {
      dispatch(dataRecieved(TYPES.GUEST_CUST, data));
    }
  },
  startOrderType: () => (dispatch) => {
    dispatch(startCall(TYPES.ORDER_TIME));
  },
  stoptOrderType: () => (dispatch) => {
    dispatch(stopCall(TYPES.ORDER_TIME));
  },
};
