import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import CustomDialog from "../../componets/dialog/CustomDialog";
import BlockButton from "../../componets/FormComponent/BlockButton";
import SimpleSelect from "../../componets/FormComponent/SimpleSelect";
import TextInput from "../../componets/FormComponent/TextInput";
import Checkbox from "../../componets/FormComponent/CheckBox";
import SearchableSelect from "../../componets/FormComponent/SearchableSelect";
import Geosuggest from "react-geosuggest";
import {
  parseShiftDTL,
  getDateSlots,
  getFormattedTime,
  checkTimeIn,
  getMonthValue,
  removeDayFromDate,
  convertToTimeZone
} from "../../helpers/DialogHelper";
import {
  getPincodeAndSuburbCode,
  getStreetName,
  getSuburbAndStreetNameOptions,
} from "../../helpers/GeographyHelper";
import { orderMSTActions } from "../../_redux/files/orderMSTRedux";
import Select, { components } from "react-select";

import { deliveryChargesDTLActions } from "../../_redux/files/deliveryChargeRedux";
import { cartActions } from "../../_redux/files/cartRedux";
import ROUTES from "../../constants/ROUTES";
import "./DeliveryDialog.scss";
import { useHistory } from "react-router";
import { isBlank } from "../../helpers/Utils";
import Spinner from "../../componets/etc/Spinner";
import { useCallback } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import deliveryChargesDTLCruds from "../../_apis/cruds/deliveryChargesDTLCrud";
import { outletMSTActions, outletMSTReduxSlice } from "../../_redux/files/outletMSTRedux";
import { selectOutletSlice } from "../../_redux/files/SelectedOutletRedux";
import { authActions } from "../../_redux/files/authRedux";
import { outletShiftActions } from "../../_redux/files/outletShiftRedux";
import OrderTypeCustomDialog from "../../componets/dialog/OrderTypeCustomDialog";
export const DeliveryDialog = (props) => {
  //const [open, setOpen] = useState(props.open);
  const history = useHistory();
  const [delivery, setDelivery] = useState("Now");
  const [prevOutlet, setPrevOutlet] = useState(false);
  const [unitNumber, setUnitNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [orderDates, setOrderDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [outletMain, setOUtletMain] = useState(null);
  const [streeName, setStreeName] = useState([]);
  const [selectedStreetName, setSelectedStreetName] = useState(null);
  const [streetSuburbData, setStreetSuburbData] = useState([]);
  const [selectedStreetSuburb, setSelectedStreetSuburb] = useState(null);
  // const [selectedStreetSuburb, setSelectedStreetSuburb] = useState(null);
  const [open, setOpen] = useState(true);
  const [storeTime, setStoreTime] = useState([]);
  const [orderTimes, setOrderTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [remember, setRemember] = useState(true);
  const [postalCode, setPostalCode] = useState("");
  const [storeOpen, setStoreOpen] = useState(true);
  const [deliveryChargeFlag, setDeliveryChargeFlag] = useState(true);
  const [errorPojo, setErrorPojo] = useState(null);
  //  State for new addressbar change
  const [polygonPath, setPolygonPath] = useState([]);
  const [userLocation, setUserLocation] = useState({});
  const [dilverable, setDeliverable] = useState(null);
  const [deliveryMessage, setDeliveryMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confrimOutlet, setConfirmOutlet] = useState(false);
  const [isMultipleOutlet, setIsmultipleOutlet] = useState(null);
  const [latlong, setLatLong] = useState(null);
  const [polygonsContainingUser, setPolygonsContainingUser] = useState([]);
  const [userAddress, setUserAddress] = useState();
  const [objAddress, setObjAddress] = useState();
  const [showFullAddress, setFullAddress] = useState(true)
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedShiwAddress, setSelectedShowAddress] = useState({});
  const [confirmSelectedOutlet, setConfirmSelectedOutlet] = useState(null);
  const [addressChangeTrig, setAddressChangeTrig] = useState(false);
  const dispatch = useDispatch();


  const outletShiftDetail = useSelector(
    (state) => state.outletShift.outletShiftDtl.data
  );

  const outletMainData = useSelector((state) => state.outletMST.currentOutlet);


    const { authUser, isAuthenticating, error } = useSelector(({ auth }) => (
        {
            authUser: auth.authUser,
            isAuthenticating: auth.loading.loadingOutlet,
            error: auth.error.authenticating
        }
    ))


    const allOutlet = useSelector((state) => state.outletMST.allOutlet.data);



  useEffect(() => {
    // if (history.location.pathname === "/" || history.location.pathname === '/menu/check-out/check-out') {
    // if (history.location.pathname === "/" || ) {
    if(history.location.pathname === "/outlet-selection" ) {
      setFullAddress(false);
    }  if (allOutlet?.length === 1) {
      setFullAddress(true);
    }

  },[allOutlet])

  const polygonAddress = polygonsContainingUser.map((ot) =>  ot.deliveryChargesMST.outletMST)


  useEffect(() => {
  let filtetredOutlet =    polygonAddress?.filter((item) => {
    return  outletMainData?.data?.[0]?.outletCode ===  item.outletCode
  })

if  (filtetredOutlet.length){
    setIsmultipleOutlet(true);
  }
    setOUtletMain(filtetredOutlet[0]?.outletCode)
  }, [outletMainData, polygonAddress])

  const handleOutletSelect = (e) => {
    debugger
    setOUtletMain(e.value)
    let selectedOutlet = polygonAddress?.filter((item) =>
     {
      return   e.value === item.outletCode
      }
);


    setConfirmSelectedOutlet(selectedOutlet)

    // if (!authUser) {
        if(selectedOutlet.length){
          if(selectedOutlet?.[0]?.outletCode !== outletMainData?.data?.[0]?.outletCode){
          dispatch(authActions.authenticateUser(selectedOutlet?.[0])).then(() => {
          // dispatch(outletMSTActions.setCurrentOutlet(selectedOutlet));
          setIsmultipleOutlet(true);
        })

        }
        // }
  }
  }

  // State for new addressbar change
  const orderType = useSelector((state) => state.orderMST.orderType);
  const { loadingOutletShift, dataOutletShift, errorOutletShift } = useSelector(
    (state) => {
      return {
        loadingOutletShift: state.outletShift.outletShiftDtl.loading,
        dataOutletShift: state.outletShift.outletShiftDtl.data,
        errorOutletShift: state.outletShift.outletShiftDtl.error,
      };
    }
  );





  const { loadingGeo, dataGeo, errorGeo } = useSelector((state) => {
    return {
      loadingGeo: state.geographyMST.geography.loading,
      dataGeo: state.geographyMST.geography.data,
      errorGeo: state.geographyMST.geography.error,
    };
  });
  const customerAddress = useSelector(
    (state) => state.orderMST.customerAddress
  );

  const custData = useSelector((state) => state.auth.authCust);
  const custOutlet = useSelector((state) => state.auth.authOutlet);



  // useEffect(() => {
  //   if (data === "delivery") {
  //     setOpen(true);
  //   }
  // }, [data]);

  // NEW CHANGES

  const geosuggestEl = useRef(null);
  const clearInput = () => {
    geosuggestEl.current.clear();
    setUserLocation({});
  };

  const isUserInPolygon = useCallback(() => {
    const google = props.google;
    const userLatLng = new google.maps.LatLng(
      userLocation.lat,
      userLocation.lng
    );

    let deliverable = false;
    let newPolygonsContainingUser = [];

    let deliveryMessage = !userLocation.lat
      ? ""
      : "Sorry, we cannot deliver in your area.";

    if (polygonPath.length) {
      for (let i = 0; i < polygonPath.length; i++) {
        if (polygonPath[i].polygon && polygonPath[i].polygon.length > 0) {
          const polygonData = JSON.parse(polygonPath[i].polygon);
          if (polygonData.length) {
            const parsedPolygon = polygonData.map((coord) => ({
              lat: coord.lat,
              lng: coord.lng,
            }));
            const polygon = new google.maps.Polygon({ paths: parsedPolygon });
            if (
              google.maps.geometry.poly.containsLocation(userLatLng, polygon)
            )

            {
              deliverable = true;
              deliveryMessage = "";
              newPolygonsContainingUser.push({
                polygon: polygon,
                expressCharges: polygonPath[i].expressCharges,
                normalCharges: polygonPath[i].normalCharges,
                minimumOrderValue: polygonPath[i].minimumOrderValue,
                deliveryChargesMST: polygonPath[i].deliveryChargesMST,
              });
            }
          }
        }
      }
    }





    setDeliverable(deliverable);
    if(newPolygonsContainingUser.length > 1) {
        debugger
        if(!outletMain) {

          setIsmultipleOutlet(false);
        }
    } else {
      setIsmultipleOutlet(true);
    }
    setDeliveryMessage(deliveryMessage);
    setPolygonsContainingUser(newPolygonsContainingUser);

    return deliverable;
  }, [props.google, userLocation, polygonPath]);

   const onSuggestSelect = async (suggest) => {
    setSelectedAddress(suggest?.label);

    setUserAddress(suggest?.gmaps?.address_components);
    setUserLocation(suggest?.location);
    if (suggest && suggest.gmaps && suggest.gmaps.address_components) {
      const components = suggest.gmaps.address_components;

      setLatLong(suggest.location);
      const locality = components.find((component) =>
        component.types.includes("locality")
      );

    } else {
    }
  };


  useEffect(() => {
    if(addressChangeTrig) {
      if(polygonsContainingUser.length === 1) {
    let allOutlet = polygonsContainingUser.map((item) => {
      return item.deliveryChargesMST.outletMST
    })

    const uniqueObjects = allOutlet.filter((obj, index, self) =>
    index === self.findIndex((o) => (
        JSON.stringify(o) === JSON.stringify(obj)
    ))
);
    setConfirmSelectedOutlet(uniqueObjects)
    // dispatch(outletMSTActions.setCurrentOutlet(uniqueObjects));

    if(uniqueObjects?.[0]?.outletCode !== outletMainData?.data?.[0]?.outletCode) {
  if(uniqueObjects.length === 1){
    setPrevOutlet(true)
    dispatch(authActions.authenticateUser(uniqueObjects?.[0])).then(() => {
      // history.push(ROUTES.home._path);
      dispatch(outletMSTActions.setCurrentOutlet(uniqueObjects));
    })
  }

}
}
}
  }, [polygonsContainingUser, addressChangeTrig, showFullAddress])



  useEffect(() => {
    const checkDeliveryArea = async () => {
      if (userLocation && polygonPath.length) {
        setLoading(true);
        try {
          const isDeliverable = await isUserInPolygon();
        } catch (error) {
          console.error(
            "An error occurred while checking delivery area:",
            error
          );
        } finally {
          setLoading(false);
        }
      }
    };

    checkDeliveryArea();
  }, [userLocation, polygonPath, isUserInPolygon]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setIsLoading(true);
  //       const res = await deliveryChargesDTLCruds.getPolygon();
  //       setPolygonPath(res);
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);
  const polygonData = useSelector(
    (state) => state.deliveryChargeDTL?.polygon || []
  );
  const polygonLoading = useSelector(
    (state) => state.deliveryChargeDTL.polygon.loading
  );

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     setIsLoading(true);
    //     await dispatch(deliveryChargesDTLActions.fetchPolygon());
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };

    if (!polygonData?.data?.length) {
      // fetchData();
    } else {
      setPolygonPath(polygonData.data || []);
    }
  }, []);

  useEffect(() => {
    setIsLoading(polygonLoading);
    if (polygonData?.data?.length) {
      setPolygonPath(polygonData.data || []);
    }
  }, [polygonData, polygonLoading]);

  // useEffect(() => {
  //   if (!polygonData?.data?.length) {
  //     setIsLoading(true)
  //     dispatch(deliveryChargesDTLActions.fetchPolygon());
  //     console.log(polygonData?.data)
  //     setPolygonPath(polygonData?.data || []);
  //     setIsLoading(false);
  //   } else {
  //     setPolygonPath(polygonData?.data || []);
  //     setIsLoading(false);
  //   }
  // }, [dispatch, polygonData]);

  // const polygonData = useSelector(state => state.deliveryChargeDTL?.polygon || []);
  // const polygonLoading = useSelector(state => state.deliveryChargeDTL.polygon.loading);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setIsLoading(true);
  //       await dispatch(deliveryChargesDTLActions.fetchPolygon());
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   if (!polygonData?.data?.length && !polygonLoading) {
  //     fetchData();
  //   } else {
  //     setPolygonPath(polygonData?.data || []);
  //   }
  // }, [dispatch, polygonData, polygonLoading]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setIsLoading(true);

  //       const res = await deliveryChargesDTLCruds.getPolygon();
  //       setPolygonPath(res);
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   if (!polygonPath.length) {
  //     fetchData();
  //   }
  // }, [polygonPath.length]);

  const svgCode =
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" id="Layer_1" x="0px" y="0px" enable-background="new 0 0 24 24" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" width="22" stroke-width="2"><circle cx="9.5" cy="9.5" r="7.5" fill="none" stroke-miterlimit="10"></circle><line x1="14.7" x2="22" y1="14.7" y2="22" fill="none" stroke-miterlimit="10"></line></svg>';

  const locationIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" id="Layer_1" x="0px" y="0px" enable-background="new 0 0 24 24" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" width="26" stroke-width="1.5"><circle cx="12" cy="9.6" r="3.4" fill="none" stroke-miterlimit="10"></circle><path fill="none" stroke-miterlimit="10" d="M12,2C8.4,2,4.3,4.7,4.3,9s3.2,8.9,7.7,13c4.5-4.1,7.7-8.7,7.7-13 S15.6,2,12,2z"></path></svg>';
  // NEW CHANGES
  // useEffect(() => {
  //   if (orderType) {
  //     if (orderType.data === "OT01") {
  //       return;
  //     }
  //   }

  //   let savedAddress = customerAddress.data;
  //   if (!savedAddress?.streetName && custData) {
  //     console.log(custData)
  //     let defaultAddress = custData?.customerAddressDTLList?.filter(
  //       (row) => row.default
  //     );
  //     if (defaultAddress && defaultAddress.length > 0) {
  //       let address = defaultAddress[0];

  //       if (address) {

  //       // setSelectedStreetSuburb(selectedStreet);
  //       setPostalCode(address.pincode);
  //       setStreetNumber(address.streetNumber);
  //        setLatLong({lat: address.latitude ,lng:address.longitude})
  //     }
  //   }
  //   } else if (savedAddress?.streetName) {
  //     var selectedStreet = {
  //       value: savedAddress.streetName + "-" + savedAddress.suburbName,
  //       label: savedAddress.streetName + "-" + savedAddress.suburbName,
  //       id: savedAddress.streetNameId + "-" + savedAddress.suburbId,
  //     };
  //     // setSelectedStreetSuburb(selectedStreet);
  //     setPostalCode(savedAddress.pincode);
  //     setStreetNumber(savedAddress.streetNumber);
  //     setUnitNumber(savedAddress.unitNumber);
  //   }
  // }, [props.open]);

  useEffect(() => {
    // setStreetSuburbData(getPincodeAndSuburbCode(dataGeo))
    setStreetSuburbData(getSuburbAndStreetNameOptions(dataGeo));
  }, [dataGeo]);

  useEffect(() => {
    if (storeTime.length === 0 && dataOutletShift ) {
       setStoreOpen(false);
      setOpen(false);
    }
  }, [storeTime, dataOutletShift]);

  useEffect(() => {
    let orderDateSlots = getDateSlots();
    let slotDate = null;
    let data = [];
    for (let i = 0; i < orderDateSlots.length; i++) {
      let onlyDate = removeDayFromDate(orderDateSlots[i]);
      data = dataOutletShift
        ? parseShiftDTL(dataOutletShift, new Date(onlyDate), "Delivery")
        : [];

      if (data.length > 0) {
        slotDate = onlyDate;
        break;
      } else {
        orderDateSlots.splice(i, 1);
        i--;
      }
    }
    setStoreTime(data);
    setOrderDates(orderDateSlots);
    if (data.length === 0 && dataOutletShift) {
      setStoreOpen(false);
      setOpen(false);
    } else {
      if (convertToTimeZone(new Date()).getDate() === new Date(slotDate).getDate()) {
        let currentTime = getFormattedTime(
          new Date(new Date(slotDate).setTime(convertToTimeZone(new Date()).getTime()))
        );
        if (
          checkTimeIn(
            new Date(slotDate).getFullYear() +
              "-" +
              getMonthValue(slotDate?.split(" ")[1]) +
              "-" +
              new Date(slotDate).getDate() +
              " " +
              currentTime,
            dataOutletShift,
            "Delivery"
          )
        ) {
          setOpen(true);
          setStoreOpen(true);
          data = ["Now", ...data];
        }
      }
    }
    setOrderTimes(data);
    setSelectedDate(new Date(slotDate));
    setSelectedTime(data.length > 0 ? data[0] : null);
  }, [dataOutletShift]);
  const handleClose = (e) => {
    props.closeDialog();
  };
  const checkSlotAvailable = (date, time) => {
    let data = dataOutletShift
      ? parseShiftDTL(dataOutletShift, date, "Delivery")
      : [];
    if (data.length > 0) {
      if (time === null || time === "Now") return true;
      if (data.includes(time)) {
        return true;
      }
    }
    return false;
  };
  const validateAddress = () => {
    const errors = { valid: true };
    if (!selectedStreetSuburb) {
      errors.streetSuburb = "Required.";
      // errors.valid = false;
    }
    if (isBlank(streetNumber)) {
      errors.streetNumber = "Required.";
      errors.valid = false;
    }
    setErrorPojo(errors);
    return errors.valid;
  };



  useEffect(() => {
      if(outletMainData){
      if (!customerAddress?.data?.address1) {
      setSelectedAddress("");
      setUnitNumber("")
      setPolygonsContainingUser([])
      setDeliverable(null)
    }
    }
  },[props.open])

  useEffect(() => {
debugger
    const storedAddress = localStorage.getItem("address");
    let storedLatLong = JSON.parse(storedAddress);
    let suggest = {
      lat: storedLatLong?.latitude,
      lng: storedLatLong?.longitude,
    };
    let savedAddress = customerAddress.data;

    if (storedLatLong) {
      setUnitNumber(storedLatLong?.unitNumber);
      setUserLocation(suggest);
      setUserAddress(storedLatLong?.userAddress);
      setLatLong(suggest);
      const address = storedAddress ? JSON.parse(storedAddress).address1 : "";
      setSelectedAddress(address);
    } else if (!savedAddress?.streetName && custData) {
      // if(outletMainData){
        // custOutlet
      if (!savedAddress?.address1 && custData) {
        let defaultAddress = custData?.customerAddressDTLList?.filter(
          (row) => row.default
        );
        debugger
        if (defaultAddress && defaultAddress.length > 0) {
          let address = defaultAddress[0];
          debugger
          // if lat long has then only set address
          if (address && (address.latitude || address.longitude)) {
            // setting let long
            setUserLocation({
              lat: address?.latitude,
              lng: address?.longitude,
            });
            // setting unit number
            setUnitNumber(address?.unitNumber);
            // setting address
            setSelectedShowAddress(address ?? {});
            formatAddress(address.address1);
            setLatLong({ lat: address.latitude, lng: address.longitude });

            if (address.address1 && address.unitNumber) {

              setSelectedAddress(formatAddress(address.address1));
          } else if (address.address1) {
              debugger
              setSelectedAddress(address.address1);
          } else {

              setSelectedAddress("");
          }
            // setSelectedAddress(
            //   address.address1 ?   formatAddress(address.address1) : ""
            // );
          }
        }
      }
    }
    function formatAddress(address) {
      let parts = address.split(",");
      let formattedAddress = parts.slice(1, parts.length - 1).join(", ");

      return formattedAddress;
    }
  }, [props.open]);

  useEffect(() => {
    if (polygonsContainingUser.length) {
      const minNormalChargePolygon = polygonsContainingUser?.reduce(
        (min, current) => {
          return current.normalCharges < min.normalCharges ? current : min;
        }
      );
      dispatch(
        deliveryChargesDTLActions.getMinimumOrderValue(
          minNormalChargePolygon.minimumOrderValue
        )
      );
    }
  }, [polygonsContainingUser, dispatch]);

  const handleContinueWithOrder = (e) => {



    // console.log('Confrim selected outlet >>>',confirmSelectedOutlet)

    // localStorage.setItem('selected-outlet', JSON.stringify(confirmSelectedOutlet));
    // localStorage.getItem("selected-outlet")
    if(history.location.pathname === "/outlet-selection") {
      debugger
      history.push(ROUTES.home._path);
    } else {
      // history.push(ROUTES.home._path);
    }


    if (true) {
      // if (validateAddress()) {

      // NEW CHANGES
      const minNormalChargePolygon = polygonsContainingUser.reduce(
        (min, current) => {
          return current.normalCharges < min.normalCharges ? current : min;
        }
      );


      if(!prevOutlet) {
        // don't add order in cart when outlet change
        props.confirm();
      }


      let orderDateTime = {
        orderDate:
          selectedDate === null ? convertToTimeZone(new Date()) : removeDayFromDate(selectedDate),
        orderTime: selectedTime === null ? "Now" : selectedTime,
        timedOrder:
          selectedTime === null || selectedTime === "Now" ? false : true,
      };
      if (
        !checkSlotAvailable(
          new Date(orderDateTime.orderDate),
          orderDateTime.orderTime
        )
      ) {
        setOpen(false);
        setStoreOpen(false);
        return;
      }

      dispatch(
        cartActions.setDeliveryCharge(minNormalChargePolygon.normalCharges)
      );
      dispatch(
        deliveryChargesDTLActions.getMinimumOrderValue(
          minNormalChargePolygon.minimumOrderValue
        )
      );

      let formattedAddress = "";

      debugger;

      function mapAddressComponents(components, unitNumber) {
        const address = {};
        if (!components) {
          formattedAddress = selectedShiwAddress.address1;
          return {};
        }
        if (unitNumber) {
          formattedAddress = "U " + unitNumber + ", " + formattedAddress;
        }
        components?.forEach((component) => {
          if (component.types.includes("route")) {
            address.route = component.long_name;
            formattedAddress += component.long_name + ", ";
          } else if (component.types.includes("subpremise")) {
            address.subpremise = component.long_name;
            formattedAddress += component.long_name + "/";
          } else if (component.types.includes("street_number")) {
            address.streetNumber = component.street_number;
            formattedAddress += component.long_name + ", ";
          } else if (component.types.includes("locality")) {
            address.locality = component.long_name;
            formattedAddress += component.long_name + ", ";
          } else if (component.types.includes("administrative_area_level_2")) {
            address.admin_area_level_2 = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            address.admin_area_level_1 = component.long_name;
          } else if (component.types.includes("country")) {
            address.country = component.long_name;
            formattedAddress += component.long_name;
          } else if (component.types.includes("postal_code")) {
            address.postal_code = component.long_name;
            formattedAddress =
              formattedAddress + ", " + component.long_name;
          } else if (component.types.includes("street_number")) {
            address.street_number = component.long_name;
          }
        });

        return address;
      }

      const address = mapAddressComponents(userAddress, unitNumber);
      const addressWithUnit = unitNumber ? `U ${unitNumber}, ${selectedAddress}` : selectedAddress;


      const setAddressTwo = 
      (address?.route || address?.locality) 
      ? `${address?.route || ""}, ${address?.locality || ""}` 
      : selectedShiwAddress?.address2;

      const data = {
        address1:  formattedAddress ?  formattedAddress :   addressWithUnit,
        address2: setAddressTwo,
        // address2: address?.route + ", " + address.locality ?? selectedShiwAddress?.address2,
        suburb: address?.locality ?? selectedShiwAddress?.suburb,
        unitNumber: unitNumber,
        pincode: address?.postal_code ?? selectedShiwAddress?.pincode,
        deliveryInstruction: "",
        latitude: latlong?.lat,
        longitude: latlong?.lng,
        country: address?.country ?? selectedShiwAddress?.country,
      };

      if (data.address1 && remember && latlong) {
        setSelectedStreetSuburb(data.address1);
        localStorage.setItem(
          "address",
          JSON.stringify({
            ...data,
            isAddress: true,
            address1: selectedAddress,
            streetNumber: streetNumber,
            selectedStreetSuburb: selectedStreetSuburb,
            userAddress: userAddress,
          })
        );
      }
      // if (data.address1 && remember && latlong) {
      //   setSelectedStreetSuburb(data.address1);
      //   localStorage.setItem(
      //     "address",
      //     JSON.stringify({
      //       isAddress: true,
      //       formattedAddress: formattedAddress ?  formattedAddress : addressWithUnit,
      //       unitNumber: unitNumber,
      //       streetNumber: streetNumber,
      //       selectedStreetSuburb: selectedStreetSuburb,
      //       pincode:data.postalCode,
      //       address1: selectedAddress,
      //       latitude: latlong?.lat,
      //       longitude: latlong?.lng,
      //       userAddress: userAddress,
      //       country: address?.country,
      //     })
      //   );
      // }

      setObjAddress(data);

      // let addressLine1 = address.route, address.locality ,
      dispatch(orderMSTActions.setCustomerAddress(data));
      dispatch(orderMSTActions.setOrderType("OT02"));
      dispatch(orderMSTActions.setOrderDateTime(orderDateTime));
      props.closeDialog();
      if (props.redirect) {



        if (props?.data?.fromOrderHistory || props?.data?.length >= 1) {

          history.push(ROUTES.menu._path + "/check-out/check-out");
        } else {
          if(history.location.pathname === ROUTES.orderType._path)
           history.push(ROUTES.menu._path);
          //  dispatch(cartActions.destroyOrderAndClear());
        }
      }
      // history.push(ROUTES.home._path);
    }
  };


  const handleDeliveryChange = (e, delivery) => {
    if (open === false && delivery === "Now") {
      setStoreOpen(false);
    }

    if (delivery === "later") {
      setStoreOpen(true);
    }
    setDelivery(delivery);
    if (selectedTime === null) {
      setSelectedTime(orderTimes.length > 0 ? orderTimes[0] : null);
    }
  };
  const handleSelectDate = (e) => {
    let onlyDate = removeDayFromDate(e.target.value);
    let data = dataOutletShift
      ? parseShiftDTL(dataOutletShift, new Date(onlyDate), "Delivery")
      : [];
    if (data.length === 0) {
      setStoreOpen(false);
      setOpen(false);
    } else {
      setStoreOpen(true);
      setOpen(true);
    }
    if (new Date(onlyDate).getDate() === convertToTimeZone(new Date()).getDate()) {
      data = ["Now", ...data];
    }
    setOrderTimes(data);
    setSelectedDate(e.target.value);
    setSelectedTime(data.length > 0 ? data[0] : null);
  };
  const handleSelectTime = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleChange = (e) => {
    if (e.target.name === "unitnumber") {
      setUnitNumber(e.target.value);
    } else if (e.target.name === "streetnumber") {
      setStreetNumber(e.target.value);
    }
  };
  const handleRemember = (event) => {
    setRemember(event.target.checked);
  };

  return (
    <>
     <OrderTypeCustomDialog
      isOpen={props.open}
      handleClose={handleClose}
      closeButton
      dialogClassName="dialog"
      dialogTitle="Enter Delivery Details"
      dialogBody={
        <> { props.open ?
    <div
      className={`${history.location.pathname === "/"  || history.location.pathname ===  "/menu/check-out/check-out"  ? '' :  'mt-2'}  delivery-dialog orderTypeCard ${history.location.pathname === "/outlet-selection" ? 'orderTypeMobile' : '' }`}>
      {/* className={`${history.location.pathname === "/" ? '' :  'mt-2'} delivery-dialog orderTypeCard ${history.location.pathname === "/outlet-selection" ? 'orderTypeMobile' : '' }`}> */}
      <div
        className="text-right"
        style={{
          position: "absolute",
          right: "-5px",
          top: "-10px",
          fontSize: "15pt",
          cursor: "pointer",
          padding: "10px",
          color: "#444",
        }}
      >
      </div>
        <div
          className="pickupHedding mb-3"
          style={{
            fontFamily: "ArialMT",
            color: "#000D2A",
            fontSize: "20px",
            // fontSize: "25px",
            // fontWeight: "bold",
          }}
        >
          Enter Delivery Details
        </div>


        {
          showFullAddress ?  <ul className="nav nav-tabs schedule mt-3">
          <li className=" schedule-now">
            <div
              className={"nav-link " + (delivery === "Now" ? "activetab" : "")}
              onClick={(e) => handleDeliveryChange(e, "Now")}
            >
              <div className="desktop"> Schedule Now</div>
              <div className="mobile"> Now</div>
            </div>
          </li>
          <li className="schedule-later">
            <div
              className={
                "nav-link " + (delivery === "later" ? "activetab" : "")
              }
              onClick={(e) => handleDeliveryChange(e, "later")}
            >
              <div className="desktop">Schedule for Later</div>
              <div className="mobile">
                <span>
                  <img src="https://img.icons8.com/office/16/000000/clock.png" />
                </span>
                Later
              </div>
            </div>
          </li>
        </ul>
        : null
        }


{
          showFullAddress && polygonAddress.length > 1 ?
                 <div className="col-12 " style={{ marginRight: "80px", marginTop: '1rem', zIndex: '999' }}>


          <Select
                    isDisabled={history.location.pathname === '/menu/check-out/check-out' ? true : false}
                    // isDisabled={disable}
                    className="inlineFormCustomSelect"
                    id="inlineFormCustomSelect"
                    options={polygonAddress?.map((item) => (
                      {
                        value: item.outletCode, label: item.address1
                      }
                    ))
                  }
                    placeholder="Select Outlet"
                    // isClearable={isclearable}
                    // components={{ MultiValueRemove }}
                    onChange={(e) => handleOutletSelect(e)}
                    value={
                      outletMainData?.data?.map((item) => (
                        {
                          value: item.outletCode, label: item.address1
                        }
                      )).filter((option) => {
                         return outletMain === option.value
                      }
                    )}
                  />

          {/* <div className="form-label">Select Outlet</div>
          <SimpleSelect
            data={
              outletMainData?.data?.map((item) =>  item.outletCode).filter((code, index, array) => array.indexOf(code) === index)
          }
            change={(e) => handleOutletSelect(e)}
            name="outlet"
            value={outletMain}
            key="1"
          ></SimpleSelect> */}
        </div>

          : null
        }

      {loadingOutletShift ? (
        <Spinner></Spinner>
      ) : storeOpen ?  null : (
      <div className="err-msg mt-2">
      Store is currently closed. Please select next available time
    </div>
       )
      }
{
          showFullAddress ?
      <div
        className="grid-container"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        {delivery === "later" ? (
          <>
            <div>
              <div className="form-label">Date</div>
              <SimpleSelect
                data={orderDates}
                change={(e) => handleSelectDate(e)}
                name="date"
                value={selectedDate}
                key="1"
              ></SimpleSelect>
            </div>

            <div>
              <div className="form-label">Time</div>
              <SimpleSelect
                data={orderTimes}
                name="time"
                value={selectedTime}
                change={(e) => handleSelectTime(e)}
              ></SimpleSelect>
            </div>
          </>
        ) : null}
      </div>
      : null }

      {isLoading ? (
        <div className="loader">Loading...</div>
      ) : (
        <div className="search--area">
          <button
            className="searchIcon"
            title="Search"
            dangerouslySetInnerHTML={{
              __html: userLocation?.lat ? locationIcon : svgCode,
            }}
          />
          <Geosuggest
            ref={geosuggestEl}
            disabled={history.location.pathname === '/menu/check-out/check-out' ? true : false
          }
           placeholder="Enter delivery address"
            initialValue={selectedAddress}
            className="geosuggest-ui"
            onSuggestSelect={(suggest) => {
              setAddressChangeTrig(suggest?.description)
              onSuggestSelect(suggest)
            }
          }
            country={["au"]}
          />



          <svg
            onClick={() => {
              if (history.location.pathname !== '/menu/check-out/check-out') {
                clearInput();
              }

            }

      //         history.location.pathname ===  '/menu/check-out/check-out' ? null : clearInput()
      // )
          }
            style={{ marginLeft: "10px" }}
            className="removeIcon"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 50 50"
          >
            <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
          </svg>
        </div>
      )}

{
          showFullAddress ?

      <div className="row deliverDetail mt-2">
        <div className="col-12 ">
          <TextInput
            label="Unit Number"
            type="text"
            className="street"
            placeholder="Apartment / Suite / Floor"
            name="unitnumber"
            value={unitNumber}
            change={(e) => handleChange(e)}
          />


        </div>
      </div>

      : null }

            {loading ? (
            <div>Checking delivery area...</div>
          ) : (
            <div className="err-msg mt-2">{deliveryMessage}</div>
          )}

{
          showFullAddress ?
      <Checkbox
        check={remember}
        change={(e) => handleRemember(e)}
        value="remember"
        color="default"
        inputLabel="Remember delivery details"
      />

      : null }

      <div
        className="pickupBtn"
        style={{ marginTop: "30px" }}
        // style={{ marginBottom: "40px", marginTop: "30px" }}
      >
        {
          showFullAddress ?
          <>
        <BlockButton
          text="Continue with Order"
          click={(e) => handleContinueWithOrder(e)}
          // check={!dilverable}
          check={!storeOpen || !dilverable  || !isMultipleOutlet || loadingOutletShift || isAuthenticating }
        ></BlockButton>
</>
        :

        <BlockButton
          text="Next"
          click={(e) => {
            e.preventDefault()
            setFullAddress(true)
          }
          }
          check={!dilverable}
      ></BlockButton>

        }

      </div>
    </div>
    : null
    }
    </>
      } />
    </>
  );
};
// export default DeliveryDialog;

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  libraries: ["geometry", "places"],
})(DeliveryDialog);
