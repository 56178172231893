import React, { useCallback, useEffect, useRef, useState } from "react";
import AuthenticatedPage from "../routes/AuthenticatedPage";
import PrivateRoutes from "../routes/PrivateRoutes";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useHistory } from "react-router";
import "./layout.scss";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cartActions, cartSlice } from "../../_redux/files/cartRedux";
import ROUTES from "../../constants/ROUTES";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { customerMSTActions } from "../../_redux/files/customerRedux";
import moment from "moment";
import SplashScreen from "../splashScreen/SplashScreen";
import { hnhSlice } from "../../_redux/files/hnhRedux";
import { homeActions } from "../../_redux/files/homeRedux";
import { orderMSTActions } from "../../_redux/files/orderMSTRedux";
import { TOKEN_NAME } from "../../_apis/setupAxios";
import { Route, Switch } from "react-router-dom";
import SelectOutlet from "../../pages/outletSelect/SelectOutlet";
import axios from "axios";
import logCartData from "../../helpers/LogCartData";

function loadScript(src, onLoad) {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.onload = onLoad;
  document.body.appendChild(script);
}

const Layout = () => {
  // const [idleTime, setIdleTime] = useState(null)
  // getting popular pizza

  useEffect(() => {
    const gaTrackingId = process.env.REACT_APP_GTAG;
    if (gaTrackingId) {
      loadScript(
        `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`,
        () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", gaTrackingId);
        }
      );
    } else {
      console.error("Google Analytics tracking ID not found.");
    }

    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const currency = process.env.REACT_APP_PAYPAL_CURRENCY;
    const components = process.env.REACT_APP_PAYPAL_COMPONENTS;

    if (true) {
      const scriptUrl = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&components=${components}`;
      // const scriptUrl = `https://www.sandbox.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&components=${components}`;
      

      loadScript(scriptUrl, () => {
        console.log("PayPal SDK script loaded successfully");
      });
    } else {
      console.error("PayPal client ID not found.");
    }

    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = require(process.env.REACT_APP_LOGO_IMAGE_PATH).default;
    } else {
      const link = document.createElement("link");
      link.id = "favicon";
      link.rel = "icon";
      link.href = require(process.env.REACT_APP_LOGO_IMAGE_PATH).default;
      document.head.appendChild(link);
    }

    document.title = process.env.REACT_APP_WEB;
  }, []);

  let { orderMSTObj, popularPizzaData, fromOrderStage } = useSelector(
    (state) => {
      return {
        orderMSTObj: state.cart.orderMSTObj,
        popularPizzaData: state?.home?.popularPizza,
        fromOrderStage: state.cart.fromOrderStage,
      };
    }
  );

  let { cartStore } = useSelector((state) => {
    return {
      cartStore: state.cart,
    };
  });

  useEffect(() => {
    if (cartStore?.error) {


      logCartData(cartStore, 'Something went wrong. Please order again')
      
      
      Swal.fire({
        icon: "warning",
        text: "Something went wrong. Please order again",
        // showCancelButton: true,
        // confirmButtonText: 'Yes, keep it!',
        // cancelButtonText: 'No, Discard it'
      });
      history.push(ROUTES.home._path);
      dispatch(cartActions.destroyOrderAndClear());
    }
  }, [cartStore.error]);

  const [navclass, setNavclass] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const [cartCount, setCartCount] = useState(0);
  const { items } = useSelector((s) => s.cart);
  const history = useHistory();
  useEffect(() => {
    if (
      location.pathname === "/menu" ||
      location.pathname === "/menu/G1/default" ||
      location.pathname === "/menu/G2/default" ||
      location.pathname === "/menu/G3/default" ||
      location.pathname === "/menu/G4/default" ||
      location.pathname === "/menu/G5/default" ||
      location.pathname === "/menu/G6/default" ||
      location.pathname === "/menu/G7/default" ||
      location.pathname === "/menu/G8/default" ||
      location.pathname === "/menu/G1/customize" ||
      location.pathname === "/menu/check-out/check-out" ||
      location.pathname === "/menu/G1/half-and-half" ||
      location.pathname === "/menu/G1/build-your-own-pizza" ||
      location.pathname === "/menu/payment/payment" ||
      location.pathname === "/menu/check-out/customize" ||
      location.pathname === "/menu/G1/check-out" ||
      location.pathname === "/menu/check-out/half-and-half"
    ) {
      setNavclass(true);
    } else {
      setNavclass(false);
    }
  });

  useEffect(() => {
    if (localStorage.getItem(TOKEN_NAME)) {
      if (fromOrderStage) {
        if (orderMSTObj)
          dispatch(orderMSTActions.getCurrentStage(orderMSTObj.id));
      }
    }
  }, []);

  useEffect(() => {
    if (!Array.isArray(items)) return;
    let sum = 0;
    items.forEach((each) => {
      sum += each.qty;
    });
    return setCartCount(sum);
  });

  const { navigateToHome, loadingUpdateStage, currentStage } = useSelector(
    (state) => {
      return {
        navigateToHome: state.cart.navigateToHome,
        loadingUpdateStage: state.cart.loadingUpdateStage,
        currentStage: state.orderMST.runningOrder,
      };
    }
  );

  useEffect(() => {
    if (navigateToHome) {
      history.push(ROUTES.home._path);
    }
  }, [navigateToHome]);

  useEffect(() => {
    if (fromOrderStage)
      if (currentStage)
        if (
          currentStage?.data?.Stage === 1 ||
          currentStage.data?.Stage === 12 ||
          currentStage.data?.Stage === 11
        ) {
        } else {
          dispatch(cartSlice.actions.setFromOrderStage(true));
          history.push(ROUTES.orderPlaced._path);
        }
  }, [currentStage]);

  useEffect(() => {
    dispatch(cartActions.reIniCartState());
  }, []);

  const loadEvent = async () => {
    //  divyesh >> remove because removal of cart tracking
    if (process.env.REACT_APP_CART_TRACKING === "true") {
      await dispatch(cartActions.destroyOrderOnLoad());
    }
  };
  const unloadEvent = async () => {
    //  divyesh >> remove because removal of cart tracking
    if (process.env.REACT_APP_CART_TRACKING === "true") {
      await dispatch(cartActions.destroyOrderOnUnLoadFetch());
    }
  };
  const loadUnload = async () => {
    window.addEventListener("load", (event) => {
      if (
        localStorage.getItem("tabCount") === null ||
        isNaN(localStorage.getItem("tabCount"))
      ) {
        localStorage.setItem("tabCount", 1);
      } else {
        localStorage.setItem(
          "tabCount",
          parseInt(localStorage.getItem("tabCount")) + 1
        );
      }
      if (parseInt(localStorage.getItem("tabCount")) === 1) {
        loadEvent();
      }
    });
    window.addEventListener("beforeunload", (event) => {
      // console.info("<== beforeunload ==>");

      if (
        localStorage.getItem("tabCount") === null ||
        isNaN(localStorage.getItem("tabCount"))
      ) {
        unloadEvent();
      } else {
        if (parseInt(localStorage.getItem("tabCount")) - 1 === 0) {
          unloadEvent();
        }
      }

      if (
        localStorage.getItem("tabCount") === null ||
        isNaN(localStorage.getItem("tabCount"))
      ) {
        //Api call
        localStorage.setItem("tabCount", 0);
      } else {
        if (parseInt(localStorage.getItem("tabCount")) > 0) {
          localStorage.setItem(
            "tabCount",
            parseInt(localStorage.getItem("tabCount")) - 1
          );
        }
      }

      var e = new Date().getTime() + 5 * 1000;
      while (new Date().getTime() <= e) {}
    });
    // window.captureEvents(Event.UNLOAD);
    // window.addEventListener('onunload', (event) => {
    //   console.info('<== onunload ==>');
    //   localStorage.clear()
    // });
    //window.addEventListener("beforeunload", unload);
    //return () => window.removeEventListener("beforeunload", unload);
  };
  useEffect(() => {
    loadUnload();
  }, []);

  const custData = useSelector((state) => state.auth.authCust);
  useEffect(() => {
    if (custData && custData.customerMSTId) {
      // dispatch(customerMSTActions.getCustomerFav(custData.customerMSTId));
      // dispatch(customerMSTActions.getCustomerHistory(custData.customerMSTId));
      // dispatch(cartActions.loadSurcharges());
    }
  }, [custData]);

  useEffect(() => {
    if (!location.pathname.includes("/half-and-half")) {
      dispatch(hnhSlice.actions.setLocalId(null));
      dispatch(hnhSlice.actions.setFreshOrder(true));
    }
    if (!location.pathname.includes("build-your-own-pizza")) {
      dispatch(cartSlice.actions.setLocalIdForBuildYourOwn(null));
    }
  }, [location]);

  const [events, setEvents] = useState([
    "mousedown",
    "mousemove",
    "keypress",
    "scroll",
    "touchstart",
  ]);
  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    if (cartCount <= 0) {
      sessionStorage.removeItem("lastTimeStamp");
    } else {
      timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    }
    timeChecker();
  }, [cartCount]);

  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);
    if (cartCount <= 0) {
      clearTimeout(startTimerInterval.current);
    } else {
      dispatch(cartSlice.actions.setNavigateToHome(true));
      dispatch(cartSlice.actions.setFromSessionExpire(true));
    }
  };
  let idleTime = 15; // in minutes
  // let idleTime = 1; // in minutes

  let timeChecker = () => {
    if (cartCount > 0) {
      startTimerInterval.current = setTimeout(() => {
        let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
        warningInactive(storedTimeStamp);
      }, idleTime * 1000 * 60);
    } else {
      clearTimeout(startTimerInterval.current);
    }
  };

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker, cartCount]);

  return (
    <div className="layout">
      {loadingUpdateStage ? (
        <>
          <SplashScreen text="Please wait..." />
        </>
      ) : (
        <>
          <Route exact path="/outlet-selection" component={SelectOutlet} />

          {location.pathname !== "/outlet-selection" ? (
            <AuthenticatedPage>
              {/* <Switch>
           <Route exact to="/full-page">
            <FullPageRoutes />
          </Route> 
           <Route  to="/">  */}

              {/* <header className={navclass ? "layout-heade-menu" : "layout-header"}> */}
              {location.pathname !== "/outlet-selection" ? (
                <header className="layout-heade-menu">
                  <Header />
                </header>
              ) : null}

              <div className="layout-content">
                <PrivateRoutes />
              </div>
              {location.pathname !== "/full-page/order-type" &&
              location.pathname !== "/outlet-selection" ? (
                <footer className="layout-footer">
                  <Footer />
                </footer>
              ) : null}

              {/* </Route>
        </Switch> */}
            </AuthenticatedPage>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Layout;
