import { initializeApp } from "firebase/app";


const firebaseConfig = {
    apiKey: "AIzaSyCGGpLR99k6GP2UkvooFNnYxeNSx-EwpZg",
    authDomain: "aroma-pos.firebaseapp.com",
    projectId: "aroma-pos",
    storageBucket: "aroma-pos.appspot.com",
    messagingSenderId: "298777322101",
    appId: "1:298777322101:web:5557983e158061d8994e3c"
  };


export const app = initializeApp(firebaseConfig);