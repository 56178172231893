import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomDialog from "../../componets/dialog/CustomDialog";
import TextInput from "../../componets/FormComponent/TextInput";
import BlockButton from "../../componets/FormComponent/BlockButton";
import Checkbox from "../../componets/FormComponent/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import "./userAccount.scss";
import { isBlank, isValidEmail } from "../../helpers/Utils";
import { authActions } from "../../_redux/files/authRedux";
import Spinner from "../../componets/etc/Spinner";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ROUTES from "../../constants/ROUTES";
import { outletMSTActions } from "../../_redux/files/outletMSTRedux";
import { orderMSTActions } from "../../_redux/files/orderMSTRedux";
import { cartActions } from "../../_redux/files/cartRedux";

const SignIn = (props) => {
  const [remember, setRemember] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(
    (state) => state.auth.loading.loginByUserPassword
  );
  const currentSelectedOutlet = useSelector((state) => state.outletMST.currentOutlet?.data);

  const [error, setError] = useState("");
  const data = useSelector((state) => state.auth.data.loginByUserPassword);
  const validate = (values) => {
    const errors = {};
    if (isBlank(values.userName)) {
      errors.userName = "Required";
    }
    if (!isValidEmail(values.userName)) {
      errors.userName = "Please enter valid Email Address.ex abc@test.com";
    }
    if (isBlank(values.password)) {
      errors.password = "Required";
    }
    if (values.password.length < 8 || values.password.length > 16) {
      errors.password = "Password must be  8 to 16 characters long.";
    }
    return errors;
  };
  let socialSign = "Sign In";
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: "",
      password: "",
      system: "PIZZAPORTAL",
    },
    validate,
    onSubmit: (values) => {
      setError("");
      dispatch(authActions.loginByUserNamePassword(values))
        .then((res) => {      
          debugger
          props.closeDialog("sign-in");
          let logiNOutletMST = res.outletMST;
          // dispatch(outletMSTActions.setCurrentOutlet(logiNOutletMST));
            let valMain =   JSON.parse(localStorage.getItem("selected-outlet"))            
            // if outlet already selected  then we will count that else we will count loginOutlet 
            let confrimOutletMST =  currentSelectedOutlet ? currentSelectedOutlet : [logiNOutletMST];

            debugger
          //  localStorage.setItem("selected-outlet", JSON.stringify([outletCode]));
            formik.resetForm();
            setError("");
            
            // when we dont have sleected outlet then only we will set login api outlet to localstorage
            if(!valMain){
              // filter out data from login api 
              let OutletObj = [{
                address1 :  logiNOutletMST.address1,
              outletCode: logiNOutletMST.outletCode,
              outletMSTId: logiNOutletMST.outletMSTId,
              outletName: logiNOutletMST.outletName
              }] 
              localStorage.setItem('selected-outlet', JSON.stringify(OutletObj))
            } 
            // dispatch(cartActions.destroyOrderAndClear());
          if(confrimOutletMST){
          //  dispatch(outletMSTActions.setCurrentOutlet(confrimOutletMST));
           debugger
            dispatch(authActions.authenticateUser(confrimOutletMST[0], true)).then((res) => {

              dispatch(cartActions.syncOrderAndCartTracking());
              debugger
            if(history.location.pathname === '/outlet-selection')  {
              // after this redirect user to home page if user is in outlet-selection page
              history.push(ROUTES.home._path);
            }

          });
        } else {
          // if outlet is not selected then redirect user to outlet-selectin
          history.push('/outlet-selection');
        }
        })
        .catch((err) => {
          setError("Please enter valid credentials.");
        });
    },
  });
  const handleRemember = (event) => {
    setRemember(event.target.checked);
  };
  const openForgotPass = (e) => {
    props.closeDialog("sign-in");
    props.openDialog("forgot");
    formik.resetForm();
  };
  const openSignUp = (e) => {
    props.closeDialog("sign-in");
    props.openDialog("sign-up");
    formik.resetForm();
  };
  const handleClose = (e) => {
    props.closeDialog("sign-in");
    formik.resetForm();
  };
  const responseFacebook = (response) => {
    let data = {
      userName: response?.email,
      password: response?.id,
      system: "PIZZAPORTAL",
    };
    dispatch(authActions.loginByUserNamePassword(data))
      .then((res) => {
        props.closeDialog("sign-in");
        formik.resetForm();
        setError("");
        dispatch(authActions.authenticateUser());
      })
      .catch((err) => {
        setError("User not found!");
      });
  };
  const responseGoogle = (response) => {
    let data = {
      userName: response?.profileObj?.email,
      password: response?.profileObj?.googleId,
      system: "PIZZAPORTAL",
    };
    dispatch(authActions.loginByUserNamePassword(data))
      .then((res) => {
        props.closeDialog("sign-in");
        formik.resetForm();
        setError("");
        dispatch(authActions.authenticateUser());
      })
      .catch((err) => {
        setError("User not found!");
      });
  };

  return (
    <>
      <CustomDialog
        isOpen={props.open}
        handleClose={handleClose}
        dialogClassName="dialog custom-dialog"
        dialogTitle="Sign In"
        dialogHeader={false}
        dialogBody={
          <div>
            <div
              onClick={handleClose}
              className="text-right"
              style={{
                position: "absolute",
                right: "-5px",
                top: "-10px",
                fontSize: "15pt",
                cursor: "pointer",
                padding: "10px",
                color: "#444",
              }}
            >
              <i class="fas fa-times-circle"></i>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="container signin-dialog">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center signin-title"> Sign In</div>
                    <div className="text-center signin-sub-title">
                      To retain fanstastic deals
                    </div>
                  </div>
                </div>
                {/* <div className="row my-3">
                  <div className="col-12 mb-2">
                    <div className="small text-center">
                      {socialSign} with Social Network
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12 facebookContainer">
                  <FacebookLogin
                    appId="311328580730028"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    textButton="Sign In with Facebook"
                    icon="fa-facebook"
                 />
                  </div>
                  <div className="col-md-6 col-xs-12 googlecontainer">
                  <GoogleLogin
                  clientId="939662684222-i95ljsh25nkln89ksf4nqb2c4ej04tj6.apps.googleusercontent.com"
                   buttonText=""
                   cookiePolicy={'single_host_origin'}
                   onSuccess={responseGoogle}
                    onFailure={responseGoogle}>
                    <div className="social-box gog-section">
                      <div className="social-icon">
                        <i className="fab fa-google"></i>
                      </div>
                      <div
                        className="social-text hideText"
                        style={{ fontSize: "15px" }}
                      >
                        <div className="social-text">Sign In with Google</div>
                          </div>
                    </div>
                    </GoogleLogin>
                  </div>
                </div> */}
                <div className="row my-3">
                  <div className="col-12 mb-1">
                    <div className="small">
                      {props.headerMsg
                        ? props.headerMsg
                        : "Sign In with your email Account"}
                    </div>
                  </div>
                  <div className="col-12">
                    <TextInput
                      label="Enter Email ID"
                      type="text"
                      placeholder="Enter Email ID"
                      name="userName"
                      change={formik.handleChange}
                      value={formik.values.userName}
                      className={formik.errors.userName ? "input-error" : ""}
                    ></TextInput>
                    {formik.touched.userName && formik.errors.userName ? (
                      <div className="fv-plugins-message-container err-msg">
                        <div className="fv-help-block">
                          {formik.errors.userName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <TextInput
                      label="Password"
                      type="password"
                      placeholder="Enter Password"
                      name="password"
                      change={formik.handleChange}
                      value={formik.values.password}
                      className={formik.errors.password && "input-error"}
                    ></TextInput>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="fv-plugins-message-container err-msg">
                        <div className="fv-help-block">
                          {formik.errors.password}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6">
                    <Checkbox
                      check={remember}
                      change={(e) => handleRemember(e)}
                      value="remember"
                      color="default"
                      inputLabel="Remember Me"
                    />
                  </div>
                  <div className="col-6">
                    <div
                      onClick={(event) => openForgotPass(event)}
                      className="forgot-pass-section"
                    >
                      Forgot Password
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-12">
                    <BlockButton
                      type="submit"
                      onSubmit={formik.handleSubmit}
                      text={loading ? <Spinner></Spinner> : "Sign In"}
                    ></BlockButton>
                    <div className="err-msg text-center">{error}</div>
                  </div>
                  <div className="col-12">
                    <div
                      style={{
                        height: "40px",
                        paddingTop: "10px",
                        fontSize: "14px",
                        color: "#130C0E",
                      }}
                    >
                      Don't have an account? &nbsp;
                      <span
                        style={{
                          color:  "var(--test-primary)",
                          // color: "#fcaf17",
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                        onClick={(event) => openSignUp(event)}
                      >
                        Sign Up
                      </span>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </form>
          </div>
        }
      />
    </>
  );
};
export default SignIn;
