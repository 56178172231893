
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const checkOutPaymentCruds = {


   /**
     * @param {{param:{domain:undefined},body:null}} data
     */
    createSession : (data) => {
        return axios.post(`/checkOutPayment/create-checkout-session/${data.param.domain}`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{orderAmount:undefined, orderId:undefined, paymentMethod:undefined}}} data
     */
    createPayment : (data) => {
        return axios.post(`/checkOutPayment/createPayment`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default checkOutPaymentCruds;
    