import React from "react";
import "./formComponent.scss";
const TextInput = (props) => {
  return (
    <input
      maxLength={props.length}
      label={props.label}
      type={props.type}
      autoComplete={props.auto}
      placeholder={props.placeholder}
      className={"input " + (props.className ? props.className : "")}
      name={props.name}
      value={props.value}
      disabled={props.disabled}
      onChange={props.change}
    />
  );
};
export default TextInput;
