import React from 'react';
import './formComponent.scss'

const IncDecCounter = ({
    value = 0,
    onChange,
    negative = false,
    step = 1,
    max = undefined,
    min = undefined,
    plusIcon,
    minusIcon
}) => {
    const isMinusDisabled = () => (!isNaN(min) ? min >= value : false)
    const isPlusDisabled = () => (!isNaN(max) ? max <= value : false)
    return (
        <div className="inc-dec-counter">
            <button onClick={() => onChange && onChange(value - step,true)} disabled={isMinusDisabled()} className={`box box-minus ${isMinusDisabled() ? 'text-muted disabled' : 'text-danger'}`}>
                {minusIcon ? minusIcon : (<i className="fas fa-minus"></i>)}
            </button>
            <span className="box box-value d-flex justify-content-center">
                <div>{value}</div>     
            </span>
            <button onClick={() => onChange && onChange(value + step,false)} disabled={isPlusDisabled()} className={`box box-plus ${isPlusDisabled() ? 'text-muted disabled' : 'text-success'}`}>
                {plusIcon ? plusIcon : (<i className="fas fa-plus"></i>)}
            </button>
        </div>
    );
};

export default IncDecCounter;