import React from 'react';
import './spashScreen.scss'
// import logo from "../../assets/images/aroma_pizza_logo.jpeg";

const SplashScreen = ({ text = "Loading...", loading = true }) => {
    const logo = (require(process.env.REACT_APP_LOGO_IMAGE_PATH).default);

    return (
        <div className="splash">
            {!loading && <img src={logo} alt="Pippo's"/>}
            {loading && (
                <div className="spinner spinner-border size-large" style={{color: 'var(--test-primary)'}} role="status" >
                {/* <div className="spinner spinner-border text-warning size-large" role="status" > */}
                    <span className="sr-only">{text}</span>
                </div>
            )}
            <div className="text ml-2">{text}</div>
        </div>
    );
};

export default SplashScreen;