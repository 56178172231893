import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { errorMessageFormatter } from "../../helpers/Utils";

class Cruds {
    constructor(api_master) {
        this.API_MASTER = api_master
    }
    fetchAddress(address) {
        return Axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address.replace(" ", "%20")}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`)
    }
}
class Slice {
    constructor(reducerName) {
        this.initialState = {
            addresses: [],
            outletAddress: undefined
        }
        const reducers = {
            addressFetched: (state, action) => {
                state.addresses.push(action.payload.address)
            },
            outletAddressFetched: (state, action) => {
                state.outletAddress = action.payload.address
            },
        }
        this.slice = createSlice({
            name: reducerName,
            initialState: this.initialState,
            reducers: reducers
        })
        this.reducer = this.slice.reducer
    }
}
class Actions {
    constructor(crudObj, sliceObj) {
        this.requestFromServer = crudObj
        this.actions = sliceObj.slice.actions
        this.callTypes = sliceObj.callTypes
        this.reducerName = sliceObj.slice.name
    }
    fetchOrderAddress = (order, geocoder) => dispatch => {
        // return this.requestFromServer.fetchAddress(order.customerAddressDTL).then(({ data: result }) => {
        //     // if (result.status === "OK") {

        //     // } else {
        //     //     console.log(`Map error for order:${order.orderNo} => ${status}`)
        //     // }
        //     console.log("lloglelel ====>>> ", data)
        // }).catch(err => console.log("Google Map API arror => ", err))
        geocoder.geocode({ address: order.customerAddressDTL }, (result, status) => {
            if (status === "OK") {
                const abc = {
                    id: order.id,
                    label: order.orderNo,
                    location: result[0].geometry.location
                }
                dispatch(this.actions.addressFetched({ address: abc }))
            } else {
                console.log(`Map error for order:${order.orderNo} => ${status}`)
            }
        })
    }
    fetchOutletAddress = (address, geocoder) => dispatch => {
        // return this.requestFromServer.fetchAddress(order.customerAddressDTL).then(({ data: result }) => {
        //     // if (result.status === "OK") {

        //     // } else {
        //     //     console.log(`Map error for order:${order.orderNo} => ${status}`)
        //     // }
        //     console.log("lloglelel ====>>> ", data)
        // }).catch(err => console.log("Google Map API arror => ", err))
        geocoder.geocode({ address: address }, (result, status) => {
            if (status === "OK") {
                dispatch(this.actions.outletAddressFetched({ address: { lat: result[0].geometry.location.lat(), lng: result[0].geometry.location.lng() } }))
            } else {
                // console.log(`Map error for outlet adrress => ${status}`)
            }
        })
    }
}

export const reducerInfo = {
    name: "gogleMap"
}

export const gogleMapCrud = new Cruds("gogleMap")
export const gogleMapSlice = new Slice(reducerInfo.name)
export const gogleMapActions = new Actions(gogleMapCrud, gogleMapSlice)
