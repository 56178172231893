import { createSlice } from "@reduxjs/toolkit";
import paymentModeMSTCruds from '../../_apis/cruds/paymentModeMSTCrud'
const REDUCER_NAME = "paymentModeMST"
const TYPES = {
    PAYMENT_MODE: 'payment_mode'
}
export const paymentModeMSTSlice = createSlice({
    name: REDUCER_NAME,
    initialState: {
        ...Object.values(TYPES).reduce((occ, cur) => { return ({ ...occ, [cur]: { loading: false, error: null, data: null } }) }, {})
    },
    reducers: {
        catchError: (state, action) => {
            state[action.payload.type].loading = false;
            state[action.payload.type].error = action.payload.error;
        },
        startCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = true;
        },
        stopCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
        },
        dataRecieved: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
            state[action.payload.type].data = action.payload.data;
        },
        clearAllItems: (state) => {
            state[TYPES.PAYMENT_MODE].data = null;
          },
    },
})

const startCall = (type) => paymentModeMSTSlice.actions.startCall({ type })
const stopCall = (type) => paymentModeMSTSlice.actions.stopCall({ type })
const dataRecieved = (type, data) => paymentModeMSTSlice.actions.dataRecieved({ type, data })
const catchError = (type, error) => paymentModeMSTSlice.actions.catchError({ type, error })

export const paymentModeActions = {
    getPaymentMode:()=> dispatch=>{
        dispatch(startCall(TYPES.PAYMENT_MODE))      
        return paymentModeMSTCruds.getOutletPaymentMode().then(res => {
            dispatch(dataRecieved(TYPES.PAYMENT_MODE, res))
        }).catch(err => {
            dispatch(catchError(TYPES.PAYMENT_MODE, err))
        })
    },
}