import { createSlice } from "@reduxjs/toolkit";
import itemMSTCrud from "../../_apis/cruds/itemMSTCrud";

const REDUCER_NAME = "buildYourOwn"

export const buildYourOwnSlice = createSlice({
    name: REDUCER_NAME,
    initialState: {
        error: null,
        item: {
            itemObj: null,
            recipeObj: null,
            base: null,
            sauce: null,
            selectedBasePrice: null,
            price: null,
            qty: null,
            size: null,
            name: null,
            description: null,
            image: null,
            baseName: null,
            localId: null,
            localIdForEdit: null
        },
    },
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload;
        },
        loadItem: (state, action) => {
            state.item = action.payload
        },
        setItemValue: (state, action) => {
            if (state.item)
                Object.keys(action.payload).forEach(k => {
                    state.item[k] = action.payload[k]
                })
        },
        setLocalId: (state, action) => {
            state.item.localIdForEdit = action.payload
        }
    },
})


export const buildYourOwnActions = {
    /**
     * @param {ITEM_OBJ} obj
     */
    loadItem: (obj) => dispatch => {
        dispatch(buildYourOwnSlice.actions.loadItem(obj))
    },
    // loadItem: (obj) => dispatch => {
    //     dispatch(customizeSlice.actions.loadItem(obj))
    // },

    setItemValue: (item) => dispatch => {
        dispatch(buildYourOwnSlice.actions.setItemValue(item))
    }
}