import Modal from "react-bootstrap/Modal";
import './closeDialog.scss'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OrderTypeCustomDialog = ({
  isOpen,
  handleClose,
  dialogClassName,
  dialogTitle,
  dialogBody,
  dialogHeader,
  closeButton = true,



}) => {
    const history = useHistory();
  
 const  userCurrentLocation = history.location.pathname ===  "/outlet-selection" ? true : false;

  
  
    return (
    <> 
     {
        userCurrentLocation ? ( dialogBody ) :  
        (
        
        <Modal
        show={isOpen}
        onHide={() => handleClose()}
        dialogClassName={dialogClassName}
        aria-labelledby="example-custom-modal-styling-title"
      >
        {dialogHeader ? <Modal.Header style={{ padding: "0 5px" }} closeButton={closeButton}>
          <Modal.Title id="example-custom-modal-styling-title">
            {dialogTitle}
          </Modal.Title>
        </Modal.Header> : null}
  
        <Modal.Body  style={{padding: '0rem'}} >{dialogBody}</Modal.Body>
      </Modal>
      )
     }
    
    </>
   );
};
export default OrderTypeCustomDialog;


