import { createSlice } from "@reduxjs/toolkit";
import geographyTypeMSTCruds from "../../_apis/cruds/geographyTypeMSTCrud";
const REDUCER_NAME = "geographyTypeMST";

const TYPES = {
  GEOGRAPHY_TYPE: "geographyType",
};

export const geographyTypeMSTReduxSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return { ...occ, [cur]: { loading: false, error: null, data: null } };
    }, {}),
  },
  reducers: {
    catchError: (state, action) => {
      state[action.payload.type].loading = false;
      state[action.payload.type].error = action.payload.error;
    },
    startCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = true;
    },
    stopCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
    },
    dataRecieved: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = action.payload.data;
    },
  },
});

const startCall = (type) => geographyTypeMSTReduxSlice.actions.startCall({ type });
const stopCall = (type) => geographyTypeMSTReduxSlice.actions.stopCall({ type });
const dataRecieved = (type, data) =>
geographyTypeMSTReduxSlice.actions.dataRecieved({ type, data });
const catchError = (type, error) =>
geographyTypeMSTReduxSlice.actions.catchError({ type, error });

export const geographyTypeMSTActions = {
  getAll: () => (dispatch) => {
    dispatch(startCall(TYPES.GEOGRAPHY_TYPE));
    return geographyTypeMSTCruds
      .getAllActive()
      .then((res) => {
        dispatch(dataRecieved(TYPES.GEOGRAPHY_TYPE, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.GEOGRAPHY_TYPE, err));
      });
  },
  
};
