import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import CustomDialog from "../../componets/dialog/CustomDialog";
import BlockButton from "../../componets/FormComponent/BlockButton";
import SimpleSelect from "../../componets/FormComponent/SimpleSelect";
import { Switch, useLocation, Route, useRouteMatch } from "react-router-dom";
import { outletShiftActions } from "../../_redux/files/outletShiftRedux";
import { outletMSTActions } from "../../_redux/files/outletMSTRedux";
import {
  parseShiftDTL,
  getDateSlots,
  getFormattedTime,
  checkTimeIn,
  getMonthValue,
  removeDayFromDate,
  convertToTimeZone
} from "../../helpers/DialogHelper";
import "./DeliveryDialog.scss";
import { cartActions } from "../../_redux/files/cartRedux";
import ROUTES from "../../constants/ROUTES";
import { orderMSTActions } from "../../_redux/files/orderMSTRedux";
import { useHistory } from "react-router";
import Spinner from "../../componets/etc/Spinner";
import { authActions } from "../../_redux/files/authRedux";
import OrderTypeCustomDialog from "../../componets/dialog/OrderTypeCustomDialog";
import Select, { components } from "react-select";

const PickupDialog = (props) => {
  //const [open, setOpen] = useState(props.orderType);
  const [open, setOpen] = useState(true);
  const [storeTime, setStoreTime] = useState([]);
  const history = useHistory();
  const [pickup, setPickup] = useState("Now");
  const [prevOutlet, setPrevOutlet] = useState(false);
  const [orderDates, setOrderDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [orderTimes, setOrderTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [outletMain, setOutletMain] = useState();
  const [outletAddress, setOutletAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [remember, setRemember] = useState(true);
  const [storeOpen, setStoreOpen] = useState(true);
  const { loading, data, error } = useSelector((state) => state.home.orderType);
  const location = useLocation();
  const [confirmSelectedOutlet, setConfirmSelectedOutlet] = useState(null);

  const outletShiftDetail = useSelector(
    (state) => state.outletShift.outletShiftDtl.data
  );
  const outletMainData = useSelector((state) => state.outletMST.currentOutlet);

useEffect(() => {

  setOutletMain(outletMainData?.data?.[0]?.outletCode)

}, [outletMainData])

  const { loadingOutletShift, dataOutletShift, errorOutletShift } = useSelector(
    (state) => {
      return {
        loadingOutletShift: state.outletShift.outletShiftDtl.loading,
        dataOutletShift: state.outletShift.outletShiftDtl.data,
        errorOutletShift: state.outletShift.outletShiftDtl.error,
      };
    }
  );
  const { loadingOutlet, loadingOutletData, errorOutlet } = useSelector(
    (state) => {
      return {
        loadingOutlet: state.outletMST.outlet.loading,
        loadingOutletData: state.outletMST.outlet.data,
        errorOutlet: state.outletMST.outlet.error,
    
      };
    }
  );

  const { authUser, isAuthenticating } = useSelector(({ auth }) => ({
    authUser: auth.authUser,
    isAuthenticating: auth.loading.loadingOutlet,
    error: auth.error.authenticating,
  }));




  const allOutlet = useSelector((state) => state.outletMST.allOutlet.data);



    const currentOutlet = useSelector(
      (state) => state?.outletMST?.currentOutlet?.data?.[0]
    );

    
    const handleSelect = (outletCode) => {

      setOutletMain(outletCode);
  
      const selectedOutlet = allOutlet.filter((item) => item.outletCode === outletCode);
      setConfirmSelectedOutlet(selectedOutlet);
  
      if(selectedOutlet?.[0]?.outletCode !== outletMainData?.data?.[0]?.outletCode){
        dispatch(orderMSTActions.resetCustomerAddress());
        localStorage.removeItem("address")
        debugger
        setPrevOutlet(true)
        dispatch(authActions.authenticateUser(selectedOutlet?.[0]) , false).then(() => {
          // history.push(ROUTES.home._path);
          dispatch(outletMSTActions.setCurrentOutlet(selectedOutlet));
        })  
      }
    };


  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (data === "pickup") {
  //     setOpen(true);
  //   }
  // }, [data]);
  useEffect(() => {
    if (currentOutlet) {
      // dispatch(outletMSTActions.getCurrentOutlet(currentOutlet.outletCode));
    }
  }, [currentOutlet, dispatch]);  





      const handleOutletSelect = (e) => {
        setOutletMain(e.value)
        let selectedOutlet = allOutlet?.filter((item, index) =>
       {
        return  e.value === item.outletCode
       }
    );
    setConfirmSelectedOutlet(selectedOutlet)
        
        // if (!authUser) {
            if(selectedOutlet?.[0]?.outletCode !== outletMainData?.data?.[0]?.outletCode){
              dispatch(orderMSTActions.resetCustomerAddress());
              localStorage.removeItem("address")
              debugger
              setPrevOutlet(true)
              dispatch(authActions.authenticateUser(selectedOutlet?.[0]) , false).then(() => {
                // history.push(ROUTES.home._path);
                dispatch(outletMSTActions.setCurrentOutlet(selectedOutlet));
              })  
            // }
      }
      }
      let outletPath = history.location.pathname === '/menu/check-out/check-out';


  useEffect(() => {
    setOutletAddress([loadingOutletData?.address1]);
  }, [loadingOutletData]);

  useEffect(() => {
    if (storeTime.length === 0  && dataOutletShift ) {
      setStoreOpen(false);
      setOpen(false);
    }
  }, [storeTime, dataOutletShift]);

  useEffect(() => {
    let orderDateSlots = getDateSlots();
    let slotDate = null;
    let data = [];
    for (let i = 0; i < orderDateSlots.length; i++) {
      let onlyDate = removeDayFromDate(orderDateSlots[i]);
      data = dataOutletShift
        ? parseShiftDTL(dataOutletShift, new Date(onlyDate), "Pick-Up")
        : [];
      if (data.length > 0) {
        slotDate = onlyDate;
        break;
      } else {
        orderDateSlots.splice(i, 1);
        i--;
      }
    }

    setStoreTime(data);
    setOrderDates(orderDateSlots);
    if (data.length === 0 && dataOutletShift) {
      setStoreOpen(false);
      setOpen(false);
    } else {
      let currentTime = getFormattedTime(
        new Date(new Date(slotDate).setTime(convertToTimeZone(new Date()).getTime()))
      );
      if (convertToTimeZone(new Date()).getDate() === new Date(slotDate).getDate()) {
        if (
          checkTimeIn(
            new Date(slotDate).getFullYear() +
              "-" +
              getMonthValue(slotDate?.split(" ")[1]) +
              "-" +
              new Date(slotDate).getDate() +
              " " +
              currentTime,
            dataOutletShift,
            "Pick-Up"
          )
        ) {
          setOpen(true);
          setStoreOpen(true);
          data = ["Now", ...data];
        }
      }
    }
    setOrderTimes(data);
    setSelectedDate(new Date(slotDate));
    setSelectedTime(data.length > 0 ? data[0] : null);
  }, [dataOutletShift]);
  const handleClose = (e) => {
    props.closeDialog();
  };
  const checkSlotAvailable = (date, time) => {
    let data = dataOutletShift
      ? parseShiftDTL(dataOutletShift, date, "Pick-Up")
      : [];
    if (data.length > 0) {
      if (time === null || time === "Now") return true;
      if (data.includes(time)) {
        return true;
      }
    }
    return false;
  };
  const handleContinueWithOrder = (e) => {
   
      // now seting item in  redux
    // console.log('Confrim selected outlet',confirmSelectedOutlet)
    // localStorage.setItem('selected-outlet', JSON.stringify(confirmSelectedOutlet));
    
    // if(history.location.pathname === "/menu/check-out/check-out") {
    // } else {
    //   history.push(ROUTES.home._path);
    // }
    if(history.location.pathname === "/outlet-selection") {
      debugger
      history.push(ROUTES.home._path);
    } else {
      // history.push(ROUTES.home._path);
    }



    
    dispatch(orderMSTActions.setOrderType("OT01"));
    debugger

    if(!prevOutlet) {
      debugger
      // don't add order in cart when outlet change 
      props.confirm();
    }

    let orderDateTime = {
      orderDate:
        selectedDate === null ? convertToTimeZone(new Date()) : removeDayFromDate(selectedDate),
      orderTime: selectedTime === null ? "Now" : selectedTime,
      timedOrder:
        selectedTime === null || selectedTime === "Now" ? false : true,
    };
    if (
      !checkSlotAvailable(
        new Date(orderDateTime.orderDate),
        orderDateTime.orderTime
      )
    ) {
      setOpen(false);
      setStoreOpen(false);
      return;
    }
    let data = {
      unitNumber: loadingOutletData?.geographyMST7
        ? loadingOutletData.geographyMST7.geographyName
        : null,
      unitNumberId: loadingOutletData?.geographyMSTId7,
      streetNumber: loadingOutletData?.geographyMST6
        ? loadingOutletData.geographyMST6.geographyName
        : null,
      streetNumberId: loadingOutletData?.geographyMSTId6,
      streetName: loadingOutletData?.geographyMST5
        ? loadingOutletData.geographyMST5.geographyName
        : null,
      streetNameId: loadingOutletData?.geographyMSTId5,
      suburbName: loadingOutletData?.geographyMST4
        ? loadingOutletData?.geographyMST4.geographyName
        : null,
      suburbId: loadingOutletData?.geographyMSTId4,
      pincode: loadingOutletData?.geographyMST3
        ? loadingOutletData.geographyMST3.geographyName
        : null,
      pincodeId: loadingOutletData?.geographyMSTId3,
    };
    dispatch(cartActions.setDeliveryCharge(0));
    dispatch(orderMSTActions.setCustomerAddress(data));
   
    dispatch(orderMSTActions.setOrderDateTime(orderDateTime));
    props.closeDialog();
    if (props.redirect) {
      if (props?.data?.fromOrderHistory || props?.data?.length >= 1) {
        history.push(ROUTES.menu._path + "/check-out/check-out");
      } else {
        if(history.location.pathname === ROUTES.orderType._path)
        history.push(ROUTES.menu._path);
      }
    }
  };
  const handleDeliveryChange = (e, pickup) => {
    if (open === false && pickup === "Now") {
      setStoreOpen(false);
    }
    if (pickup === "later") {
      setStoreOpen(true);
    }
    setPickup(pickup);
    if (selectedTime === null) {
      setSelectedTime(orderTimes.length > 0 ? orderTimes[0] : null);
    }
  };
  const handleSelectDate = (e) => {
    let onlyDate = removeDayFromDate(e.target.value);
    let data = dataOutletShift
      ? parseShiftDTL(dataOutletShift, new Date(onlyDate), "Pick-Up")
      : [];
    if (data.length === 0) {
      setStoreOpen(false);
      setOpen(false);
    } else {
      setStoreOpen(true);
      setOpen(true);
    }
    if (new Date(onlyDate).getDate() === convertToTimeZone(new Date()).getDate()) {
      data = ["Now", ...data];
    }
    setOrderTimes(data);
    setSelectedDate(e.target.value);
    setSelectedTime(data.length > 0 ? data[0] : null);
  };
  const handleSelectTime = (e) => {
    setSelectedTime(e.target.value);
  };
  const handleChange = (e) => {};
  const handleRemember = (event) => {
    setRemember(event.target.checked);
  };


  
let defaultValue = null;
let isDisabled = false;

if (allOutlet && allOutlet.length === 1) {
  isDisabled = true;
}

useEffect(() => {
  if (allOutlet && allOutlet.length === 1) {
    // handleOutletSelect({ value: allOutlet[0].outletCode, label: allOutlet[0].address1 });
    handleSelect(allOutlet[0].outletCode)
  }
}, [allOutlet]); 

  return (
<>
    <OrderTypeCustomDialog
    isOpen={props.open}
    handleClose={handleClose}
    closeButton
    dialogClassName="dialog"
    dialogTitle="Please Select store to pick-up items"
    dialogBody={
      <>
      {
        props.open ?
        <div className={`  ${history.location.pathname === "/"  || history.location.pathname ===  "/menu/check-out/check-out"  ? '' :  'mt-2'}  delivery-dialog orderTypeCard  ${history.location.pathname === "/outlet-selection" ? 'orderTypeMobile' : '' }`}>
        <div
          className="text-right"
          style={{
            position: "absolute",
            right: "-5px",
            top: "-10px",
            fontSize: "15pt",
            cursor: "pointer",
            padding: "10px",
            color: "#444",
          }}
        >
               {/* <i class="fas fa-times-circle"></i> */}
        </div>
  
        {/* <br /> */}
        <div
          className="pickupHedding mb-3 "
          style={{
            fontFamily: "ArialMT",
            color: "#000D2A",
            fontSize: "20px",
            // fontWeight: "bold",
            // fontSize: "25px",
            // fontWeight: "bold",
          }}
        >
          Please select the store to pick-up items
        </div>
        {/* <br /> */}
        <ul className="nav nav-tabs schedule">
          <li className=" schedule-now">
            <div
              className={"nav-link " + (pickup === "Now" ? "activetab" : "")}
              onClick={(e) => handleDeliveryChange(e, "Now")}
            >
              <div className="desktop"> Pick-up Now</div>
              <div className="mobile"> Now</div>
            </div>
          </li>
          <li className="schedule-later">
            <div
              className={
                "nav-link " + (pickup === "later" ? "activetab" : "")
              }
              onClick={(e) => handleDeliveryChange(e, "later")}
            >
              <div className="desktop"> Pick-up Later</div>
              <div className="mobile">
                <span>
                  <img src="https://img.icons8.com/office/16/000000/clock.png" />
                </span>
                Later
              </div>
            </div>
          </li>
        </ul>
  

        {/* <div className="col-12 " style={{ marginRight: "80px", marginTop: '1rem' }}>
  
                  <Select
                    isDisabled={isDisabled  || history.location.pathname === '/menu/check-out/check-out' ? true : false
                  }
                    className=" "
                    id="inlineFormCustomSelect"
                    options={allOutlet?.map((item) => (
                      {
                        value: item.outletCode, label: item.address1
                      }
                    ))
                  }
                    placeholder="Select Outlet"
                    onChange={(e) => handleOutletSelect(e)}
                    // value={defaultValue}

                    value={
                      allOutlet?.map((item) => (
                        {
                          value: item.outletCode, label: item.address1
                        }
                      )).filter((option) => {
                         return outletMain === option.value
                      }
                    )}
                  />
  
                {/* <div className="form-label">Select outlet</div>
                <SimpleSelect
                  data={allOutlet?.map((item) => item.outletCode)}
                  change={(e) => handleOutletSelect(e)}
                  name="outlet"
                  value={outletMain}
                  key="1"
                ></SimpleSelect> */}
              {/* </div> */}


  
              {/* <ul className="location-list">
      {locations.map((location) => (
        <>
       
        <li
          key={location}
          className={selectedLocation === location ? 'selected' : ''}
          onClick={() => handleClick(location)}
        >
          <div style={{width: '100px', backgroundColor: 'gray'}}></div>
          {location}
        </li>
        </>
      ))}
    </ul> */}
   <div className="pickup-container">
   {allOutlet?.map((location) => (
        <div
          key={location.name}
          className={`pickup-option ${outletMain === location.outletCode? 'selected' : ''} ${outletPath ?  'disabled' : ''}`}
              onClick={() => {
            if (outletPath) {
              return;
            }
            handleSelect(location.outletCode);
          }}
        >
          <div className={`grey-block ${outletMain === location.outletCode ? 'selected' : ''}  ${outletPath ?  'disabled' : ''}`}></div>
          <div className="location-info">
            <div className="location-name">{location.outletName}</div>
            <div className="location-address">{location.address1}</div>
          </div>
        </div>
      ))}
    </div>


        {isAuthenticating ? (
         <div style={{
          position:"absolute",
          left: "47%"
         }}>
         <Spinner></Spinner>
         </div>
        ) : storeOpen ? null : (
          <div className="err-msg mt-2" style={{padding: '0px 20px'}}>
            Store is currently closed. Please select next available time
          </div>
        )}
  
        <div className="row pickUpDate " style={{ marginTop: "10px" }}>
          {pickup === "later" ? (
            <>
              <div className="col-6 ">
              {/* <div className="col-6 " style={{ marginRight: "80px" }}> */}
                <div className="form-label">Date</div>
                <SimpleSelect
                  data={orderDates}
                  change={(e) => handleSelectDate(e)}
                  name="date"
                  value={selectedDate}
                  key="1"
                ></SimpleSelect>
              </div>
              <div className="col-6 ">
                <div className="form-label">Time</div>
                <SimpleSelect
                  data={orderTimes}
                  name="time"
                  value={selectedTime}
                  change={(e) => handleSelectTime(e)}
                ></SimpleSelect>
              </div>
            </>
          ) : null}
          {/* <div className="col-12 mt-2">
            {/* <SimpleSelect
              data={outletAddress}
              name="time"
              value={selectedAddress}
              change={(e) => handleSelectTime(e)}
              style={{width:'100%',height:'70px',varticleAlign:'top'}}
            ></SimpleSelect>
             <div>
              <span>
                <b>Outlet Address</b>
              </span>
            </div>
            <div
              className="pickupAdress"
              style={{
                backgroundColor: "#fff",
                height: "70px",
                padding: "5px",
                borderRadius: "6px",
              }}
            >
              {outletAddress}
            </div>
            </div> */}
        </div>
        {/* <Checkbox
          check={remember}
          change={(e) => handleRemember(e)}
          value="remember"
          color="default"
          inputLabel="Remember Pick-up details"
        /> */}
        <div
          className="pickupBtn"
          style={{ marginBottom: "40px", marginTop: "15px" }}
        >
          <BlockButton
            text="Continue with Order"
            click={(e) => handleContinueWithOrder(e)}
            // check={!storeOpen || (!outletMain || isAuthenticating)}
            check={!storeOpen || (!outletMain || isAuthenticating || !dataOutletShift)}
            // test={[!storeOpen, !outletMain,  isAuthenticating]}
          ></BlockButton>
        </div>
      </div>
      : null
      }
      
      </>
    
  } />    
  </>
  );
};
export default PickupDialog;
