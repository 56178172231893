import { useDispatch } from "react-redux";
import { calcToppings, cartSlice } from "../_redux/files/cartRedux";
import { letsRound } from "./Utils";
export const getSingleItem = (obj, comboId, itemPos, combSeq) => {
    if (obj.recipeMap) {
    let base = obj.base ? obj.base : getBase(obj);
    let price = getRecipePrice(obj);
    let selectedBasePrice = getBasePrice(obj, base);
    let size = getSize(obj);
    let recipe = getRecipeFromItem(obj);
    let additionalPrice = obj.additionalPrice ?? 0;
    let totalToppingss = 0;
    let toppingsPricess = 0;
    if (recipe?.Toppings && recipe?.Toppings?.length) {
      const { totalToppings, toppingsPrice } = calcToppings(recipe?.Toppings);
      totalToppingss = totalToppings;
      toppingsPricess = toppingsPrice;
    }
    return {
      itemObj: obj,
      index: itemPos,
      recipeObj: recipe,
      base: base,
      sauce: recipe?.Sauce?.find((x) => x.active),
      selectedBasePrice: selectedBasePrice,
      toppingsPrice: toppingsPricess,
      totalToppings:totalToppingss,
      price: letsRound(parseInt(price) + selectedBasePrice),
      qty: 1,
      size: size,
      name: obj.recipeMap.shortName,
      description: obj.recipeMap.ingredients,
      id: obj.recipeMap.id,
      // toppings: getRecipeFromItem(size).toppings,
      image: obj.recipeMap.image,
      additionalPrice: additionalPrice,
      baseName: recipe?.Base?.find((x) => x.id === base)?.name,
      comboRefId: comboId,
      combSeq: comboId ? combSeq : null,
      offeritem: true,
      itemGroupCode: obj.itemGroupCode,
      dbId: 0,
    };
  }
  if (obj.itemMap) {
    let price = getItemPrice(obj);
    let additionalPrice = obj.additionalPrice ?? 0;
    return {
      itemObj: obj,
      recipeObj: undefined,
      base: undefined,
      sauce: undefined,
      index: itemPos,
      selectedBasePrice: undefined,
      price: letsRound(parseInt(price)),
      qty: 1,
      size: undefined,
      name: obj.itemMap.shortName,
      description: obj.itemMap.ingredients,
      id: obj.itemMap.id,
      // toppings: getRecipeFromItem(size).toppings,
      image: obj.itemMap.image,
      baseName: undefined,
      comboRefId: comboId,
      combSeq: comboId ? combSeq : null,
      offeritem: true,
      additionalPrice: additionalPrice,
      itemGroupCode: obj.itemGroupCode,
      dbId: 0,
    };
  }
};

const getSingleItemFromHistory = (obj, comboId, itemPos) => {
  if (obj.recipes) {
    let recipe = Object.values(obj.recipes[0])[0];
    let base = recipe.Base ? recipe.Base[0].id : getBase(obj, true);
    let price = obj.itemPrice;
    let selectedBasePrice = getBasePrice(obj, base, true);
    let size = getSize(obj, true);
    //let recipe=getRecipeFromItem(obj);
    let additionalPrice = obj.additionalPrice ?? 0;
    return {
      itemObj: obj,
      index: itemPos,
      recipeObj: recipe,
      base: base,
      sauce: recipe?.Sauce?.find((x) => x.defaultQuantity === x.itemQuantity),
      selectedBasePrice: selectedBasePrice,
      price: letsRound(parseInt(price) + selectedBasePrice),
      qty: 1,
      size: size,
      name: obj.item.shortName,
      description: obj.item.ingredients,
      id: recipe.id,
      // toppings: getRecipeFromItem(size).toppings,
      image: obj.item.image,
      additionalPrice: additionalPrice,
      baseName: recipe?.Base?.find((x) => x.id === base)?.name,
      comboRefId: comboId,
      offeritem: true,
      itemGroupCode: obj.itemGroupCode,
      dbId: 0,
    };
  }
  if (obj.item) {
    let price = getItemPrice(obj, true);
    let additionalPrice = obj.additionalPrice ?? 0;
    return {
      itemObj: obj,
      recipeObj: undefined,
      base: undefined,
      sauce: undefined,
      index: itemPos,
      selectedBasePrice: undefined,
      price: letsRound(parseInt(price)),
      qty: 1,
      size: undefined,
      name: obj.item.shortName,
      description: obj.item.ingredients,
      id: obj.item.id,
      // toppings: getRecipeFromItem(size).toppings,
      image: obj.item.image,
      baseName: undefined,
      comboRefId: comboId,
      offeritem: true,
      additionalPrice: additionalPrice,
      itemGroupCode: obj.itemGroupCode,
      dbId: 0,
    };
  }
};

const getRecipeFromItem = (obj, history = false) => {
  if (history) {
    if (obj.recipes) {
      return Object.values(obj.recipes[0])[0];
    }
  } else {
    if (obj.recipeMap?.recipes[0]) {
      return Object.values(obj.recipeMap?.recipes[0])[0];
    }
  }

  return null;
};
const getItem = (obj, history = false) => {
  if (history) {
    if (obj.item) {
      return obj.item;
    }
  } else {
    if (obj.itemMap) {
      return obj.itemMap;
    }
  }

  return null;
};
const getBase = (obj, history = false) => {
  const recipe = getRecipeFromItem(obj, history);
  if (recipe?.Base) {
    const options = recipe.Base.filter(
      (x) => x.itemQuantity > 0 && x.defaultQuantity === x.itemQuantity
    ).map((b, i) => ({
      label: b.name,
      value: b.id,
    }));
    return options?.length ? options[0].value : 0;
  } else {
    return 0;
  }
};
const getBaseOption = (obj, id, history = false) => {
  const recipe = getRecipeFromItem(obj, history);
  if (recipe.Base) {
    const options = recipe.Base.filter((x) => x.id === id).map((b, i) => ({
      label: b.name,
      value: b.id,
    }));
    return options;
  } else {
    return [];
  }
};
const getRecipePrice = (obj, history = false) => {
  let recipe = getRecipeFromItem(obj, history);
  return letsRound(recipe.basePrice + (recipe.basePrice * recipe.tax) / 100);
};
const getItemPrice = (obj, history = false) => {
  const item = getItem(obj, history);
  return letsRound(item.price + (item.price * item.tax) / 100);
};
const getBasePrice = (obj, id, history = false) => {
  let basePrice = getRecipeFromItem(obj, history).Base?.find(
    (x) => x.id === id
  );
  return basePrice ? basePrice.addCost : 0;
};
const getSize = (obj, history = false) => {
  if (history) {
    let sizeObj = getRecipeFromItem(obj, history);
    return sizeObj.Size?.id;
  } else {
    return getRecipeFromItem(obj, history).Size?.id;
  }
};
export const getItemFromOffer = (offerObj, addItem, groupCode, index) => {
  if (addItem.recipeObj) {
    let itemPresent = offerObj.offersAllItem.filter(
      (n) =>
        n.itemGroupCode === groupCode &&
        n.recipeMap &&
        n.recipeId === addItem.recipeObj.id
    );
    if (itemPresent && itemPresent.length) {
      let offerAddItem = { ...itemPresent[0], base: addItem.base };
      let item = getSingleItem(
        offerAddItem,
        offerObj.id,
        index,
        offerObj.combinationSequence
      );
      if (addItem.base) {
        const baseOption = getBaseOption(offerAddItem, addItem.base);
        if (baseOption.length > 0) {
          item.base = baseOption[0].value;
          item.selectedBasePrice = getBasePrice(offerAddItem, item.base);
          item.baseName = baseOption[0].label;
        }
      }
      let actionOption = offerObj.offersAllItem.filter(
        (n) => n.itemGroupCode === item.itemGroupCode
      );
      let action = false;
      if (actionOption && actionOption.length > 1) {
        action = true;
      }
      return { ...item, action };
    }
    return null;
  } else if (addItem.itemObj) {
    let itemPresent = offerObj.offersAllItem.filter(
      (n) =>
        n.itemGroupCode === groupCode &&
        n.itemMap &&
        n.itemId === addItem.itemObj.id
    );
    if (itemPresent && itemPresent.length) {
      let item = getSingleItem(itemPresent[0], offerObj.id, index);
      let actionOption = offerObj.offersAllItem.filter(
        (n) => n.itemGroupCode === item.itemGroupCode
      );
      let action = false;
      if (actionOption && actionOption.length > 1) {
        action = true;
      }
      return { ...item, action };
    }
    return null;
  }
};

export const replaceItemAtIndex = (arr, index, newValue) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};
export const getAvailableIndex = (offerItem) => {
  if (offerItem.length) {
    let index = 1;
    // let filterIndex=offerItem.filter(n=>n.index===index)
    // for(let i = 0; i<10; i++){
    //   if(offerItem[i]){
    //     if(offerItem[i].index === index){
    //       index++
    //    }
    //   }
    //  else {
    //     }
    // }
    let filterIndex = offerItem.map((n) => {
      if (n) {
        if (n.index === index) {
          index++;
        }
      }
    });
    return index;
  } else {
    return 1;
  }
};
export const addedGroupItem = (offerItem, group) => {
  let removeGroup = [...offerItem.removedItemGroup];
  const groupPresent = removeGroup.filter((n) => n.itemGroupCode === group);
  if (groupPresent && groupPresent.length) {
    if (groupPresent[0].numberOfitems > 1) {
      let index = removeGroup.indexOf(groupPresent[0]);
      let newEntity = {
        groupName: groupPresent[0].groupName,
        numberOfitems: groupPresent[0].numberOfitems - 1,
        itemGroupCode: groupPresent[0].itemGroupCode,
        data: groupPresent[0]?.data,
      };
      removeGroup = replaceItemAtIndex(removeGroup, index, newEntity);
      return { ...offerItem, removedItemGroup: removeGroup };
    } else {
      return {
        ...offerItem,
        removedItemGroup: removeGroup.filter((n) => n.itemGroupCode !== group),
      };
    }
  }
};
export const getOfferHistoryItem = (itemList, offerObj) => {
  let obj = [];
  let index = 1;

  const initialForCombSeq = "Comb";
  let randomNumber = Math.floor(1000 + Math.random() * 9000);
  let combSeq = initialForCombSeq.concat(randomNumber);

  itemList.map((row) => {
    let offerItem = null;
    if (row.recipes) {
      let filterOffer = offerObj.itemResponseList.filter(
        (n) =>
          n.recipeMap &&
          Object.values(n.recipeMap?.recipes[0])?.find(
            (x) => x.id === Object.values(row.recipes[0])[0].id
          )
      ).map((item) => ({
        ...item,
        qty: row.quantity
      }));
      if (filterOffer && filterOffer.length > 0) {
        offerItem = filterOffer[0];
        let n = Object.values(offerItem.recipeMap?.recipes[0])[0];
        let key = Object.keys(offerItem.recipeMap?.recipes[0])[0];
        if (n.Base) {
          let filterBase = n.Base.find(
            (x) => x.id === Object.values(row.recipes[0])[0].Base[0].id
          );
          if (filterBase) {
            n = { ...n, Base: [filterBase] };
          }
        }
        if (n.Toppings) {
          let toppingArr = [];
          let modifiedItem = row.modifiedItems;
          if (modifiedItem && modifiedItem.length > 0) {
            n.Toppings.map((topping) => {
              let changeTopping = modifiedItem.find(
                (t) => t.recipeItemDTLId === topping.id
              );
              if (changeTopping) {
                toppingArr.push({
                  ...topping,
                  defaultQuantity: changeTopping.plannedQuantity,
                  itemQuantity: changeTopping.actualQuantity,
                });
              } else {
                toppingArr.push(topping);
              }
            });
          }
          n = { ...n, Toppings: [...toppingArr] };
        }

          if(n?.Sauce) {
            let sauceArr = [];
            let modifiedItem = row.modifiedItems;
          if (modifiedItem && modifiedItem.length > 0) {
            n.Sauce.map((sauce) => {
              let changeSauce = modifiedItem.find(
                (t) => t.recipeItemDTLId === sauce.id
              );
              if (changeSauce) {
                sauceArr.push({
                  ...sauce,
                  active:true,
                  defaultQuantity: changeSauce.plannedQuantity,
                  itemQuantity: changeSauce.actualQuantity,
                });
              } else {
                sauceArr.push({
                  ...sauce,
                  active:false
                });
              }
            })
          }
          n = { ...n, Sauce: [...sauceArr] };
          }
        let recipesArr = [{ [key]: n }];
        // let recipesArr = row.availableItems.item.recipes;
        offerItem = {
          ...offerItem,
          recipeMap: {
            ...offerItem.recipeMap,
            availableSizes: row.availableSizes,
            recipes: recipesArr,
          },
        };
      }
    } else if (row.item) {
      let filterOffer = offerObj.itemResponseList.filter(
        (n) => n.itemMap && n.itemMap.id === row.item.id
      );
      if (filterOffer && filterOffer.length > 0) {
        offerItem = filterOffer[0];
      }
    }
    let entity = getSingleItem(offerItem, row.offerId, index);
    //let entity=getSingleItemFromHistory(row,row.offerId,index)
    let actionOption = offerObj.itemResponseList.filter(
      (n) => n.itemGroupCode === entity.itemGroupCode
    );
    let action = false;
    if (actionOption && actionOption.length > 1) {
      action = true;
    }
    obj.push({ ...entity, action, combSeq });
    index++;
  });
  return {
    offersAllItem: offerObj.itemResponseList,
    price: offerObj.priceWithTax.toFixed(2),
    // price: itemList
    //   .map((item) => item.itemPrice)
    //   .reduce((prev, next) => prev + next),
    image: offerObj.image,
    combinationSequence: combSeq,
    name: offerObj.offerName,
    offeritem: obj,
    id: offerObj.id,
    removedItemGroup: [],
    qty: 1,
  };
};

export const getItemsData = (data) => {
  // base: props.data.id,
  // baseName: " ",
  // description: "",
  // image: props.data.image,
  // name: props.data.offerName,
  // price: props.data.priceWithTax,
  let temp;
  const initialForCombSeq = "Comb";
  let randomNumber = Math.floor(1000 + Math.random() * 9000);
  let combSeq = initialForCombSeq.concat(randomNumber);
  let offeritem = data.itemResponseList.filter((data) => data.default === true);
  for (let i = 0; i <= offeritem.length; i++) {
    for (let j = i + 1; j <= offeritem.length; j++) {
      if (offeritem[i]?.sortOrder > offeritem[j]?.sortOrder) {
        temp = offeritem[i];
        offeritem[i] = offeritem[j];
        offeritem[j] = temp;
      }
    }
  }
  localStorage.setItem("combSeq", combSeq);
  let obj = [];
  let index = 1;
  offeritem.map((row) => {
    let entity = getSingleItem(row, data.id, index);
    let actionOption = data.itemResponseList.filter(
      (n) => n.itemGroupCode === entity.itemGroupCode
    );
    let action = false;
    if (actionOption && actionOption.length > 1) {
      action = true;
    }
    obj.push({ ...entity, action, combSeq });
    index++;
  });
  return {
    offersAllItem: data.itemResponseList,
    price: data.priceWithTax.toFixed(2),
    image: data.image,
    name: data.offerName,
    offeritem: obj,
    id: data.id,
    removedItemGroup: [],
    qty: 1,
  };
  // offersAllItem: props.data.itemResponseList,
  // numberOfitems: 0,
  // sauce: props.data.itemResponseList
  //   .filter((data) => {
  //     return data.default === true;
  //   })
  //   .map((el) => {
  //     return el.recipeMap?.Sauce;
  //   }),
};
export const checkItemInOffer = (
  obj,
  offerItemIndex,
  offerId,
  cartItems,
  combinationSeq
) => {
  const offer = cartItems.filter(
    (n) => n.id && n.id === offerId && n.combinationSequence === combinationSeq
  );
  // const makeCustomOfferSelection = cartItems.filter((n) => )

  if (offer && offer.length) {
    // const offerIdex = cartItems.indexOf(offer[0]);

    const filterGroupCode = offer[0].offeritem.filter(
      (n) => n.index === offerItemIndex
    );
    let groupCode = "";
    if (filterGroupCode.length > 0) {
      groupCode = filterGroupCode[0].itemGroupCode;
    } else {
      groupCode = offer[0].removedItemGroup.find(
        (n) => n.index === offerItemIndex
      ).itemGroupCode;
    }

    const offerItem = getItemFromOffer(
      offer[0],
      obj,
      groupCode,
      offerItemIndex
    );
    if (offerItem) {
      return true;
    }
  }
  return false;
};
