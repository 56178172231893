
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const couponMSTCruds = {

    all : () => {
        return axios.get(`/couponMST/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getAllActive : () => {
        return axios.get(`/couponMST/allActive/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{active:undefined, couponCode:undefined, couponMSTId:undefined, couponSystemMappingList:undefined, couponType:undefined, couponValue:undefined, createdBy:undefined, createdOn:undefined, description:undefined, endDate:undefined, free:undefined, itemMST:undefined, itemMSTId:undefined, maxUsage:undefined, modifiedBy:undefined, modifiedOn:undefined, outletMST:undefined, outletMSTId:undefined, recipeMST:undefined, recipeMSTId:undefined, startDate:undefined, utilized:undefined}}} data
     */
    applyPromoCode : (data) => {
        return axios.post(`/couponMST/applyPromoCode/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    checkCoupon : ({ code }) => {
        if(code.includes('%')){
            let uniCodeForModulo = "25"
            let index = code.indexOf('%')
            let tempString = [code.slice(0, index + 1), uniCodeForModulo, code.slice(index + 1)].join('')
            code = tempString
        }
        return axios.get(`/couponMST/checkCoupon/${code}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{active:undefined, couponCode:undefined, couponMSTId:undefined, couponSystemMappingList:undefined, couponType:undefined, couponValue:undefined, createdBy:undefined, createdOn:undefined, description:undefined, endDate:undefined, free:undefined, itemMST:undefined, itemMSTId:undefined, maxUsage:undefined, modifiedBy:undefined, modifiedOn:undefined, outletMST:undefined, outletMSTId:undefined, recipeMST:undefined, recipeMSTId:undefined, startDate:undefined, utilized:undefined}}} data
     */
    create : (data) => {
        return axios.post(`/couponMST/create/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    delete : ({ id }) => {
        return axios.delete(`/couponMST/delete/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byId : ({ id }) => {
        return axios.get(`/couponMST/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findPaginated : () => {
        return axios.get(`/couponMST/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    update : (data) => {
        return axios.put(`/couponMST/update/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default couponMSTCruds;
    