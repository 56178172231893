import React from "react";
const CheckBox = (props) => {
  
  return (
    <>
      <div class="form-check">
        <input
          name={props.name}
          checked={props.check}
          class="form-check-input"
          type="checkbox"
          value={props.value}
          id={props.id}
          disabled={props.disabled}
          onChange={props.change}
          style={props.style}
        ></input>
        <label class="form-check-label" for={props.id}>
          {props.inputLabel}
        </label>
      </div>
    </>
  );
};
export default CheckBox;
