import React, { useEffect, useState } from "react";
import "./topBar.scss";
// import LogoImage from "../../assets/images/aroma_pizza_logo.jpeg";
// import LogoImage from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../_redux/files/cartRedux";
import MyPopOver from "../etc/MyPopOver";
import Cart from "../../pages/menu/Cart";
import CustomDialog from "../dialog/CustomDialog";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ROUTES from "../../constants/ROUTES";
const TopBar = (props, { activeSubpage }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { items } = useSelector((s) => s.cart);
  const showPopUpCart = useSelector((s) => s.cart.showPopUpCart);
  const orderLoading = useSelector((state) => state.orderMST.orderTime.loading);
  const [searchToggle, setSearchToggle] = useState(false);
  const totalCartItems = useSelector((s) => s.cart.totalItems);
  const [hideIcon, setHideIcon] = useState(true);
  const [cartCount, setCartCount] = useState(0);

  const LogoImage = (require(process.env.REACT_APP_LOGO_IMAGE_PATH).default);


  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/full-page/order-type"
    ) {
      setHideIcon(true);
    } else {
      setHideIcon(false);
    }
  }, [location]);

  useEffect(() => {
    if (searchToggle) {
      document.querySelector(".search-input").focus();
    }
  }, [searchToggle]);
  useEffect(() => {
    if (!Array.isArray(items)) return;
    let sum = 0;
    items.forEach((each) => {
      sum += each.qty;
    });
    return setCartCount(sum);
  });
  return (
    <div className="topbar">
      <div className="top-panel row" onBlur={() => setSearchToggle(false)}>
        <div className="col-lg-4 col-md-4 col-sm-10 col-search">
          <div className="search-box serachHideforMobile">
            {/* {!searchToggle && (
              <i
                style={{ color: "#eee" }}
                className="fas fa-search icon-left icons"
                onClick={() => setSearchToggle(true)}
              ></i>
            )}
            {searchToggle && (
              <i
                className="fas fa-times icons icon-left"
                onClick={() => setSearchToggle(false)}
              ></i>
            )}
            {searchToggle && (
              <input
                type="search"
                className="search-input"
                placeholder="Search"
              />
            )}
            {searchToggle && <i className="fas fa-search icon-right icons"></i>} */}
          </div>
        </div>
        <div className="col col-right">
          <div className="order-pref">
            {/* <div className="custom-control custom-radio address-radio">
                            <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadio1"><span>Australia, VIC, 3042, AIRPORT WEST, Cameron St, 85589</span></label>
                        </div>
                        <div className="custom-control custom-radio pickup-radio">
                            <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadio2"><span>Pickup</span></label>
                        </div> */}
          </div>
          {/* <MyPopOver
            show={showPopUpCart}
            setShow={v => dispatch(cartActions.setShowPopUpCart(v))}
            buttonContent={(
              <span style={showPopUpCart ? { color: '#fcaf17' } : {}} className="cart-icon">
                <i className="fas fa-shopping-bag"></i>
                <span className="badge badge-light">{totalCartItems}</span>
              </span>
            )}
            bodyStyle={{ marginTop: '20px' }}
          >
            <Cart
            />
          </MyPopOver> */}

          {location.pathname.includes("payment") ? (
            <span
              style={{ opacity: "0.75", cursor: "context-menu" }}
              className="cart-icon2"
            >
              <i className="fas fa-shopping-bag"></i>
              <span className="badge badge-light">{cartCount}</span>
            </span>
          ) : (
            <span
              onClick={
                orderLoading
                  ? {}
                  : () => dispatch(cartActions.setShowPopUpCart(true))
              }
              style={showPopUpCart ? { color: "var(--test-primary)" } : {}}
              className="cart-icon"
            >
              <i className="fas fa-shopping-bag"></i>
              <span className="badge badge-light">{cartCount}</span>
            </span>
          )}
        </div>
      </div>
      {/* <img className="logo" src={LogoImage} alt="pinapple pizza logo" /> */}
      <Link to={ROUTES.home._path}>
        <img className="logo" src={LogoImage} alt=" pizza logo" />
      </Link>
      {hideIcon ? (
        <img className="logoMobile" src={LogoImage} alt=" pizza logo" />
      ) : (
        ""
      )}
    </div>
  );
};

export default TopBar;
