import { createSlice } from "@reduxjs/toolkit";
import geographyMSTCruds from "../../_apis/cruds/geographyMSTCrud";
const REDUCER_NAME = "geographyMST";

const TYPES = {
  GEOGRAPHY: "geography",
  GEOGRAPHY_BY_TYPE_CODE: "geographyByType",

};

export const geographyMSTReduxSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return { ...occ, [cur]: { loading: false, error: null, data: null } };
    }, {}),
  },
  reducers: {
    catchError: (state, action) => {
      state[action.payload.type].loading = false;
      state[action.payload.type].error = action.payload.error;
    },
    startCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = true;
    },
    stopCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
    },
    dataRecieved: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = action.payload.data;
    },
  },
});

const startCall = (type) => geographyMSTReduxSlice.actions.startCall({ type });
const stopCall = (type) => geographyMSTReduxSlice.actions.stopCall({ type });
const dataRecieved = (type, data) =>
geographyMSTReduxSlice.actions.dataRecieved({ type, data });
const catchError = (type, error) =>
geographyMSTReduxSlice.actions.catchError({ type, error });

export const geographyMSTActions = {
  // getAll: () => (dispatch) => {
  //   dispatch(startCall(TYPES.GEOGRAPHY));
  //   return geographyMSTCruds
  //     .getAllActive()
  //     .then((res) => {
  //       dispatch(dataRecieved(TYPES.GEOGRAPHY, res));
  //     })
  //     .catch((err) => {
  //       dispatch(catchError(TYPES.GEOGRAPHY, err));
  //     });
  // },
  getByTypeCode: (code) => (dispatch) => {
    dispatch(startCall(TYPES.GEOGRAPHY));
    return geographyMSTCruds
      .getByTypeCode(code)
      .then((res) => {
        dispatch(dataRecieved(TYPES.GEOGRAPHY, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.GEOGRAPHY, err));
      });
  },
};
