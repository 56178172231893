import { createSlice } from "@reduxjs/toolkit";
import contactusCruds from "../../_apis/cruds/contactusCrud";


const REDUCER_NAME = "contactUs";

const TYPES = {
    GET_CONTACTINFO : 'contactUs',
}

    export const contactUsSlice = createSlice({
        name: REDUCER_NAME,
        initialState: {
            ...Object.values(TYPES).reduce((occ, cur) => { return ({ ...occ, [cur]: { loading: false, error: null, data: null } }) }, {})

        },
        reducers: {
            catchError: (state, action) => {
                state[action.payload.type].loading = false;
                state[action.payload.type].error = action.payload.error;
            },
            startCall: (state, action) => {
                state[action.payload.type].error = null;
                state[action.payload.type].loading = true;
            },
            stopCall: (state, action) => {
                state[action.payload.type].error = null;
                state[action.payload.type].loading = false;
            },
            dataRecieved: (state, action) => {
                state[action.payload.type].error = null;
                state[action.payload.type].loading = false;
                state[action.payload.type].data = action.payload.data;
            },
        },
    })


    const startCall = (type) => contactUsSlice.actions.startCall({ type })
const stopCall = (type) => contactUsSlice.actions.stopCall({ type })
const dataRecieved = (type, data) => contactUsSlice.actions.dataRecieved({ type, data })
const catchError = (type, error) => contactUsSlice.actions.catchError({ type, error })


export const contactUsAction = {

    getContactInfo: () => dispatch => {
        dispatch(startCall(TYPES.GET_CONTACTINFO))
        return contactusCruds.getContactusInfo().then(res => {
            dispatch(dataRecieved(TYPES.GET_CONTACTINFO, res))
            stopCall(TYPES.GET_CONTACTINFO)
        }).catch(err => {
            dispatch(catchError(TYPES.GET_HISTORY, err))
            stopCall(TYPES.GET_CONTACTINFO)
        }) 
    }

}
