import { createSlice } from "@reduxjs/toolkit";
import homeControllerCruds from "../../_apis/cruds/homeControllerCrud";

const REDUCER_NAME = "home";

const TYPES = {
  MENU_ITEMS: "menuItems",
  OFFERS: "offers",
  OFFERINFO:"offerInfo",
  PUPULAR_PIZZA: "popularPizza",
  ORDER_TYPE: "orderType",
  OFFER_BY_ID:"offerById",
};

export const homeSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return { ...occ, [cur]: { loading: false, error: null, data: null } };
    }, {}),
  },
  reducers: {
    catchError: (state, action) => {
      state[action.payload.type].loading = false;
      state[action.payload.type].error = action.payload.error;
    },
    startCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = true;
    },
    stopCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
    },
    dataRecieved: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = action.payload.data;
    },
    clearMenuItems: (state) => {
      state[TYPES.MENU_ITEMS].data = null;
    },

  },
});

const startCall = (type) => homeSlice.actions.startCall({ type });
const stopCall = (type) => homeSlice.actions.stopCall({ type });
const dataRecieved = (type, data) =>
  homeSlice.actions.dataRecieved({ type, data });
const catchError = (type, error) =>
  homeSlice.actions.catchError({ type, error });

export const homeActions = {
  getMenuGroupItems: () => (dispatch) => {
    dispatch(startCall(TYPES.MENU_ITEMS));
    return homeControllerCruds
      .getMenu()
      .then((res) => {
        dispatch(dataRecieved(TYPES.MENU_ITEMS, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.MENU_ITEMS, err));
      });
  },
  getOffers: () => (dispatch) => {
    dispatch(startCall(TYPES.OFFERS));
    return homeControllerCruds
      .getOffer()
      .then((res) => {
        dispatch(dataRecieved(TYPES.OFFERS, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.OFFERS, err));
      });
  },
  getOffersPaginated: () => (dispatch) => {
    dispatch(startCall(TYPES.OFFERS));
    return homeControllerCruds
      .getOfferPaginated()
      .then((res) => {
        dispatch(dataRecieved(TYPES.OFFERS, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.OFFERS, err));
      });
  },
  getOffersInfo: () => (dispatch) => {
    dispatch(startCall(TYPES.OFFERINFO));
    return homeControllerCruds
      .getOfferInfo()
      .then((res) => {
        dispatch(dataRecieved(TYPES.OFFERINFO, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.OFFERINFO, err));
      });
  },
  getPopularPizza: () => (dispatch) => {
    dispatch(startCall(TYPES.PUPULAR_PIZZA));
    return homeControllerCruds
      .getPopularPizza()
      .then((res) => {
        dispatch(dataRecieved(TYPES.PUPULAR_PIZZA, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.PUPULAR_PIZZA, err));
      });
  },
  setOrderType: (orderType) => (dispatch) => {
    dispatch(dataRecieved(TYPES.ORDER_TYPE, orderType));
  },
  getByOfferId:(id)=>async (dispatch)=>{
    dispatch(startCall(TYPES.OFFER_BY_ID));
    return await homeControllerCruds.getOfferById({id})
    .then((res) => {
      dispatch(dataRecieved(TYPES.OFFER_BY_ID, res));
      return Promise.resolve(res)
    })
    .catch((err) => {
      dispatch(catchError(TYPES.OFFER_BY_ID, err));
      return Promise.reject(err)
    });
  }
};
