import Modal from "react-bootstrap/Modal";
import './closeDialog.scss'

const CustomDialog = ({
  isOpen,
  handleClose,
  dialogClassName,
  dialogTitle,
  dialogBody,
  dialogHeader,
  closeButton = true,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={() => handleClose()}
      dialogClassName={dialogClassName}
      aria-labelledby="example-custom-modal-styling-title"
    >
      {dialogHeader ? <Modal.Header style={{ padding: "0 5px" }} closeButton={closeButton}>
        <Modal.Title id="example-custom-modal-styling-title">
          {dialogTitle}
        </Modal.Title>
      </Modal.Header> : null}

      <Modal.Body  >{dialogBody}</Modal.Body>
    </Modal>
  );
};
export default CustomDialog;
