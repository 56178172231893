
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const cartTrackingMSTCruds = {

    all: () => {
        return axios.get(`/cartTrackingMST/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({ message: errorMessageFormatter(res.data), status: res.status }))
            .catch(err => Promise.reject({ message: err.message, status: err?.response?.status }))
    },


    /**
      * @param {{param:{},body:{active:undefined, cartManageTime:undefined, cartTrackingMSTId:undefined, cartTrackingPage:undefined, checkoutTime:undefined, createdBy:undefined, createdOn:undefined, initTime:undefined, lastPageTime:undefined, menuPageTime:undefined, modifiedBy:undefined, modifiedOn:undefined, orderMST:undefined, orderMSTId:undefined, paymentPageTime:undefined}}} data
      */
    create: (data) => {
        if (data.body.cartTrackingPage) {
            return axios.post(`/cartTrackingMST/create/`, data.body)
                .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({ message: errorMessageFormatter(res.data), status: res.status }))
                .catch(err => Promise.reject({ message: err.message, status: err?.response?.status }))
        }
    },

    delete: ({ id }) => {
        return axios.delete(`/cartTrackingMST/delete/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({ message: errorMessageFormatter(res.data), status: res.status }))
            .catch(err => Promise.reject({ message: err.message, status: err?.response?.status }))
    },

    byId: ({ id }) => {
        return axios.get(`/cartTrackingMST/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({ message: errorMessageFormatter(res.data), status: res.status }))
            .catch(err => Promise.reject({ message: err.message, status: err?.response?.status }))
    },


    /**
      * @param {{param:{},body:{active:undefined, cartManageTime:undefined, cartTrackingMSTId:undefined, cartTrackingPage:undefined, checkoutTime:undefined, createdBy:undefined, createdOn:undefined, initTime:undefined, lastPageTime:undefined, menuPageTime:undefined, modifiedBy:undefined, modifiedOn:undefined, orderMST:undefined, orderMSTId:undefined, paymentPageTime:undefined}}} data
      */
    manageCart: (data) => {
        return axios.post(`/cartTrackingMST/manageCart/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({ message: errorMessageFormatter(res.data), status: res.status }))
            .catch(err => Promise.reject({ message: err.message, status: err?.response?.status }))
    },

    findPaginated: () => {
        return axios.get(`/cartTrackingMST/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({ message: errorMessageFormatter(res.data), status: res.status }))
            .catch(err => Promise.reject({ message: err.message, status: err?.response?.status }))
    },

    update: (data) => {
        return axios.put(`/cartTrackingMST/update/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({ message: errorMessageFormatter(res.data), status: res.status }))
            .catch(err => Promise.reject({ message: err.message, status: err?.response?.status }))
    },

}

export default cartTrackingMSTCruds;
