import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { isBlank } from "../../helpers/Utils";
import "./userAccount.scss";
import CustomDialog from "../../componets/dialog/CustomDialog";
import Spinner from "../../componets/etc/Spinner";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../componets/FormComponent/TextInput";
import BlockButton from "../../componets/FormComponent/BlockButton";
import { authActions } from "../../_redux/files/authRedux";
const ForgotPassword = (props) => {
  const [resetPressed, setResetPressed] = useState(false);
  const handleClose = (e) => {
    props.closeDialog("forgot");
  };
  
  const openLogin = () => {
    props.closeDialog("forgot");
    props.openDialog("sign-in");
  };
  const handleResetPressed = () => {
    setResetPressed(true);
  };
  const handleResetPressedFalse = () => {
    setResetPressed(false)
  }
  return (
    <>
      {" "}
      <CustomDialog
        isOpen={props.open}
        handleClose={handleClose}
        dialogClassName="dialog"
        dialogTitle="Forgot Password"
        dialogHeader={false}
        dialogBody={
          <div className="container">
            <div
              onClick={handleClose}
              className="text-right"
              style={{
                position: "absolute",
                right: "-5px",
                top: "-10px",
                fontSize: "15pt",
                cursor: "pointer",
                padding: "10px",
                color: "#444",
              }}
            >
              <i class="fas fa-times-circle"></i>
            </div>
            {resetPressed ? (
              <ResetPasswordForm closeResetPassword={openLogin} resetPressed={handleResetPressedFalse} />
            ) : (
              <ForgotPasswordForm resetPressed={handleResetPressed} />
            )}
          </div>
        }
      />
    </>
  );
};
export default ForgotPassword;
const ForgotPasswordForm = ({ resetPressed }) => {
  const loading = useSelector((state) => state.auth.loading.forgotPassword);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors = {};
    if (isBlank(values.emailId)) {
      errors.emailId = "Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      emailId: "",
    },
    validate,
    onSubmit: (values) => {
      dispatch(authActions.sendOTP(values.emailId))
        .then((res) => {
          if (res?.message) {
            setError(res?.message);
          } else {
            resetPressed(true);
          }
        })
        .catch((error) => {
          setError(error.message);
        });
    },
  });
  
  return (
    <div className="row" >
      <div className="col-12">
        <form onSubmit={formik.handleSubmit}>
          <div className="row mt-4">
            <div className="col-12 col-offset-2 mt-2">
              <div className="text-center">
                <h4
                  class="card-title "
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Forget Password
                </h4>
              </div>
              {error && <div className="err-msg">{error}</div>}
              <div className="small mt-4">
                Confirm your email and we will send the instructions
              </div>
              <TextInput
                label="Enter Email ID"
                type="text"
                placeholder="Enter Email ID"
                name="emailId"
                change={formik.handleChange}
                value={formik.values.emailId}
                className={formik.errors.emailId && "input-error"}
              ></TextInput>
              {formik.touched.emailId && formik.errors.emailId ? (
                <div className="fv-plugins-message-container err-msg">
                  <div className="fv-help-block">{formik.errors.emailId}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-3 mb-5 forgetBtn">
            <BlockButton
              type="submit"
              onSubmit={formik.handleSubmit}
              text="Reset Password/ Send my Password"
            ></BlockButton>
            {loading && <Spinner></Spinner>}
          </div>
        </form>
      </div>
    </div>
  );
};
const ResetPasswordForm = ({ closeResetPassword, resetPressed }) => {

  // This is code is changeing state of reset password because of this user navigate to forgot email we dont want that now >> Dvs  
  // const setContainer = (e) => { 
  //   if(!document?.getElementById("forgetPasswordModule")?.contains(e.target)){
  //     resetPressed(false)
  //   }

  // }

  // useEffect(() => {
  // }, [window.addEventListener('click', setContainer)])

  const loading = useSelector((state) => state.auth.loading.forgotPassword);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors = {};
    if (isBlank(values.newPassword)) {
      errors.newPassword = "Required";
    }
    if (values.newPassword.length < 8 || values.newPassword.length > 16) {
      errors.newPassword = "Password must be  8 to 16 characters long.";
    }
    if (isBlank(values.username)) {
      errors.username = "Required";
    }
    if (isBlank(values.otp)) {
      errors.otp = "Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      username: "",
      otp: "",
    },
    validate,
    onSubmit: (values) => {
      dispatch(
        authActions.ResetPassword(
          values.newPassword,
          values.username,
          values.otp
        )
      )
        .then((res) => {
          if (res?.message) {
            setError(res.message);
          } else {
            closeResetPassword();
          }
        })
        .catch((err) => {
          setError(err.message);
        });
    },
  });
  return (
    <div className="row " id="forgetPasswordModule">
      <div className="col-12">
        {<div className="err-msg">{error}</div>}
        <form onSubmit={formik.handleSubmit}>
          <div className="small">OTP</div>
          <div className="row">
            <div className="col-12 col-offset-2">
              <TextInput
                label="Enter OTP"
                type="text"
                placeholder="Enter OTP"
                name="otp"
                change={formik.handleChange}
                value={formik.values.otp}
                className={formik.errors.otp && "input-error"}
              ></TextInput>
              {formik.touched.otp && formik.errors.otp ? (
                <div className="fv-plugins-message-container err-msg">
                  <div className="fv-help-block">{formik.errors.otp}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="small">User Name</div>
          <div className="row">
            <div className="col-12 col-offset-2">
              <TextInput
                label="Enter User Name"
                type="text"
                placeholder="Enter User Name"
                name="username"
                change={formik.handleChange}
                value={formik.values.username}
                className={formik.errors.username && "input-error"}
              ></TextInput>
              {formik.touched.username && formik.errors.username ? (
                <div className="fv-plugins-message-container err-msg">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="small">New Password</div>
          <div className="row">
            <div className="col-12 col-offset-2">
              <TextInput
                label="New Password"
                type="password"
                placeholder="New Password"
                name="newPassword"
                change={formik.handleChange}
                value={formik.values.newPassword}
                className={formik.errors.newPassword && "input-error"}
              ></TextInput>
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="fv-plugins-message-container err-msg">
                  <div className="fv-help-block">
                    {formik.errors.newPassword}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <BlockButton
              type="submit"
              onSubmit={formik.handleSubmit}
              text="Reset Password"
            ></BlockButton>
            {loading && <Spinner></Spinner>}
          </div>
        </form>
      </div>
    </div>
  );
};
