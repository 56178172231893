// import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./files/authRedux";
import { homeSlice } from "./files/homeRedux";
import { menuPageSlice } from "./files/menuPageRedux";
import { outletShiftDTLSlice } from "./files/outletShiftRedux";
import { outletMSTReduxSlice } from './files/outletMSTRedux'
import { geographyMSTReduxSlice } from './files/geographyMSTRedux'
import { geographyTypeMSTReduxSlice } from './files/geographyTypeMSTRedux'
import { cartSlice } from "./files/cartRedux";
import { combineReducers } from "redux";
import { orderMSTReduxSlice } from './files/orderMSTRedux'
import { customizeSlice } from "./files/customizeRedux";
import { hnhSlice } from "./files/hnhRedux";
import { buildYourOwnSlice } from "./files/buildYourOwnRedux";
import {customerMSTSlice} from "./files/customerRedux"
import {feedbackMSTReduxSlice} from './files/feedBackRedux'
import {gogleMapSlice} from './files/GoogleMapRedux'
import {checkoutSlice} from './files/checkOutRedux'
import {paymentModeMSTSlice} from './files/paymentModeRedux'
import {deliveryChargesDTLReduxSlice} from './files/deliveryChargeRedux'
import { contactUsSlice } from "./files/contactusRedux";
import { selectOutletSlice } from "./files/SelectedOutletRedux";
import { termsConditionMSTSlice } from "./files/tacRedux";
const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [homeSlice.name]: homeSlice.reducer,
  [menuPageSlice.name]: menuPageSlice.reducer,
  [outletShiftDTLSlice.name]: outletShiftDTLSlice.reducer,
  [outletMSTReduxSlice.name]: outletMSTReduxSlice.reducer,
  [geographyMSTReduxSlice.name]: geographyMSTReduxSlice.reducer,
  [geographyTypeMSTReduxSlice.name]: geographyTypeMSTReduxSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [orderMSTReduxSlice.name]: orderMSTReduxSlice.reducer,
  [customizeSlice.name]: customizeSlice.reducer,
  [hnhSlice.name]: hnhSlice.reducer,
  [buildYourOwnSlice.name]: buildYourOwnSlice.reducer,
  [customerMSTSlice.name]:customerMSTSlice.reducer,
  [feedbackMSTReduxSlice.name]:feedbackMSTReduxSlice.reducer,
  gogleMap: gogleMapSlice.reducer,
  [checkoutSlice.name]:checkoutSlice.reducer,
  [paymentModeMSTSlice.name]:paymentModeMSTSlice.reducer,
  [deliveryChargesDTLReduxSlice.name]:deliveryChargesDTLReduxSlice.reducer,
  [contactUsSlice.name]:contactUsSlice.reducer,
  [selectOutletSlice.name]: selectOutletSlice.reducer,
  [termsConditionMSTSlice.name]: termsConditionMSTSlice.reducer

});

export default rootReducer;
