import { createSlice } from "@reduxjs/toolkit";
import itemMSTCrud from "../../_apis/cruds/itemMSTCrud";

const REDUCER_NAME = "customize"

export const customizeSlice = createSlice({
    name: REDUCER_NAME,
    initialState: {
        error: null,
        item: null,
    },
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload;
        },
        loadItem: (state, action) => {
            state.item = action.payload
        },
        setItemValue: (state, action) => {
            if (state.item)
                Object.keys(action.payload).forEach(k => {
                    state.item[k] = action.payload[k]
                })
        }
    },
})


export const customizeActions = {
    /**
     * @param {ITEM_OBJ} obj
     */
    loadItem: (obj) => dispatch => {
        dispatch(customizeSlice.actions.loadItem(obj))
    },

    setItemValue: (item) => dispatch => {
        dispatch(customizeSlice.actions.setItemValue(item))
    }
}