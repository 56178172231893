const ROUTES = {
    home: {
        _path: '/',
    },
    menu: {
        _path: '/menu',
        _pathWithParams: '/menu/:group?/:subpage?',
        _checkoutPath: '/menu/check-out/check-out',
        _pathWithParamsG1: '/menu/G1/default',
    },
    offers: {
        _path: '/offers'
    },
    userAccount: {
        _path: '/user-account',
        signIn: {
            _path: '/user/sign-in'
        },
        manageAccount: {
            _path: '/user-account/manage-account',
        },
        resetPassword: {
            _path: '/user-account/reset-password',
        },
        orderHistory: {
            _path: '/user-account/order-history'
        },
        trackOrder: {
            _path: '/user-account/track-order'
        },
        favourite: {
            _path: '/user-account/favourite'
        },
    },
    orderType: {
        _path: '/full-page/order-type'
    },
    about: {
        _path: '/contact-us'
    },
    feedback: {
        _path: '/feedback'
    },
    payment: {
        _path: "/menu/payment/payment",
        _pathWithParams: '/menu/payment/payment/:result?',
    },
    orderPlaced: {
        _path: "/order-placed",
    },
    tac: {
        _path: "/terms-condition",
    },
    privacyPolicy: {
        _path: "/privacy-policy",
    }
}

export default ROUTES;