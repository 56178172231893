import React from "react";
import "./formComponent.scss";

const BlockButton = ({
  type = "submit",
  text = "",
  click,
  onSubmit,
  check,
  className = "block-button btn btn-dark",
  style,
  priceOption = undefined,
}) => {
  return (
    <button
      type={type}
      onClick={click}
      onSubmit={onSubmit}
      className={`${className} ${
        priceOption && "d-flex justify-content-between align-items-center"
      } `}
      style={style}
      disabled={check}
    >
      {priceOption && <span>{priceOption}</span>}
      <span>{text}</span>
    </button>
  );
};

export default BlockButton;
