import { createSlice } from "@reduxjs/toolkit";


const REDUCER_NAME = "selectOutlet";


const initialState = {  
   orderFromReorder: false,
   selectOutlet: [],
  };


  export const selectOutletSlice = createSlice({
    name: REDUCER_NAME,
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
      startCall: (state, action) => {
        state.loading = true;
        state.error = null;
      },
      setFromSessionExpire: (state, action) => {
        state.fromSessionExpire = action.payload;
      },
      setSelectOutlet: (state, action) => {
        state.selectOutlet.push({
          ...action.payload,
        });
      },
  }
  })