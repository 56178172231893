import { createSlice } from "@reduxjs/toolkit";
import outletShiftDTLCrud from "../../_apis/cruds/outletShiftDTLCrud";
const REDUCER_NAME = "outletShift";

const TYPES = {
  OUTLET_SHIFT_DTL: "outletShiftDtl",
};

export const outletShiftDTLSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return { ...occ, [cur]: { loading: false, error: null, data: null } };
    }, {}),
  },
  reducers: {
    catchError: (state, action) => {
      state[action.payload.type].loading = false;
      state[action.payload.type].error = action.payload.error;
    },
    startCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = true;
    },
    stopCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
    },
    dataRecieved: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = action.payload.data;
    },
    clearShift: (state) => {
      state[TYPES.OUTLET_SHIFT_DTL].data = null;
    },
  },
});

const startCall = (type) => outletShiftDTLSlice.actions.startCall({ type });
const stopCall = (type) => outletShiftDTLSlice.actions.stopCall({ type });
const dataRecieved = (type, data) =>
  outletShiftDTLSlice.actions.dataRecieved({ type, data });
const catchError = (type, error) =>
  outletShiftDTLSlice.actions.catchError({ type, error });

export const outletShiftActions = {
  getOutletShiftDTL: () => (dispatch) => {
    dispatch(startCall(TYPES.OUTLET_SHIFT_DTL));
    return outletShiftDTLCrud
      .getShiftByOutletSystem()
      .then((res) => {
        let regular = res.regular;
        let special = res.special;
        special = special?.map((x) =>
          x.day === 7 ? { ...x, day: 0 } : { ...x }
        );
        regular = regular.map((x) =>
          x.day === 7 ? { ...x, day: 0 } : { ...x }
        );
        let obj = {
          ...res,
          regular,
          special: special ? special : [],
        };

        dispatch(dataRecieved(TYPES.OUTLET_SHIFT_DTL, obj));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.OUTLET_SHIFT_DTL, err));
      });
  },
};
