import { createSlice } from "@reduxjs/toolkit";

const REDUCER_NAME = "hnh";

export const hnhSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    error: null,
    left: {},
    right: {},
    qty: 1,
    size: null,
    base: null,
    localId: null,
    isHnh: true,
    localIdForEdit: null,
    leftSauce: null,
    rightSauce: null,
    editBtnClick: null,
    freshOrder: true,
  },
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload;
    },
    loadItem: (state, action) => {
      
      state.left = action.payload.left;
      state.right = action.payload.right;
      state.qty = action.payload.qty;
      state.localId = action.payload.localId;
      state.size = action.payload.size;
      state.base = action.payload.base;
      state.sauce = action.payload.sauce;
    },
    setItemValue: (state, action) => {
      
      Object.keys(action.payload).forEach((leftright) => {
        
        Object.keys(action.payload[leftright]).forEach((p) => {
          state[leftright][p] = action.payload[leftright][p];
        });
      });
    },
    setLocalId: (state, action) => {
      state.localIdForEdit = action.payload;
    },
    setFreshOrder: (state, action) => {
      state.freshOrder = action.payload
    },
    setEditBtnClick: (state, action) => {
      state.editBtnClick = action.payload;
    },
    setQty: (state, action) => {
      state.qty = action.payload;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    },
    setBase: (state, action) => {
      state.base = action.payload;
    },
    setLeftSauce: (state, action) => {
      
      state.leftSauce = action.payload;
    },
    setRightSauce: (state, action) => {
      state.rightSauce = action.payload;
    },
  },
});

export const hnhActions = {
  /**
   * @param {ITEM_OBJ} obj
   */
  loadItem: (obj) => (dispatch) => {
    dispatch(hnhSlice.actions.loadItem(obj));
  },

  setItemValue: (item) => (dispatch) => {
    dispatch(hnhSlice.actions.setItemValue(item));
  },

  setQty: (qty) => (dispatch) => {
    dispatch(hnhSlice.actions.setQty(qty));
  },
  setSize: (Size) => (dispatch) => {
    dispatch(hnhSlice.actions.setSize(Size));
  },
  setBase: (Base) => (dispatch) => {
    dispatch(hnhSlice.actions.setBase(Base));
  },
};
