
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const contactusCruds = {


   /**
     * @param {{param:{},body:{contactUsEmail:undefined, contactUsId:undefined, contactUsMessage:undefined, contactUsMobile:undefined, contactUsName:undefined, customerMST:undefined, customerMSTId:undefined}}} data
     */
    create : (data) => {
        return axios.post(`/contactus/create/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
    getContactusInfo : () => {
        return axios.get('/outletMST/contact-us')
        .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
        .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    }
        
}

export default contactusCruds;
    