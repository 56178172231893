import React from "react";
import "./footer.scss";
import ROUTES from "../../constants/ROUTES";
import { Link, matchPath, useHistory, useLocation } from "react-router-dom";
const Footer = () => {

  let facebook = process.env.REACT_APP_FACEBOOK;
  let instagram  = process.env.REACT_APP_INSTAGRAM;
  let linkedin  = process.env.REACT_APP_LINKEDIN;
  let twitter  = process.env.REACT_APP_TWITTER;

  return (
    <div className="footer row mt-5 no-gutters ">
      <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 footer-menu">
        <div className="row social-page-row">
          <div className="col-md-9 col-sm-8 col-xs-5 pages">
            <ul>
              <li>
                <Link  className="nav-link text-white" to={`${ROUTES.menu._path}/G2/default`}>
                  Menu
                </Link>
              </li>
              <li>
                <Link className="nav-link text-white" to={ROUTES.offers._path}>
                  Offer
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link text-white"
                  to={ROUTES.feedback._path}
                >
                  Feedback
                </Link>
              </li>
            </ul>
          </div>
        
          <div className="col-md-3 col-sm-4 col-xs-5 social-icons"> {
             facebook !== undefined &&  facebook !== "" ?     <a href={facebook} target="_blank" rel="noreferrer" >
            <button className="social-btn">
              <i className="fab fa-facebook-f"></i>
            </button>
          </a> : null
          }
          
          {  
          linkedin !== undefined &&  linkedin !== "" ? 
                  <a href="#" target="_blank">
                  <button className="social-btn">
                    <i className="fab fa-linkedin-in"></i>
                  </button>
                  </a> : ""
          }
           
           {
          twitter !== undefined && twitter !== ""  ?
             <a href={twitter} target="_blank" rel="noreferrer">
             <button className="social-btn">
               <i className="fab fa-twitter"></i>
             </button>
           </a>
            : ""
           }
            {
              instagram !== undefined && instagram !== ""   ?  <a href={instagram} target="_blank" rel="noreferrer" >
              <button className="social-btn">
                <i className="fab fa-instagram"></i>
              </button>
            </a> : ""
            }
           
          </div>
        </div>
        <div className="row menu-row">
          <ul>
            <li>
              <Link className="nav-link text-white" to={ROUTES.home._path}>
                Home
              </Link>
            </li>
            {/* <li>About us</li>
                        <li>Store Locator</li> */}
            <li>
              <Link className="nav-link text-white" to={ROUTES.about._path}>
                Contact us
              </Link>
            </li>
            {/* <li>Sitemap</li>
                        <li>Privacy Policy</li> */}
            <li>
              <Link className="nav-link text-white" to={ROUTES.tac._path}>
              Terms & Condition
              </Link>
            </li>
            <li>
              <Link className="nav-link text-white" to={ROUTES.privacyPolicy._path}>
             Privacy Policy
              </Link>
            </li>
          </ul>
        </div>      
      </div>

      <div className="col-12 footer-of-footer">
        <div className="row no-gutters">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            &copy; 2021 All rights reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
