import { createSlice } from "@reduxjs/toolkit";
import itemMSTCrud from "../../_apis/cruds/itemMSTCrud";
import { sortObjectByValue } from "../../helpers/Utils";
const REDUCER_NAME = "menuPage";

const TYPES = {
  ALL_ITEMS: "allItems",
  //BY_GROUPCODE:'byGroupCode'
};

export const menuPageSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return {
        ...occ,
        [cur]: { loading: false, error: null, data: null, apiArgsQue: [] },
      };
    }, {}),
  },
  reducers: {
    catchError: (state, action) => {
      state[action.payload.type].loading = false;
      state[action.payload.type].error = action.payload.error;
    },
    startCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = true;
    },
    stopCall: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
    },
    dataRecieved: (state, action) => {
      state[action.payload.type].error = null;
      state[action.payload.type].loading = false;
      state[action.payload.type].data = action.payload.data;
    },
    clearAllItems: (state) => {
      state[TYPES.ALL_ITEMS].data = null;
    },
    addRecievedData: (state, action) => {
      state[action.payload.type].error = null;
      // state[action.payload.type].loading = false;
      let keys = Object.keys(action.payload.data)[0];
      if (state[action.payload.type].data) {
        state[action.payload.type].data = sortObjectByValue({
          ...state[action.payload.type].data,
          [keys]: action.payload.data[keys],
        });
      } else {
        state[action.payload.type].data = action.payload.data;
      }
      state[action.payload.type].apiArgsQue = state[
        action.payload.type
      ].apiArgsQue.filter((n) => n !== keys);
    },
    addSilentlyRecievedData: (state, action) => {
      let keys = Object.keys(action.payload.data)[0];
      if (state[action.payload.type].data) {
        state[action.payload.type].data = sortObjectByValue({
          ...state[action.payload.type].data,
          [keys]: action.payload.data[keys],
        });
      } else {
        state[action.payload.type].data = action.payload.data;
      }
      state[action.payload.type].apiArgsQue = state[
        action.payload.type
      ].apiArgsQue.filter((n) => n !== keys);
    },
    addParamInQue: (state, action) => {
      if (
        !state[action.payload.type].apiArgsQue.includes(action.payload.data)
      ) {
        state[action.payload.type].apiArgsQue.push(action.payload.data);
      }
    },
  },
  
});

const startCall = (type) => menuPageSlice.actions.startCall({ type });
const stopCall = (type) => menuPageSlice.actions.stopCall({ type });
const dataRecieved = (type, data) =>
  menuPageSlice.actions.dataRecieved({ type, data });
const addRecievedData = (type, data) =>
  menuPageSlice.actions.addRecievedData({ type, data });
const addSilentlyRecievedData = (type, data) =>
  menuPageSlice.actions.addRecievedData({ type, data });
const catchError = (type, error) =>
  menuPageSlice.actions.catchError({ type, error });
const addParamInQue = (type, data) =>
  menuPageSlice.actions.addParamInQue({ type, data });

export const menuPageActions = {
  getAllItems: () => (dispatch) => {
    dispatch(startCall(TYPES.ALL_ITEMS));
    return itemMSTCrud
      .getItemsByGroupCodes({
        body: {
          groupCodes: [],
          outletCode: "RJT01",
          systemCode: "PIZZAPORTAL",
        },
      })
      .then((res) => {
        dispatch(dataRecieved(TYPES.ALL_ITEMS, res));
        dispatch(stopCall(TYPES.ALL_ITEMS));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.ALL_ITEMS, err));
        dispatch(stopCall(TYPES.ALL_ITEMS));
      });
  },
  getItemsByGroupCode: (groupCode) => (dispatch, getState) => {
    if (!getState().menuPage.allItems.apiArgsQue.includes(groupCode)) {
      dispatch(startCall(TYPES.ALL_ITEMS));
      dispatch(addParamInQue(TYPES.ALL_ITEMS, groupCode));
      let m = JSON.parse(sessionStorage.getItem("apiCall"));

      if (m) {
        sessionStorage.setItem("apiCall", m + 1);
      } else {
        sessionStorage.setItem("apiCall", 1);
      }

      return itemMSTCrud
        .getItemsByGroupCodes({
          body: {
            groupCodes: [groupCode],
            outletCode: "RJT01",
            systemCode: "PIZZAPORTAL",
          },
        })
        .then((res) => {
          dispatch(addRecievedData(TYPES.ALL_ITEMS, res));
          
          let n = JSON.parse(sessionStorage.getItem("apiRes"));
          if (n) {
            sessionStorage.setItem("apiRes", n + 1);
          } else {
            sessionStorage.setItem("apiRes", 1);
          }
          dispatch(stopCall(TYPES.ALL_ITEMS));
          let j = JSON.parse(sessionStorage.getItem("apiRes"));
          let m = JSON.parse(sessionStorage.getItem("apiCall"));
          if (m === j) {
            dispatch(stopCall(TYPES.ALL_ITEMS));
            sessionStorage.removeItem("apiCall");
            sessionStorage.removeItem("apiRes");
          } else {
            if (groupCode === "payment" || groupCode === "check-out") {
              dispatch(stopCall(TYPES.ALL_ITEMS));
              sessionStorage.removeItem("apiCall");
              sessionStorage.removeItem("apiRes");
            }
          }
        })
        .catch((err) => {
          dispatch(catchError(TYPES.ALL_ITEMS, err));
          dispatch(stopCall(TYPES.ALL_ITEMS));
          sessionStorage.removeItem("apiCall");
          sessionStorage.removeItem("apiRes");
        });
    } else {
      dispatch(stopCall(TYPES.ALL_ITEMS));
      sessionStorage.removeItem("apiCall");
      sessionStorage.removeItem("apiRes");
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  },
  getItemsByGroupCodeSilently: (groupCode) => (dispatch, getState) => {
    if (!getState().menuPage.allItems.apiArgsQue.includes(groupCode)) {
      dispatch(addParamInQue(TYPES.ALL_ITEMS, groupCode));

      return itemMSTCrud
        .getItemsByGroupCodes({
          body: {
            groupCodes: [groupCode],
            outletCode: "RJT01",
            systemCode: "PIZZAPORTAL",
          },
        })
        .then((res) => {
          dispatch(addSilentlyRecievedData(TYPES.ALL_ITEMS, res));
          dispatch(stopCall(TYPES.ALL_ITEMS));
        })
        .catch((err) => {
          dispatch(catchError(TYPES.ALL_ITEMS, err));
          dispatch(stopCall(TYPES.ALL_ITEMS));
        });
    } else {
      dispatch(stopCall(TYPES.ALL_ITEMS));
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  },
};
