import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Profile2 from "../../assets/images/Profile2.png";
import Favorite from "../../assets/images/Favorite.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import { authActions } from "../../_redux/files/authRedux";
import { Link, useLocation } from "react-router-dom";
import ROUTES from "../../constants/ROUTES";
import { useHistory } from "react-router-dom";
import "./userAccount.scss";
import { cartActions } from "../../_redux/files/cartRedux";
import Swal from "sweetalert2";
const UserAccountPopUp = () => {
  const [isOpen, setIsOpen] = useState(false);
  let ref = React.useRef(null);
  let accountRef = React.useRef(null);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);
  const burgerButtonOpen = useSelector((state) => state.cart.activeNavbar)
  let history = useHistory();
  const data = useSelector((state) => state.auth.authUser);
  const custMST = useSelector((state) => state.auth.authCust);
  const loading = useSelector(
    (state) => state.auth.loading.loginByUserPassword
  );
  const { items } = useSelector((s) => s.cart);

  const logOut = async () => {
    if (items.length) {
      Swal.fire({
        title: "You will lose order",
        // text: "This item is not available in Offer.Do you want to add it separate in cart?.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Logout",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(cartActions.destroyOrderAndClear());
          dispatch(authActions.logOut());
          history.push(ROUTES.home._path);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          closePopover();
        }
      });
    } else {
      dispatch(authActions.logOut());
      history.push(ROUTES.home._path);
    }
  };
  const closePopover = () => {
    accountRef.click();
  };

  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      closePopover();
      setIsOpen(false);
    }
  }, [location, burgerButtonOpen]);

  return (
    <>
      <OverlayTrigger
        ref={(r) => (ref = r)}
        trigger="click"
        key={"bottom"}
        placement={"bottom"}
        overlay={
          <Popover
            id={`popover-positioned-bottom`}
            className="user-account-popup"
          >
            <Popover.Title as="h3" className="user-account-popover-title">
              <div className="row no-gutters">
                <div className="col-3">
                  <img src={Profile2} alt="profile" />
                </div>
                <div
                  className="col-9"
                  style={{
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <div
                    className="text-right"
                    style={{ fontSize: "12pt", whiteSpace: "nowrap" }}
                  >
                    {custMST?.primaryContact}
                  </div>
                  <div
                    className="text-right"
                    style={{
                      fontSize: "12pt",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {custMST?.primaryEmail}
                  </div>
                </div>
              </div>
            </Popover.Title>
            <Popover.Content className="user-account-popover-content">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <img src={Favorite} alt="Manage Account" />
                  <Link
                    className="nav-link link-text"
                    to={ROUTES.userAccount.manageAccount._path}
                    onClick={() => closePopover}
                  >
                    Manage Account
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <img src={Favorite} alt="Reset Password" />
                  <Link
                    className="nav-link link-text"
                    to={ROUTES.userAccount.resetPassword._path}
                    onClick={() => closePopover}
                  >
                    Reset Password
                  </Link>
                </div>
              </div>
                      {/* commented because of oreder histoy api  */}
                <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <img src={Favorite} alt="Order History" />
                  <Link
                    className="nav-link link-text"
                    to={ROUTES.userAccount.orderHistory._path}
                    onClick={() => closePopover}
                  >
                    Order History
                  </Link>
                </div>
              </div> 
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <img src={Favorite} alt="Track Order" />
                  <Link
                    className="nav-link link-text"
                    to={ROUTES.userAccount.trackOrder._path}
                    onClick={() => closePopover}
                  >
                    Track Order
                  </Link>
                </div>
              </div>
              {/* Removed Favourite because it's not working */}
              {/* <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <img src={Favorite} alt="Favorite" />
                  <Link
                    className="nav-link link-text"
                    to={ROUTES.userAccount.favourite._path}
                    onClick={() => closePopover}
                  >
                    Favourite
                  </Link>
                </div>
              </div> */}
              <div className="row">
                <div className="col-1"></div>

                <div
                  style={{ cursor: "pointer" }}
                  className="col-10 link-text"
                  onClick={() => logOut()}
                >
                  <img src={Favorite} alt="Log Out" />
                  <a style={{ padding: ".5rem 1rem" }}> Log Out</a>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <Button
          variant="secondary"
          ref={(r) => (accountRef = r)}
          onClick={() => {
            if (isOpen) {
              setIsOpen(false);
            } else {
              setIsOpen(true);
            }
          }}
          className="user-btn"
        >
          <i className="fas fa-user-circle"></i>
        </Button>
      </OverlayTrigger>
    </>
  );
};
export default UserAccountPopUp;
