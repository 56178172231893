import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../_redux/files/authRedux";
import SplashScreen from "../splashScreen/SplashScreen";
import { contactUsAction } from "../../_redux/files/contactusRedux";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { outletMSTActions } from "../../_redux/files/outletMSTRedux";
import { deliveryChargesDTLActions } from "../../_redux/files/deliveryChargeRedux";
import { orderMSTActions } from "../../_redux/files/orderMSTRedux";
import ROUTES from "../../constants/ROUTES";

const AuthenticatedPage = ({ children }) => {
  const disptach = useDispatch();
  const { authUser, isAuthenticating, error,outletLoading  } = useSelector(({ auth }) => ({
    authUser: auth.authUser,
    isAuthenticating: auth.loading.authenticating,
    error: auth.error.authenticating,
    outletLoading:auth.loading.signinOutlet
  }));


  // const selectedOutlet = true;




  const location = useLocation();


  let outletSelectionStorage = JSON.parse(localStorage.getItem("selected-outlet"));
  const isPolicyPage = location.pathname === ROUTES.tac._path || location.pathname === ROUTES.privacyPolicy._path;


  useEffect(() => {
    if(outletSelectionStorage) {

      
      // setting all the data in Redux 
      disptach(outletMSTActions.setCurrentOutlet(outletSelectionStorage));
      // disptach(orderMSTActions.setCustomerAddress(storedAddress));
    }
    if (!authUser) {
      disptach(authActions.authenticateUser(outletSelectionStorage?.[0], true))
        .then(() => {
          disptach(deliveryChargesDTLActions.fetchPolygon());
          disptach(outletMSTActions.getAllOutlet());
        })
      // disptach(contactUsAction.getContactInfo())
    }
  }, []);


  // if (selectedOutlet?.data) {
  // } else {
  //   if (!storedOutlet) {
  //     return <Redirect to="/outlet-selection" />;
  //   } else {
  //     if (!authUser) {
  //       <Redirect to="/outlet-selection" />;
  //     }
  //     // disptach(outletMSTActions.setCurrentOutlet(storedOutlet));
  //   }
  // }

  // If we don't have outletSelected stored in local then redirect to outlet-selection
  if (!isPolicyPage && !outletSelectionStorage) {
    return <Redirect to="/outlet-selection" />;
  }

  return isAuthenticating || error ? (
      <SplashScreen
      loading={isAuthenticating}
      text={
        error ? (
          <small className="text-danger">
            {error.message}, Please refresh.
          </small>
        ) : (
          "Please Wait..."
        )
      }
    />
  ) : !isAuthenticating && error ? (
    <span className="text-danger">{error.message}</span>
  ) : !authUser && isAuthenticating ? (
    <span className="text-danger">
      User not found something went wrong, refresh page
    </span>
  ) : (
    <>{children}</>
  );
};

export default AuthenticatedPage;
