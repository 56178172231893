export const TOKEN_NAME = "pizza-web-user-token";

const setupAxios = (axios) => {
  var isAbsoluteURLRegex = /^(?:\w+:)\/\//;

  axios.interceptors.request.use(function (config) {
    if (!isAbsoluteURLRegex.test(config.url)) {
      config.url = process.env.REACT_APP_API_URL + config.url;
    }
    if (config.url !== "https://geolocation-db.com/json/") {
      const token = localStorage.getItem(TOKEN_NAME);
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    config.headers["Content-Type"] = "application/json";
    return config;
  });
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.path !==
          process.env.REACT_APP_API_URL + "/authenticate"
      ) {
        localStorage.removeItem(TOKEN_NAME);
      }
      return Promise.reject(error);
    }
  );
};

export default setupAxios;
