import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomDialog from "../../componets/dialog/CustomDialog";
import TextInput from "../../componets/FormComponent/TextInput";
import BlockButton from "../../componets/FormComponent/BlockButton";
import Checkbox from "../../componets/FormComponent/CheckBox";
import userMSTCruds from "../../_apis/cruds/userMSTCrud";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import SignIn from "./SignIn";
import "./userAccount.scss";
import fb from "../../assets/images/fb.png";
import google from "../../assets/images/Google.png";
import { isBlank, isValidEmail } from "../../helpers/Utils";
import { authActions } from "../../_redux/files/authRedux";
import Spinner from "../../componets/etc/Spinner";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
const SignUp = (props) => {
  const [signInOpen, setSignInOpen] = useState(false);
  const [remember, setRemember] = useState(true);
  const loading = useSelector((state) => state.auth.loading.newUser);
  const loadingBySocial = useSelector((state) => state.auth.loading.loginBySocial);
  const error = useSelector((state) => state.auth.error.newUser);
  const socialerror = useSelector((state) => state.auth.error.loginBySocial);
  const data = useSelector((state) => state.auth.data.newUser);
  const socialdata = useSelector((state) => state.auth.data.loginBySocial);
  const currentSelectedOutlet = useSelector((state) => state.outletMST.currentOutlet?.data);

  const dispatch = useDispatch();
  const validate = (values) => {
    const errors = {};
    if (isBlank(values.customerFirstName)) {
      errors.customerFirstName = "Required.";
    }
     else if(!(values.customerFirstName).match(/^[a-zA-Z]+$/)){
      errors.customerFirstName = "Only alphabets are allowed";
    }
   if(!(values.customerLastName).match(/^[a-zA-Z]+$/)){
      errors.customerLastName = "Only alphabets are allowed";
    }
    if (isBlank(values.email)) {
      errors.email = "Required.";
    }
    if (!isValidEmail(values.email)) {
      errors.email = "Please enter valid Email Address.ex abc@test.com";
    }
    if (!isBlank(values.mobile)) {
      if (values.mobile.length !== 10) {
        errors.mobile = "Please enter valid mobile Number.";
      }
      if(!values.mobile.match(/^[0-9]+$/)){
        errors.mobile = "Only numbers are allowed";
      }
    }
    if (values.password.length < 8 || values.password.length > 16) {
      errors.password = "Password must be  8 to 16 characters long.";
    }
    if (values.password !== values.confirmPassword) {
      errors.password = "Password and Confirm Password must be same.";
    }
    return errors;
  };
  const closeSignInDialog = () => {
    setSignInOpen(false);
  };

  let socialSign = "Sign Up";
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerFirstName: props.signUpdata? props.signUpdata?.customerFirstName  :"",
      customerLastName: "",
      email: props.signUpdata ? props.signUpdata?.primaryEmail :"",
      mobile: props.signUpdata ? props.signUpdata?.primaryContact :"",
      password: "",
      confirmPassword: "",
      emailSubscription: true,
      smsSubscription: true,
      system: "PIZZAPORTAL",
      signUp:true,
    },
    validate,
    onSubmit: (values) => {
      dispatch(authActions.createUser(values)).then((res) => {
        if(res){
        let currentOutlet =   JSON.parse(localStorage.getItem("selected-outlet"))            
        if(currentSelectedOutlet) {
          dispatch(authActions.authenticateUser(currentOutlet?.[0], true))
        }
      }
      }).catch((err) => {
        console.log(err)
      })
    },
  });

  const handleRemember = (event) => {
    setRemember(event.target.checked);
  };
  useEffect(() => {
    if (data !== null) {
      props.closeDialog("sign-up");
      // props.openDialog("sign-in");
      formik.resetForm();
    }
    if (socialdata!== null) {
      props.closeDialog("sign-up");
      // props.openDialog("sign-in");
      formik.resetForm();
    }
  }, [data,socialdata]);
  const openSignIn = (e) => {
    props.closeDialog("sign-up");
    props.openDialog("sign-in");
    formik.resetForm();
  };
  const handleClose = (e) => {
    props.closeDialog("sign-up");
    formik.resetForm();
  };
  const responseFacebook = (response) => {
    let data= {
      firstName: response?.name,
      lastName: '',
      type : response?.graphDomain,
      email: response?.email,
      mobileNo: 0,
      socialId:response?.id,
    }
    dispatch(authActions.loginByScoial(data))
  }
  const responseGoogle = (response) => {
    let data= {
      firstName: response?.profileObj.givenName,
      lastName: response?.profileObj.familyName,
      type : response?.tokenObj.idpId,
      email: response?.profileObj.email,
      mobileNo: 0,
      socialId:response?.profileObj.googleId,
    }
    dispatch(authActions.loginByScoial(data))
  }

  return (
    <>
      <CustomDialog
        isOpen={props.open}
        handleClose={handleClose}
        dialogClassName="dialog custom-dialog"
        dialogTitle="Sign Up"
        dialogHeader={false}
        dialogBody={
          <div className="container signin-dialog">
            <div
              onClick={handleClose}
              className="text-right"
              style={{
                position: "absolute",
                right: "-5px",
                top: "-10px",
                fontSize: "15pt",
                cursor: "pointer",
                padding: "10px",
                color: "#444",
              }}
            >
              <i class="fas fa-times-circle"></i>
            </div>
            <div className="row">
              <div className="col-12 text-center signin-title"> Sign Up</div>
            </div>
            <div className="row">
              <div className="col-12 text-center signin-sub-title">
                To grab fanstastic deals
              </div>
            </div>

            <div className="row mt-2 ">
              <div className="col-xs-12 offset-xs-0 col-md-12">
                <form onSubmit={formik.handleSubmit}>
                  {/* <div className="small"> {socialSign} with Social Network</div> */}
                  {/* <div className="row my-3 ">
                    <div className="col-md-6 col-xs-12 facebookContainer">
                    <FacebookLogin
                    appId="311328580730028"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    textButton="Sign Up with facebook"
                    icon="fa-facebook"
                 />
                    </div>
                    <div className="col-md-6 col-xs-12 googlecontainer">
                    <GoogleLogin
                     clientId="939662684222-i95ljsh25nkln89ksf4nqb2c4ej04tj6.apps.googleusercontent.com"
                     buttonText=""
                     cookiePolicy={'single_host_origin'}
                     onSuccess={responseGoogle}
                     onFailure={responseGoogle}>           
                     <div className="social-box gog-section">
                        <div className="social-icon">
                          <i className="fab fa-google"></i>
                        </div>
                        <div
                          className="social-text hideText"
                          style={{ fontSize: "15px" }}
                        >
                          <div className="social-text">Sign Up with Google</div>
                        </div>                      
                      </div>
                      </GoogleLogin>                     
                    </div>
                  </div> */}
                  {/* <div className="small center-text my-2">OR</div> */}
                  {error ? (
                    <div className="fv-plugins-message-container err-msg">
                      {error}
                    </div>
                  ) : null}
                  {socialerror ? (
                    <div className="fv-plugins-message-container err-msg">
                      {socialerror}
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-12 col-offset-2 singUpInps">
                      <TextInput
                        label="First Name"
                        type="text"
                        placeholder="First Name"
                        name="customerFirstName"
                        change={formik.handleChange}
                        value={formik.values.customerFirstName}
                        className={
                          formik.errors.customerFirstName && "input-error"
                        }
                      ></TextInput>
                      {formik.touched.customerFirstName &&
                      formik.errors.customerFirstName ? (
                        <div className="fv-plugins-message-container err-msg">
                          <div className="fv-help-block">
                            {formik.errors.customerFirstName}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-offset-2 singUpInps">
                      <TextInput
                        label="Last Name"
                        type="text"
                        placeholder="Last Name"
                        name="customerLastName"
                        change={formik.handleChange}
                        value={formik.values.customerLastName}
                        className={
                          formik.errors.customerLastName && "input-error"
                        }
                      ></TextInput>
                      {formik.touched.customerLastName &&
                      formik.errors.customerLastName ? (
                        <div className="fv-plugins-message-container err-msg">
                          <div className="fv-help-block">
                            {formik.errors.customerLastName}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-offset-2 singUpInps">
                      <TextInput
                        label="Enter Email ID"
                        type="text"
                        placeholder="Enter Email ID"
                        name="email"
                        change={formik.handleChange}
                        value={formik.values.email}
                        className={formik.errors.email && "input-error"}
                      ></TextInput>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container err-msg">
                          <div className="fv-help-block">
                            {formik.errors.email}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-offset-2 singUpInps">
                      <TextInput
                        label="Enter Mobile Number"
                        type="text"
                        placeholder="Enter Mobile Number"
                        name="mobile"
                        length="10"
                        change={formik.handleChange}
                        value={formik.values.mobile}
                        className={formik.errors.mobile && "input-error"}
                      ></TextInput>
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div className="fv-plugins-message-container err-msg">
                          <div className="fv-help-block">
                            {formik.errors.mobile}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-offset-2 singUpInps">
                      <TextInput
                        label="Password"
                        type="password"
                        placeholder="Enter Password"
                        name="password"
                        change={formik.handleChange}
                        value={formik.values.password}
                        className={formik.errors.password && "input-error"}
                      ></TextInput>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container err-msg">
                          <div className="fv-help-block">
                            {formik.errors.password}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div></div>
                  <div className="row">
                    <div className="col-12 col-offset-2 singUpInps">
                      <TextInput
                        label="Confirm Password"
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        change={formik.handleChange}
                        value={formik.values.confirmPassword}
                        className={
                          formik.errors.confirmPassword && "input-error"
                        }
                      ></TextInput>
                      {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <div className="fv-plugins-message-container err-msg">
                          <div className="fv-help-block">
                            {formik.errors.confirmPassword}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-offset-2">
                      {" "}
                      <Checkbox
                        check={remember}
                        change={(e) => handleRemember(e)}
                        value="remember"
                        color="default"
                        id="rememberChk"
                        inputLabel="Remember Me"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-offset-2">
                      {" "}
                      <Checkbox
                        check={formik.values.emailSubscription}
                        change={() =>
                          formik.setFieldValue(
                            "emailSubscription",
                            !formik.values.emailSubscription
                          )
                        }
                        value="emailSubscription"
                        color="default"
                        id="emailSubscriptionChk"
                        inputLabel="Email Subscription"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-offset-2">
                      {" "}
                      <Checkbox
                        check={formik.values.smsSubscription}
                        change={() =>
                          formik.setFieldValue(
                            "smsSubscription",
                            !formik.values.smsSubscription
                          )
                        }
                        value="smsSubscripation"
                        color="default"
                        id="smsSubscripationChk"
                        inputLabel="SMS Subscription"
                      />
                    </div>
                  </div>

                  <div>
                    <BlockButton type="submit"  text={loading || loadingBySocial  ? <Spinner></Spinner> : "Sign Up"}></BlockButton>
                  </div>
                  <div
                    style={{
                      height: "40px",
                      paddingTop: "10px",
                      fontSize: "14px",
                      color: "#130C0E",
                    }}
                  >
                    Already have an account? &nbsp;
                    <span
                      style={{
                        color:  "var(--test-primary)",
                        // color: "#fcaf17",
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                      onClick={(event) => openSignIn(event)}
                    >
                      Sign In
                    </span>
                    <SignIn open={signInOpen} closeDialog={closeSignInDialog} />
                  </div>  
                </form>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
export default SignUp;
