
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const termsConditionMSTCruds = {

    all : () => {
        return axios.get(`/termAndCondition/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default termsConditionMSTCruds;
    