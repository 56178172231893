
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
import { app } from '../../firebase';
import { doc, getDoc, getFirestore } from 'firebase/firestore';


const db = getFirestore(app);

// const fetchData = async () => {
//   const docRef = doc(db, "recipe", "group");
//   const docSnap = await getDoc(docRef);

//   if (docSnap.exists()) {
//   let data = docSnap.data().G3;
//    console.log(data);
//    return data
//   } else {
//   console.log("No such document!");
//   }
//  };
const itemMSTCruds = {

    all : () => {
        return axios.get(`/itemMST/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getAllActive : () => {
        return axios.get(`/itemMST/allActive/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{groupCodes:undefined, outletCode:undefined, systemCode:undefined}}} data
     */
getItemsByGroupCodes: async (group) => {
    let groupCode = group.body.groupCodes[0];

    const cacheName = "menuImagesCache"; 

    const storeImageInCache = async (url, response) => {
        try {
            const cache = await caches.open(cacheName);
            await cache.put(url, response); 
        } catch (error) {
            console.error(`Error storing image in cache for URL: ${url}`, error);
        }
    };

    const fetchAndCacheImage = async (url) => {
        try {
            const response = await fetch(url);
            if (response.ok) {
                await storeImageInCache(url, response.clone()); 
                return URL.createObjectURL(await response.blob()); 
            } else {
                console.warn(`Failed to fetch the image: ${url}`);
                return null;
            }
        } catch (error) {
            console.error(`Error fetching image from URL: ${url}`, error);
            return null;
        }
    };

    const getImageFromCache = async (url) => {
        const cache = await caches.open(cacheName);
        const cachedResponse = await cache.match(url);
        if (cachedResponse) {
            const cachedBlob = await cachedResponse.blob();
            return URL.createObjectURL(cachedBlob); 
        }
        return null; 
    };

    try {
        const response = await axios.get(`/recipeData/byGroupCode/${groupCode}`);
        if (response?.data?.data) {
            const data = JSON.parse(response.data.data);
            let objName = Object.keys(data);

            for (const itemKey in data?.[objName]?.items) {
                const item = data?.[objName]?.items[itemKey];
                
                if (item.image) {
                    let cachedImageUrl = await getImageFromCache(item.image);
                    if (cachedImageUrl) {
                        item.imageUrl = cachedImageUrl;
                    } else {
                        cachedImageUrl = await fetchAndCacheImage(item.image);
                        if (cachedImageUrl) {
                            item.imageUrl = cachedImageUrl; 
                        }
                    }
                }
            }

            return data;
        } else {
            return Promise.reject("No data found");
        }
    } catch (err) {
        return Promise.reject({ message: err.message, status: err?.response?.status });
    }
},




        
    findByItemPropertyValue : () => {
        return axios.get(`/itemMST/byItemPropertyCode/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{active:undefined, barcode:undefined, countingType:undefined, createdBy:undefined, createdOn:undefined, dietaryMST:undefined, dietaryMSTId:undefined, halfNHalf:undefined, hsnCode:undefined, image:undefined, ingredients:undefined, inventoryItemUomMST:undefined, inventoryItemUomMSTId:undefined, itemCode:undefined, itemDivisionMST:undefined, itemDivisionMSTId:undefined, itemGroupMST:undefined, itemGroupMSTId:undefined, itemMSTId:undefined, itemName:undefined, itemPropertyMappingDTLList:undefined, itemTypeMST:undefined, itemTypeMSTId:undefined, kot:undefined, modifiedBy:undefined, modifiedOn:undefined, negative:undefined, new:undefined, productionItemUomMST:undefined, productionItemUomMSTId:undefined, productionMapping:undefined, purchaseItemUomMST:undefined, purchaseItemUomMSTId:undefined, purchaseMapping:undefined, recipeType:undefined, remarks:undefined, salesItemUomMST:undefined, salesItemUomMSTId:undefined, salesMapping:undefined, shortName:undefined, sortOrder:undefined}}} data
     */
    create : (data) => {
        return axios.post(`/itemMST/create/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    delete : ({ id }) => {
        return axios.delete(`/itemMST/delete/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findAllItemRecipe : () => {
        return axios.get(`/itemMST/getItemAndRecipe`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byId : ({ id }) => {
        return axios.get(`/itemMST/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findItemsByTemplate : ({ templateId, outletId }) => {
        return axios.get(`/itemMST/itemTemplateMST/id/${templateId}/outletMST/id/${outletId}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getOfferItems : () => {
        return axios.get(`/itemMST/offers`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byOutletCode : ({ code }) => {
        return axios.get(`/itemMST/outletMST/code/${code}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byOutletWithTemplate : ({ id }) => {
        return axios.get(`/itemMST/outletMST/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findPaginated : () => {
        return axios.get(`/itemMST/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    update : (data) => {
        return axios.put(`/itemMST/update/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{id:undefined},body:null}} data
     */
    uploadFile : (data) => {
        return axios.post(`/itemMST/uploadFile/id/${data.param.id}`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default itemMSTCruds;
    