import {
  addMinute,
  convertTimeToAMPM,
  setTimeToDate,
  getMinuteMultipleOf,
  removeMinute,
} from "./Utils";
const moment = require('moment-timezone');

export const getStoreStartTime = (data, date) => {
  let dateMatchSlots = data.filter(
    (n) =>
      n.date &&
      new Date(n.date).toDateString() === new Date(date).toDateString()
  );
  if (dateMatchSlots?.length > 0) {
    let time = {
      startTime: setTimeToDate(convertToTimeZone(new Date()), dateMatchSlots[0].startTime),
      endTime: setTimeToDate(convertToTimeZone(new Date()), dateMatchSlots[0].endTime),
    };
    let leadTimeArr = dateMatchSlots[0].leadTime.split(":");
    let cutoffTimeArr = dateMatchSlots[0].cutoffTime.split(":");
    time.startTime = addMinute(time.startTime, leadTimeArr[1]);
    time.endTime = removeMinute(time.endTime, cutoffTimeArr[1]);
    return time;
  }

  let dayMatchSlots = data.filter(
    (n) =>
      n.date === null && n.day !== null && n.day === new Date(date).getDay()
  );
  if (dayMatchSlots?.length > 0) {
    let time = {
      startTime: setTimeToDate(convertToTimeZone(new Date()), dayMatchSlots[0].startTime),
      endTime: setTimeToDate(convertToTimeZone(new Date()), dayMatchSlots[0].endTime),
    };
    let leadTimeArr = dayMatchSlots[0].leadTime.split(":");
    let cutoffTimeArr = dayMatchSlots[0].cutoffTime.split(":");
    time.startTime = addMinute(time.startTime, leadTimeArr[1]);
    time.endTime = removeMinute(time.endTime, cutoffTimeArr[1]);
    return time;
  }
  let regularSlots = data.filter(
    (n) =>
      n.date === null && (n.day === null || n.day === new Date(date).getDay())
  );
  if (regularSlots?.length > 0) {
    let time = {
      startTime: setTimeToDate(convertToTimeZone(new Date()), regularSlots[0].startTime),
      endTime: setTimeToDate(convertToTimeZone(new Date()), regularSlots[0].endTime),
    };
    let leadTimeArr = regularSlots[0].leadTime.split(":");
    let cutoffTimeArr = regularSlots[0].cutoffTime.split(":");
    time.startTime = addMinute(time.startTime, leadTimeArr[1]);
    time.endTime = removeMinute(time.endTime, cutoffTimeArr[1]);
    return time;
  }
};
export const getDateSlots = () => {
  var today = convertToTimeZone(new Date());
  let todaysDate = today.getDate();
  let mm = today.getMonth() + 1;
  let year = today.getFullYear();
  let todayday = today.getDate() + 1;
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // const weekday = [
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  // ];
  let orderDates = [];
  let noOfdays = new Date(year, mm, 0).getDate();
  let date;
  let day;
  for (let i = 0; i <= 15; i++) {
    let newDate = null;
    if (i === 0) {
      newDate = new Date(today.setDate(today.getDate()));
    } else {
      newDate = new Date(today.setDate(today.getDate() + 1));
    }

    date = todaysDate + i;
    day = todayday + i;
    if (date > noOfdays) {
      date = date - noOfdays;
    }
    if (date === 1 && i > 0) {
      if (mm === 12) {
        mm = 1;
        year = year + 1;
      } else {
        mm = mm + 1;
      }
    }
    let displayDate =
      date +
      " " +
      months[mm - 1] +
      " " +
      year +
      " " +
      getWeekDay(newDate.getDay());
    orderDates.push(displayDate);
  }
  return orderDates;
};
export const getWeekDay = (day) => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekday[day];
};
export const getFormattedDate = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let todaysDate = date.getDate();
  let mm = date.getMonth() + 1;
  let year = date.getFullYear();
  // if (todaysDate === 1) {
  //   if (mm === 12) {
  //     mm = 1;
  //     year = year + 1;
  //   } else {
  //     mm = mm + 1;
  //   }
  // }
  return todaysDate + " " + months[mm - 1] + " " + year;
};
export const getTimeSlots = (shiftData, date, time, offTime = null) => {
  let dateMatchSlots = shiftData.filter(
    (n) =>
      n.date &&
      new Date(n.date).toDateString() === new Date(date).toDateString()
  );
  if (dateMatchSlots?.length > 0) {
    let slot = dateMatchSlots[0];
    let startTime = setTimeToDate(convertToTimeZone(new Date()), slot.startTime);
    let endTime = setTimeToDate(convertToTimeZone(new Date()), slot.endTime);
    let leadTime = setTimeToDate(convertToTimeZone(new Date()), slot.leadTime);
    let cutOffTime = setTimeToDate(convertToTimeZone(new Date()), slot.cutoffTime);
    let intervalTime = setTimeToDate(convertToTimeZone(new Date()), slot.intervalTime);
    return getSlots(
      startTime,
      endTime,
      leadTime,
      cutOffTime,
      intervalTime,
      time,
      offTime
    );
  }

  let dayMatchSlots = shiftData.filter(
    (n) =>
      n.date === null && n.day !== null && n.day === new Date(date).getDay()
  );
  if (dayMatchSlots?.length > 0) {
    let slot = dayMatchSlots[0];
    let startTime = setTimeToDate(convertToTimeZone(new Date()), slot.startTime);
    let endTime = setTimeToDate(convertToTimeZone(new Date()), slot.endTime);
    let leadTime = setTimeToDate(convertToTimeZone(new Date()), slot.leadTime);
    let cutOffTime = setTimeToDate(convertToTimeZone(new Date()), slot.cutoffTime);
    let intervalTime = setTimeToDate(convertToTimeZone(new Date()), slot.intervalTime);
    return getSlots(
      startTime,
      endTime,
      leadTime,
      cutOffTime,
      intervalTime,
      time,
      offTime
    );
  }
  let regularSlots = shiftData.filter(
    (n) => n.date === null && (n.day === null || n.day === 0)
  );
  if (regularSlots?.length > 0) {
    let slot = regularSlots[0];
    let startTime = setTimeToDate(convertToTimeZone(new Date()), slot.startTime);
    let endTime = setTimeToDate(convertToTimeZone(new Date()), slot.endTime);
    let leadTime = setTimeToDate(convertToTimeZone(new Date()), slot.leadTime);
    let cutOffTime = setTimeToDate(convertToTimeZone(new Date()), slot.cutoffTime);
    let intervalTime = setTimeToDate(convertToTimeZone(new Date()), slot.intervalTime);
    return getSlots(
      startTime,
      endTime,
      leadTime,
      cutOffTime,
      intervalTime,
      time,
      offTime
    );
  }
};
const getSlots = (
  startTime,
  endTime,
  leadTime,
  cutOffTime,
  intervalTime,
  time,
  offTime = null
) => {
  if (
    (time.getTime() > startTime.getTime() ||
      time.getTime() === startTime.getTime()) &&
    (endTime.getTime() > time.getTime() || time.getTime() === endTime.getTime())
  ) {
    //startTime.setMinutes(startTime.getMinutes() + leadTime.getMinutes());
    //endTime.setMinutes(endTime.getMinutes() - cutOffTime.getMinutes());
    var timeSlot = [];
    time = getMinuteMultipleOf(time, intervalTime.getMinutes());
    while (time.getTime() < endTime.getTime()) {
      // let hour = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
      // let min =
      //   time.getMinutes() > 0
      //     ? time.getMinutes() > 9
      //       ? time.getMinutes()
      //       : "0" + time.getMinutes()
      //     : "00";
      // let strTime = hour + ":" + min;
      if (
        offTime &&
        time.getTime() >= offTime.startTime.getTime() &&
        time.getTime() <= offTime.endTime.getTime()
      ) {
        time = addMinute(time, intervalTime.getMinutes());
      } else {
        let newTime = getFormattedTime(time);
        timeSlot.push(newTime);
        time = addMinute(time, intervalTime.getMinutes());
      }
    }
    return timeSlot;
  } else {
    return [];
  }
};
export const getFormattedTime = (time) => {
  let hour = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
  let min =
    time.getMinutes() > 0
      ? time.getMinutes() > 9
        ? time.getMinutes()
        : "0" + time.getMinutes()
      : "00";
  let strTime = hour + ":" + min;
  return convertTimeToAMPM(strTime);
};
const getOffTime = (data, date, type) => {
  if (data?.special?.length > 0) {
    let offData = data.special.filter(
      (row) => row.orderTypeName === type && row.off
    );
    if (offData && offData.length > 0) {
      let offTiming = getStoreStartTime(offData, date);
      if (offTiming) {
        return offTiming;
      }
    }
  }
};
export const convertToTimeZone = (date) => {
  const formattedDate = moment(date).tz(process.env.REACT_APP_TIMEZONE_OFFSET).format('YYYY-MM-DD HH:mm:ss');
  return new Date(formattedDate);
}
export const parseShiftDTL = (data, date, type) => {
  const offTime = getOffTime(data, date, type);
  if (data?.special?.length > 0) {
    let deliverData = data.special.filter(
      (row) => row.orderTypeName === type && !row.off
    );
    if (deliverData && deliverData.length > 0) {
      let storeTiming = getStoreStartTime(deliverData, date);
      if (storeTiming) {
        let currentTime = convertToTimeZone(new Date());
        if (date.getDate() === currentTime.getDate()) {
          if (date.getTime() < storeTiming.startTime.getTime()) {
            currentTime = storeTiming.startTime;
          }
        } else {
          currentTime = storeTiming.startTime;
        }
        return getTimeSlots(deliverData, date, currentTime, offTime);
      }
    }
  }
  if (data?.regular?.length > 0) {
    let deliverData = data.regular.filter((row) => row.orderTypeName === type);
    let storeTiming = getStoreStartTime(deliverData, date);
    if (storeTiming) {
      let currentTime = convertToTimeZone(new Date());
      if (date.getDate() === currentTime.getDate()) {
        if (currentTime.getTime() < storeTiming.startTime.getTime()) {
          currentTime = storeTiming.startTime;
        }
      } else {
        currentTime = storeTiming.startTime;
      }
      return getTimeSlots(deliverData, date, currentTime, offTime);
    }
  }
  return [];
};
export const removeFromArray = (arr, value) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    }
  }
  return arr;
};
export const checkTimeIn = (currentTimeStr, data, type) => {
  const str = currentTimeStr.replace(/-/g, "/");
  let currentTime = new Date(str);
  let offTime = getOffTime(data, currentTime, type);
  if (data?.special?.length > 0) {
    let deliverData = data.special.filter(
      (row) => row.orderTypeName === type && !row.off
    );
    let storeTiming = getStoreStartTime(deliverData, currentTime);
    if (storeTiming) {
      if (
        currentTime > storeTiming.startTime &&
        storeTiming.endTime > currentTime
      ) {
        if (
          offTime &&
          currentTime.getTime() >= storeTiming.startTime.getTime() &&
          currentTime.getTime() <= storeTiming.endTime.getTime()
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
  }
  if (data?.regular?.length > 0) {
    let deliverData = data.regular.filter((row) => row.orderTypeName === type);
    let storeTiming = getStoreStartTime(deliverData, currentTime);
    if (storeTiming) {
      if (
        currentTime > storeTiming.startTime &&
        currentTime < storeTiming.endTime
      ) {
        if (
          offTime &&
          currentTime.getTime() >= storeTiming.startTime.getTime() &&
          currentTime.getTime() <= storeTiming.endTime.getTime()
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
  }
  return false;
};


export const checkTimeInForCheckout = (currentTimeStr, data, type) => {
  const str = currentTimeStr.replace(/-/g, "/");
  let currentTime = new Date(str);
  let offTime = getOffTime(data, currentTime, type);
  if (data?.special?.length > 0) {
    let deliverData = data.special.filter(
      (row) => row.orderTypeName === type && !row.off
    );
    let storeTiming = getStoreStartTime(deliverData, currentTime);
    if (storeTiming) {
      if (
        currentTime > storeTiming.startTime &&
        storeTiming.endTime > currentTime
      ) {
        if (
          offTime &&
          currentTime.getTime() >= storeTiming.startTime.getTime() &&
          currentTime.getTime() <= storeTiming.endTime.getTime()
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
  }
  if (data?.regular?.length > 0) {
    let deliverData = data.regular.filter((row) => row.orderTypeName === type);
    let storeTiming = getStoreStartTime(deliverData, currentTime);
    if (storeTiming) {
      if (
        currentTime >= storeTiming.startTime &&
        currentTime <= storeTiming.endTime
      ) {
        if (
          offTime &&
          currentTime.getTime() >= storeTiming.startTime.getTime() &&
          currentTime.getTime() <= storeTiming.endTime.getTime()
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
  }
  return false;
};
export const getMonthValue = (monthName) => {
  const months = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  return months[monthName];
};
export const removeDayFromDate = (date) => {
  let parsedDate = String(date).split(" ");
  if (isNaN(parsedDate[0])) {
    return date;
  }
  return parsedDate[0] + " " + parsedDate[1] + " " + parsedDate[2];
};
