
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const customerMSTCruds = {

    all : () => {
        return axios.get(`/customerMST/all/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getAllActive : () => {
        return axios.get(`/customerMST/allActive/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{active:undefined, aggregator:undefined, anniversary:undefined, birthDate:undefined, createdBy:undefined, createdOn:undefined, customerAddressDTLList:undefined, customerCode:undefined, customerFirstName:undefined, customerGroupMST:undefined, customerGroupMSTId:undefined, customerLastName:undefined, customerMSTId:undefined, emailSubscription:undefined, gender:undefined, ledgerBalance:undefined, modifiedBy:undefined, modifiedOn:undefined, primaryContact:undefined, primaryEmail:undefined, smsSubscription:undefined, storeInstruction:undefined, userMST:undefined, userMSTId:undefined}}} data
     */
    create : (data) => {
        return axios.post(`/customerMST/create/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    delete : ({ id }) => {
        return axios.delete(`/customerMST/delete/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getCustomerCode : () => {
        return axios.get(`/customerMST/getCode`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getFavourite : ({ id }) => {
        return axios.get(`/customerMST/getFavourite/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getHistory : ({ id }) => {
        return axios.get(`/customerMST/getHistory/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byId : ({ id }) => {
        return axios.get(`/customerMST/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    mailChimpAction : () => {
        return axios.get(`/customerMST/mailChimpAction`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    mailChimpAction : () => {
        return axios.delete(`/customerMST/mailChimpAction`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    byMobileNo : ({ no }) => {
        return axios.get(`/customerMST/mobileNo/${no}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    findPaginated : () => {
        return axios.get(`/customerMST/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{createdBy:undefined, customerFirstName:undefined, customerLastName:undefined, email:undefined, emailSubscription:undefined, mobile:undefined, password:undefined, signUp:undefined, smsSubscription:undefined, userMSTId:undefined}}} data
     */
    signUpCustomer : (data) => {
        return axios.post(`/customerMST/signUpCustomer/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{createdBy:undefined, customerFirstName:undefined, customerLastName:undefined, email:undefined, emailSubscription:undefined, mobile:undefined, password:undefined, signUp:undefined, smsSubscription:undefined, userMSTId:undefined}}} data
     */
    signUpCustomerByIp : (data) => {
        return axios.post(`/customerMST/signUpCustomer/byIp/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{email:undefined, firstName:undefined, lastName:undefined, mobileNo:undefined, socialId:undefined, type:undefined}}} data
     */
    signUpCustomerBySocial : (data) => {
        return axios.post(`/customerMST/signUpCustomer/bySocial/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        

   /**
     * @param {{param:{},body:{createdBy:undefined, customerFirstName:undefined, customerLastName:undefined, email:undefined, emailSubscription:undefined, mobile:undefined, password:undefined, signUp:undefined, smsSubscription:undefined, userMSTId:undefined}}} data
     */
    updateSignUpCustomer : (data) => {
        return axios.post(`/customerMST/signUpCustomer/update/`,data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    update : (data) => {
        return axios.put(`/customerMST/update/`, data.body)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default customerMSTCruds;
    