import { createSlice } from "@reduxjs/toolkit";
import paymentModeMSTCruds from '../../_apis/cruds/paymentModeMSTCrud'
import termsConditionMSTCruds from "../../_apis/cruds/termsConditionMSTCrud";
const REDUCER_NAME = "termsConditionMST"
const TYPES = {
    termsCondition:"termsCondition",
    PrivacyPolicy:"PrivacyPolicy",
}
export const termsConditionMSTSlice = createSlice({
    name: REDUCER_NAME,
     initialState: {
    ...Object.values(TYPES).reduce((occ, cur) => {
      return { ...occ, [cur]: { loading: false, error: null, data: null } };
    }, {}),
    
  },
    reducers: {
        catchError: (state, action) => {
            state[action.payload.type].loading = false;
            state[action.payload.type].error = action.payload.error;
        },
        startCall: (state, action) => {
            debugger
            state[action.payload.type].error = null;
            state[action.payload.type].loading = true;
        },
        stopCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
        },
        dataRecieved: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
            state[action.payload.type].data = action.payload.data;
        },
    },
})

const startCall = (type) => termsConditionMSTSlice.actions.startCall({ type })
const stopCall = (type) => termsConditionMSTSlice.actions.stopCall({ type })
const dataRecieved = (type, data) => termsConditionMSTSlice.actions.dataRecieved({ type, data })
const catchError = (type, error) => termsConditionMSTSlice.actions.catchError({ type, error })

export const tacActions = {
    getTermsCondition:()=> dispatch=>{
        dispatch(startCall(TYPES.termsCondition))      
        return  termsConditionMSTCruds.all().then(res => {
            let terms  =   res?.filter((res) => res.termsAndConditions);
            let privacy  =   res?.filter((res) => !res.termsAndConditions);
            dispatch(dataRecieved(TYPES.termsCondition, terms))
            dispatch(dataRecieved(TYPES.PrivacyPolicy, privacy))
            // console.log(res)termsConditionMST[""].data[0].termsAndConditions
        }).catch(err => {
            dispatch(startCall(TYPES.termsCondition))    
            dispatch(catchError(TYPES.termsCondition, err))
        })
    },
}