import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ROUTES from "../../constants/ROUTES";
import {
  cartActions,
  cartSlice,
  CART_TRACKING_PAGES,
} from "../../_redux/files/cartRedux";
import Feedback from "../feedback/Feedback";
import "./orderSuccessfullyPlaced.scss";
import SignIn from "../userAccount/SignIn";
import SignUp from "../userAccount/SignUp";
import ForgotPassword from "../userAccount/ForgotPassword";
import localStorage from "redux-persist/es/storage";
import { TOKEN_NAME } from "../../_apis/setupAxios";
import { authActions } from "../../_redux/files/authRedux";
import { orderMSTActions } from "../../_redux/files/orderMSTRedux";
import { convertTimeToAMPM } from "../../helpers/Utils";
const OrderSuccessFullyPlaced = () => {
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openForgotPass, setOpenForgotPass] = useState(false);
  const dispatch = useDispatch();
  const placedOrderObj = useSelector((s) => s.cart.placedOrderMSTObj);
  const fromReorderObj = useSelector((s) => s.orderMST.runningOrder);
  const checkFlag = useSelector((s) => s.cart.fromOrderStage);
  const data = useSelector((state) => state.auth.authUser);
  const [allData, setAllData] = useState({});

  useEffect(() => {
    setAllData({
      orderTrackingNumber: checkFlag
        ? fromReorderObj?.data?.Order?.orderNumber
        : placedOrderObj?.orderNumber,
      isDelivery: checkFlag
        ? fromReorderObj?.data?.Order?.orderType === "OT02"
        : placedOrderObj?.orderType === "OT02",
      isPickUp: checkFlag
        ? fromReorderObj?.data?.Order?.orderType === "OT01"
        : placedOrderObj?.orderType === "OT01",
      deliveryTime: checkFlag
        ? fromReorderObj?.data?.Order?.endTime
        : placedOrderObj?.endTime,
      deliveryDate: checkFlag
        ? fromReorderObj?.data?.Order?.orderDate[2] +
          "/" +
          fromReorderObj?.data?.Order?.orderDate[1] +
          "/" +
          fromReorderObj?.data?.Order?.orderDate[0]
        : placedOrderObj?.orderDate[2] +
          "/" +
          placedOrderObj?.orderDate[1] +
          "/" +
          placedOrderObj?.orderDate[0],
      address: checkFlag
        ? fromReorderObj?.data?.Order?.orderAddress
        : placedOrderObj?.orderAddress,
    });

    dispatch(cartSlice.actions.setFromOrderStage(false));
    dispatch(cartActions.clearCart());
  }, [fromReorderObj]);

  // const orderTrackingNumber = checkFlag
  //   ? fromReorderObj?.data?.Order?.orderNumber
  //   : placedOrderObj?.orderNumber;
  // const isDelivery = checkFlag
  //   ? fromReorderObj?.data?.Order?.orderType === "OT02"
  //   : placedOrderObj?.orderType === "OT02";
  // const isPickUp = checkFlag
  //   ? fromReorderObj?.data?.Order?.orderType === "OT01"
  //   : placedOrderObj?.orderType === "OT01";
  // const deliveryTime = checkFlag
  //   ? fromReorderObj?.data?.Order?.endTime?.slice(0, 2)?.join(":")
  //   : placedOrderObj?.endTime?.slice(0, 2)?.join(":");
  // const deliveryDate = checkFlag
  //   ? fromReorderObj?.data?.Order?.orderDate[2] +
  //     "/" +
  //     fromReorderObj?.data?.Order?.orderDate[1] +
  //     "/" +
  //     fromReorderObj?.data?.Order?.orderDate[0]
  //   : placedOrderObj?.orderDate[2] +
  //     "/" +
  //     placedOrderObj?.orderDate[1] +
  //     "/" +
  //     placedOrderObj?.orderDate[0];
  // const address = checkFlag
  //   ? fromReorderObj?.data?.Order?.orderAddress
  //   : placedOrderObj?.orderAddress;
  const custData = useSelector((state) => state.auth.authCust);
  const tempData = useSelector((state) => state.orderMST.tempUser);

  useEffect(() => {
    dispatch(cartSlice.actions.setFromOrderStage(false));
    dispatch(cartActions.clearCart());
    document.querySelector(".logo")?.scrollIntoView({ behavior: "smooth" });
    // dispatch(cartActions.syncCartTracking(CART_TRACKING_PAGES.success))
  }, []);

  useEffect(() => {
    dispatch(orderMSTActions.setGuestCustomer(null));
    sessionStorage.removeItem("user");
    if (data && data.ipUser === true) {
      dispatch(authActions.setAuthCustomer(""));
      localStorage.removeItem(TOKEN_NAME);
      dispatch(authActions.loginByGuest());
    }
  }, []);
  const authSuccess =
    data === null ? false : data.ipAddress === data.userName ? false : true;

  const openUserProfileDialog = (type) => {
    if (type === "sign-in") {
      setOpenSignIn(true);
    } else if (type === "sign-up") {
      setOpenSignUp(true);
    } else if (type === "forgot") {
      setOpenForgotPass(true);
    }
  };
  const closeUserProfileDialog = (type) => {
    if (type === "sign-in") {
      setOpenSignIn(false);
    } else if (type === "sign-up") {
      setOpenSignUp(false);
    } else if (type === "forgot") {
      setOpenForgotPass(false);
    }
  };

  return (
    <div className="order-placed text-center">
      <h3 className="main-title">
        <i class="fas fa-check-circle mb-3"></i>
        <br />
        Order Successfully Placed
      </h3>
      <h6 className="subtitle">
        Thank you for your order <b>{allData?.orderTrackingNumber}</b>
      </h6>
      <br />
      {allData?.isDelivery && (
        <>
          <h4 className="ordertime">
            Your order will be delivered approx{" "}
            <strong>{convertTimeToAMPM(allData?.deliveryTime)}</strong>{" "}
            <strong> {allData?.deliveryDate}</strong> at
            <div style={{ fontSize: "12pt", color: "#888", marginTop: "5px" }}>
              {allData?.address}
            </div>
          </h4>
          <br />
        </>
      )}
      {allData?.isPickUp && (
        <>
          <h4 className="ordertime">
            Your order will be ready to collect approx{" "}
            <strong>{convertTimeToAMPM(allData?.deliveryTime)}</strong>{" "}
            <strong> {allData?.deliveryDate}</strong>
            {/* <div style={{ fontSize: '12pt', color: "#888",marginTop:"5px" }}>{address}</div> */}
          </h4>
          <br />
        </>
      )}
      <div className="btns">
        <Link
          to={ROUTES.userAccount.trackOrder._path}
          className="btn bg-black mx-1"
        >
          Track Your Order
        </Link>
        <Link to={ROUTES.menu._path} className="btn bg-yellow mx-1">
          Continue Ordering
        </Link>
      </div>

      {authSuccess ? null : (
        <div className="row mt-3">
          <div className="col-12">
            Don't have an account? &nbsp;
            <span
              style={{
                color: "#4A90E2",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "600",
              }}
              onClick={() => openUserProfileDialog("sign-up")}
            >
              Sign-Up
            </span>
            <SignIn
              open={openSignIn}
              closeDialog={closeUserProfileDialog}
              openDialog={openUserProfileDialog}
            />
            <SignUp
              open={openSignUp}
              closeDialog={closeUserProfileDialog}
              openDialog={openUserProfileDialog}
              signUpdata={tempData?.data}
            />
            <ForgotPassword
              open={openForgotPass}
              closeDialog={closeUserProfileDialog}
              openDialog={openUserProfileDialog}
            />
          </div>
        </div>
      )}

      <Feedback rateProductQlty={false} />
    </div>
  );
};

export default OrderSuccessFullyPlaced;
